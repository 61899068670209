import { InputLabel, TextField } from "@mui/material";
import React from "react";
import useTheme from "../../../theme/hooks/useTheme";

interface IDisabledFieldProps {
  value: any;
  id: string;
  label: string;
}

export const DisabledField = ({ id, value, label }: IDisabledFieldProps) => {
  const theme = useTheme();

  return (
    <>
      <InputLabel
        shrink
        sx={{
          color: theme.colors.colorsTextTextSecondary,
          fontWeight: 500,
        }}
      >
        {label}
      </InputLabel>
      <TextField
        sx={{
          backgroundColor: theme.colors.colorsBackgroundBgDisabledSubtle,
          margin: 0,
          mb: theme.spacing.spacing2xl,
          "& .MuiInputBase-root": {
            height: 44,
            padding: "0 15px",
            input: {
              padding: 0,
              ":placeholder-shown": {
                color: theme.colors.colorsTextTextPlaceholder,
              },
            },
          },
          "& .MuiFormHelperText-root": {
            ml: 0,
          },
        }}
        value={value}
        disabled
        autoFocus
        margin="dense"
        id={id}
        placeholder={""}
        error={undefined}
        helperText={""}
        type="text"
        fullWidth
        onChange={(e) => {}}
      />
    </>
  );
};
