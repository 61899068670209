import { Box, Grid, InputLabel, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import AddressAutocompleteComponent from "../../../components/AddressAutocompleteComponent/AddressAutocompleteComponent";
import useTheme from "../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import {
  FleetManagementAPI,
  ICreateInstallationRequestBody,
  ICreateManualInstallationRequestBody,
} from "../../../api/FleetManagementAPI";
import { AlertOctagon, CheckCircle, LayersThree01 } from "untitledui-js-base";
import { Button } from "../../../components/Button/Button";
import AuthenticationAPI from "../../../api/AuthenticationAPI";
import { InfoPage } from "./InfoPage";
import Loader2 from "../../../components/Loader/Loader2";
import { useSnackbar } from "../../../components/Snackbar/SnackbarContext";
import { useSearchParams } from "react-router-dom";
import { DisabledField } from "./DisabledField";
import updateConnectivitySettingsRequest from "../../../api/requests/UpdateConnectivitySettingsRequest";
import { InstallationType } from "../../../store/services/models/installations/installationsCommon";

interface IParams {
  verificationId: string;
  connectivityId: string;
  verificationMethod: string;
  verificationCode: string;
  state: string;
  createdDate: string;
}

export const PairingVerificationPage = () => {
  const { t } = useTranslation("cloud_ui");
  const theme = useTheme();
  const navigate = useNavigate();
  const showSnackbar = useSnackbar();
  const locationUrl = useLocation();

  const fleetManagementAPI = new FleetManagementAPI(navigate);
  const authApi = new AuthenticationAPI(navigate);

  const [searchParams] = useSearchParams();

  const indoorUnitSN = searchParams.get("indoorUnitSN");
  const outdoorUnitSN = searchParams.get("outdoorUnitSN");
  const connectivityId = searchParams.get("connectivityId");

  const { verificationId, verificationCode, deviceId, params } = useParams();
  const [parsedParams, setParsedParams] = useState<IParams>();
  const [isOffline, setIsOffline] = useState(false);

  const [name, setName] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [isLocationValid, setIsLocationValid] = useState<boolean>();

  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isInstallationCreatePermission, setIsInstallationCreatePermission] =
    useState<boolean>(false);

  const [isPermissonError, setIsPermissionError] = useState<boolean>(false);
  const [isSavingInProgress, setIsSavingInProgress] = useState<boolean>(false);
  const [isSavedSuccessfully, setIsSavedSuccessfully] =
    useState<boolean>(false);

  const [isVerificationCodeNotFound, setIsVerificationCodeNotFound] =
    useState<boolean>(false);

  useEffect(() => {
    if (
      locationUrl.pathname.includes("offline") ||
      locationUrl.search.includes("offline")
    ) {
      setIsOffline(true);
    } else {
      setIsOffline(false);
    }
  }, [locationUrl]);

  useEffect(() => {
    const cleanedParams = params?.slice(1, -1);
    setParsedParams(parseParams(cleanedParams!));
  }, [params]);

  useEffect(() => {
    fleetManagementAPI
      .getPairingStatus(verificationId as string)
      .then((res) => {
        if (res.data.state === "PENDING") {
          setIsVerificationCodeNotFound(false);
        } else {
          setIsVerificationCodeNotFound(true);
        }
      })
      .catch((err) => {
        setIsVerificationCodeNotFound(true);
      });
  }, [verificationId]);

  useEffect(() => {
    const token = fleetManagementAPI.getAuthTokenForFleetManagement();
    if (token) {
      const adminPermissions: string[] = ["ADMIN"];
      authApi
        .hasRoles(adminPermissions)
        .then((response) => {
          setIsAdmin(response.data.value);
        })
        .catch((error) => {});

      const installationCreatePermission: string[] = ["INSTALLATION_CREATE"];
      authApi
        .hasRoles(installationCreatePermission)
        .then((response) => {
          setIsInstallationCreatePermission(response.data.value);
        })
        .catch((error) => {});
    }
  }, []);

  const parseParams = (paramString: string) => {
    const params: any = {};
    const regex = /(\w+)=([^,]+)/g;
    let match;
    while ((match = regex.exec(paramString)) !== null) {
      params[match[1]] = match[2];
    }
    return params;
  };

  const submit = () => {
    if (isAdmin || isInstallationCreatePermission) {
      setIsSavingInProgress(true);

      if (isOffline) {
        const requestBody: ICreateManualInstallationRequestBody = {
          name,
          location,
          type: InstallationType.DOMESTIC_V3,
        };

        fleetManagementAPI
          .createManualInstallation(requestBody)
          .then((res) => {
            const request = new updateConnectivitySettingsRequest(
              connectivityId == null ? "" : connectivityId
            );
            fleetManagementAPI
              .updateConnectivitySettings(request, res.data.uuid)
              .then((res) => {
                setIsSavingInProgress(false);
                setIsSavedSuccessfully(true);
              })
              .catch((err) => {
                showSnackbar(t("common_messages.generic_error"), {
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  autoHideDuration: 3000,
                  severity: "error",
                });
              });
          })
          .catch((err) => {
            showSnackbar(t("common_messages.generic_error"), {
              anchorOrigin: {
                vertical: "top",
                horizontal: "center",
              },
              autoHideDuration: 3000,
              severity: "error",
            });

            setIsSavingInProgress(false);
          });
      } else {
        const requestBody: ICreateInstallationRequestBody = {
          name,
          location,
          verificationCode: verificationCode,
        };

        fleetManagementAPI
          .createInstallation(requestBody)
          .then((_res) => {
            setIsSavingInProgress(false);
            setIsSavedSuccessfully(true);
          })
          .catch((err) => {
            if (err?.response?.data?.code === "VerificationExpired") {
              showSnackbar(
                t("pages.pairingVerificationPage.errors.verificationExpired"),
                {
                  anchorOrigin: {
                    vertical: "top",
                    horizontal: "center",
                  },
                  autoHideDuration: 3000,
                  severity: "error",
                }
              );
            } else {
              showSnackbar(t("common_messages.generic_error"), {
                anchorOrigin: {
                  vertical: "top",
                  horizontal: "center",
                },
                autoHideDuration: 3000,
                severity: "error",
              });
            }

            setIsSavingInProgress(false);
          });
      }
    } else {
      setIsPermissionError(true);
    }
  };

  if (isVerificationCodeNotFound && !isOffline) {
    return (
      <InfoPage
        icon={
          <AlertOctagon
            size="28"
            style={{ color: theme.colors.colorsForegroundFgErrorPrimary }}
            strokeWidth={2}
          />
        }
        title={`${t("pages.pairingVerificationPage.errors.verificationError")}`}
        description={`${t(
          "pages.pairingVerificationPage.errors.verificationExpired"
        )}`}
      />
    );
  }

  if (isPermissonError) {
    return (
      <InfoPage
        icon={
          <AlertOctagon
            size="28"
            style={{ color: theme.colors.colorsForegroundFgErrorPrimary }}
            strokeWidth={2}
          />
        }
        title={`${t("pages.pairingVerificationPage.permissionError.title")}`}
        description={`${t(
          "pages.pairingVerificationPage.permissionError.description"
        )}`}
      />
    );
  }

  if (isSavingInProgress) {
    return (
      <InfoPage
        icon={<Loader2 />}
        title={`${t("common_texts.saving")}`}
        description={`${t("common_texts.plaeaseWait")}`}
        hideButton
      />
    );
  }

  if (isSavedSuccessfully) {
    return (
      <InfoPage
        icon={
          <CheckCircle
            size="28"
            style={{ color: theme.colors.colorsForegroundFgBrandPrimary }}
            strokeWidth={2}
          />
        }
        title={`${t("pages.pairingVerificationPage.success.title")}`}
        description={`${t(
          "pages.pairingVerificationPage.success.description"
        )}`}
      />
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100vw",
        height: "100vh",
        overflowY: "auto",
      }}
    >
      <Box
        component="form"
        sx={{
          maxWidth: "375px",
          padding: theme.spacing.spacing2xl,
          height: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: theme.spacing.spacing4xl,
            mt: theme.spacing.spacing6xl,
          }}
        >
          <Box
            sx={{
              width: "56px",
              height: "56px",
              border: `1px solid ${theme.colors.componentColorsComponentsIconsFeaturedIconsModernFeaturedIconModernBorder}`,
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: theme.spacing.spacing3xl,
            }}
          >
            <LayersThree01
              size="28"
              style={{ color: theme.colors.colorsForegroundFgBrandPrimary }}
              strokeWidth={2}
            />
          </Box>
          <Typography
            sx={{
              ...theme.typography[".display-xs-semibold"],
              color: theme.colors.colorsTextTextPrimary,
              mb: theme.spacing.spacingMd,
            }}
          >
            {t("pages.pairingVerificationPage.title")}
          </Typography>
          <Typography
            sx={{
              ...theme.typography[".text-md-regular"],
              color: theme.colors.colorsTextTextTertiary,
              mb: theme.spacing.spacingMd,
            }}
          >
            {t("pages.pairingVerificationPage.description")}
          </Typography>
        </Box>
        <Grid container>
          {indoorUnitSN && (
            <DisabledField
              id="indoorUnitSN"
              value={indoorUnitSN}
              label={t(
                "pages.pairingVerificationPage.fields.labels.indoorUnitSerialNumber"
              )}
            />
          )}
          {outdoorUnitSN && (
            <DisabledField
              id="outdoorUnitSN"
              value={outdoorUnitSN}
              label={t(
                "pages.pairingVerificationPage.fields.labels.outdoorUnitSerialNumber"
              )}
            />
          )}
          <InputLabel
            shrink
            sx={{
              color: theme.colors.colorsTextTextSecondary,
              fontWeight: 500,
            }}
          >
            {t("pages.pairingVerificationPage.fields.labels.deviceId")}
          </InputLabel>
          <TextField
            sx={{
              backgroundColor: theme.colors.colorsBackgroundBgDisabledSubtle,
              margin: 0,
              "& .MuiInputBase-root": {
                height: 44,
                padding: "0 15px",
                input: {
                  padding: 0,
                  ":placeholder-shown": {
                    color: theme.colors.colorsTextTextPlaceholder,
                  },
                },
              },
              "& .MuiFormHelperText-root": {
                ml: 0,
              },
            }}
            value={isOffline ? connectivityId : deviceId}
            disabled
            autoFocus
            margin="dense"
            id="deviceID"
            placeholder={""}
            error={undefined}
            helperText={""}
            type="text"
            fullWidth
            onChange={(e) => {}}
          />
          <InputLabel
            shrink
            sx={{
              color: theme.colors.colorsTextTextSecondary,
              fontWeight: 500,
              mt: theme.spacing.spacing2xl,
            }}
          >
            {t("pages.pairingVerificationPage.fields.labels.name")}
          </InputLabel>
          <TextField
            sx={{
              margin: 0,
              "& .MuiInputBase-root": {
                height: 44,
                padding: "0 15px",
                input: {
                  padding: 0,
                  ":placeholder-shown": {
                    color: theme.colors.colorsTextTextPlaceholder,
                  },
                },
              },
              "& .MuiFormHelperText-root": {
                ml: 0,
              },
            }}
            value={name}
            autoFocus
            margin="dense"
            id="name"
            placeholder={`${t(
              "pages.pairingVerificationPage.fields.paceholders.name"
            )}`}
            error={undefined}
            helperText={""}
            type="text"
            fullWidth
            onChange={(e) => setName(e.target.value)}
          />
          <InputLabel
            shrink
            sx={{
              color: theme.colors.colorsTextTextSecondary,
              fontWeight: 500,
              mt: theme.spacing.spacing2xl,
            }}
          >
            {t("pages.pairingVerificationPage.fields.labels.location")}
          </InputLabel>
          <AddressAutocompleteComponent
            onAddressSelect={(address, _country, isValidAddress) => {
              setLocation(address);
              setIsLocationValid(isValidAddress);
            }}
            address={location}
            placeholder={`${t(
              "pages.pairingVerificationPage.fields.paceholders.location"
            )}`}
          />

          <Button
            label={`${t("pages.pairingVerificationPage.submitButton")}`}
            onClick={submit}
            sx={{ mt: theme.spacing.spacing3xl, width: "100%" }}
            disabled={!location || !isLocationValid}
          />
        </Grid>
      </Box>
    </Box>
  );
};
