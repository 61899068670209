import { Box, Typography } from "@mui/material";
import React from "react";
import { ArrowRight } from "untitledui-js-base";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";

export interface IPointsChangeProps {
  title: string;
  diffs: any;
}

export default function PointsChange({ diffs, title }: IPointsChangeProps) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "column",
        }}
      >
        {Object.entries(diffs).map(([key, value]: [any, any]) => {
          const pointNumber = key.slice(-1);

          return (
            <Box
              key={`${title.replace(" ", "")}_${pointNumber}`}
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography
                sx={{
                  ...theme.typography[".text-sm-medium"],
                  color: theme.colors.colorsTextTextPrimary,
                  mb: theme.spacing.spacingXl,
                }}
              >{`${t(
                "pages.installationSettings.zones.fieldsLabels.point"
              )} ${pointNumber}`}</Typography>
              {value!.outdoorTemp && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    mb: theme.spacing.spacingXl,
                  }}
                >
                  <Typography
                    sx={{
                      ...theme.typography[".text-sm-regular"],
                      color: theme.colors.colorsTextTextQuaternary,
                      width: "50%",
                    }}
                  >
                    {`${t(
                      "pages.installationSettings.zones.fieldsLabels.outdoorTempereature"
                    )} ${pointNumber}`}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      width: "50%",
                    }}
                  >
                    {value!.outdoorTemp ? (
                      <>
                        <Typography
                          sx={{
                            ...theme.typography[".text-sm-semibold"],
                            color: theme.colors.colorsTextTextTertiary,
                          }}
                        >
                          {value.outdoorTemp?.value?.previousValue + "°C"}
                        </Typography>
                        <ArrowRight
                          size="14"
                          style={{
                            color: theme.colors.colorsTextTextTertiary,
                            margin: `0 ${theme.spacing.spacingMd}`,
                          }}
                          strokeWidth={2}
                        />
                        <Typography
                          sx={{
                            ...theme.typography[".text-sm-semibold"],
                            color: theme.colors.colorsTextTextTertiary,
                          }}
                        >
                          {value.outdoorTemp?.value?.newValue + "°C"}
                        </Typography>
                      </>
                    ) : (
                      <Typography
                        sx={{
                          ...theme.typography[".text-sm-semibold"],
                          color: theme.colors.colorsTextTextTertiary,
                          width: "100%",
                        }}
                      >
                        {"-"}
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
              {value.flowTemp && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    mb: theme.spacing.spacingXl,
                  }}
                >
                  <Typography
                    sx={{
                      ...theme.typography[".text-sm-regular"],
                      color: theme.colors.colorsTextTextQuaternary,
                      width: "50%",
                    }}
                  >
                    {`${t(
                      "pages.installationSettings.zones.fieldsLabels.flowTemperature"
                    )} ${pointNumber}`}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                      width: "50%",
                    }}
                  >
                    {value.flowTemp ? (
                      <>
                        <Typography
                          sx={{
                            ...theme.typography[".text-sm-semibold"],
                            color: theme.colors.colorsTextTextTertiary,
                          }}
                        >
                          {value.flowTemp?.value?.previousValue + "°C"}
                        </Typography>
                        <ArrowRight
                          size="14"
                          style={{
                            color: theme.colors.colorsTextTextTertiary,
                            margin: `0 ${theme.spacing.spacingMd}`,
                          }}
                          strokeWidth={2}
                        />
                        <Typography
                          sx={{
                            ...theme.typography[".text-sm-semibold"],
                            color: theme.colors.colorsTextTextTertiary,
                          }}
                        >
                          {value.flowTemp?.value?.newValue + "°C"}
                        </Typography>
                      </>
                    ) : (
                      <Typography
                        sx={{
                          ...theme.typography[".text-sm-semibold"],
                          color: theme.colors.colorsTextTextTertiary,
                          width: "100%",
                        }}
                      >
                        {"-"}
                      </Typography>
                    )}
                  </Box>
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
