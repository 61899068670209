import React, { useEffect, useRef, useState } from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { Box, SxProps, Typography } from "@mui/material";

export interface ITimeInput {
  label?: string;
  labelColor?: string;
  currentHours: string;
  onChange: (newHours: string) => void;
  sx?: SxProps;
}

export default function TimeInput({
  currentHours,
  onChange,
  label,
  labelColor,
  sx,
}: ITimeInput) {
  const theme = useTheme();

  const inputRef = useRef<HTMLInputElement>(null);

  const [hours, setHours] = useState<string>(currentHours);

  useEffect(() => {
    if (currentHours) {
      setHours(currentHours);
    }
  }, [currentHours]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (
      value === "" ||
      (/^\d{0,2}$/.test(value) && parseInt(value) >= 0 && parseInt(value) <= 23)
    ) {
      setHours(value);
      onChange(value.padStart(2, "0"));
    }
  };

  return (
    <Box sx={{ ...sx, mb: theme.spacing.spacingXl }}>
      {label && (
        <Typography
          sx={{
            ...theme.typography[".text-sm-medium"],
            color: labelColor || theme.colors.colorsTextTextSecondary,
            mb: theme.spacing.spacingSm,
          }}
        >
          {label}
        </Typography>
      )}
      <Box
        onClick={() => (inputRef ? inputRef.current?.focus() : {})}
        sx={{
          ...theme.typography[".text-md-regular"],
          color: theme.colors.colorsTextTextPrimary,
          padding: "0 14px",
          display: "flex",
          alignItems: "center",
          height: "44px",
          border: `1px solid ${theme.colors.colorsBorderBorderPrimary}`,
          backgroundColor: theme.colors.colorsBackgroundBgPrimary,
          borderRadius: "8px",
          width: "max-content",
          input: {
            ...theme.typography[".text-md-regular"],
            border: "none",
            outline: "none",
            width: "20px",
            padding: "0",
          },
        }}
      >
        <input
          ref={inputRef}
          value={hours}
          onChange={handleChange}
          type="text"
          onBlur={() => setHours(hours!.padStart(2, "0"))}
        />
        :00
      </Box>
    </Box>
  );
}
