import React from "react";
import { useTranslation } from "react-i18next";
import DefaultChange from "../../components/ConfirmChangesModal/DefaultChage";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { Divider, Typography } from "@mui/material";

export default function DifferencesToShowInModal({
  differencesToShow,
}: {
  differencesToShow: any;
}) {
  const { t } = useTranslation("cloud_ui");
  const theme = useTheme();
  const formatWeeks = (daysCount: number) => {
    const weeksCount = daysCount / 7;
    return `${weeksCount} ${t(
      `pages.installationSettings.dhw.${weeksCount === 1 ? "week" : "weeks"}`
    )}`;
  };
  return (
    <>
      {differencesToShow?.preferredTemp && (
        <>
          <DefaultChange
            changedValue={
              differencesToShow?.preferredTemp.value.newValue + "°C"
            }
            previousValue={
              differencesToShow?.preferredTemp.value.previousValue + "°C"
            }
            title={`${t(
              "pages.installationSettings.dhw.fieldsLabels.DHWTemperatureSetPoint"
            )}`}
          />
          <Divider
            sx={{
              width: "100%",
              borderColor: theme.colors.colorsBorderBorderSecondary,
            }}
          />
        </>
      )}
      {differencesToShow?.antiLegionella && (
        <>
          {/* ANTILEGIONELLA CHANGES */}
          {(differencesToShow?.antiLegionella?.interval ||
            differencesToShow?.antiLegionella.startHour ||
            differencesToShow?.antiLegionella.endHour ||
            differencesToShow?.antiLegionella.temp ||
            differencesToShow?.antiLegionella.duration ||
            differencesToShow?.antiLegionella.timeout ||
            differencesToShow?.antiLegionella.state) && (
            <Typography
              sx={{
                ...theme.typography[".text-sm-medium"],
                color: theme.colors.colorsTextTextPrimary,
                mb: theme.spacing.spacingLg,
                mt: theme.spacing.spacingLg,
              }}
            >
              {t("pages.installationSettings.dhw.antiLegionellaTitle")}
            </Typography>
          )}
          {differencesToShow?.antiLegionella.state && (
            <DefaultChange
              changedValue={differencesToShow?.antiLegionella.state.newValue}
              previousValue={
                differencesToShow?.antiLegionella.state.previousValue
              }
              title={`${t(
                "pages.installationSettings.dhw.fieldsLabels.antiLegionellaState"
              )}`}
            />
          )}
          {differencesToShow?.antiLegionella.temp && (
            <DefaultChange
              changedValue={
                differencesToShow?.antiLegionella.temp.value.newValue + "°C"
              }
              previousValue={
                differencesToShow?.antiLegionella.temp.value.previousValue +
                "°C"
              }
              title={`${t(
                "pages.installationSettings.dhw.fieldsLabels.antiLegionellaTempSetPoint"
              )}`}
            />
          )}
          {differencesToShow?.antiLegionella.duration && (
            <DefaultChange
              changedValue={
                differencesToShow?.antiLegionella.duration.value.newValue +
                "min"
              }
              previousValue={
                differencesToShow?.antiLegionella.duration.value.previousValue +
                "min"
              }
              title={`${t(
                "pages.installationSettings.dhw.fieldsLabels.antiLegionellaDuration"
              )}`}
            />
          )}
          {differencesToShow?.antiLegionella.timeout && (
            <DefaultChange
              changedValue={
                differencesToShow?.antiLegionella.timeout.value.newValue +
                "min"
              }
              previousValue={
                differencesToShow?.antiLegionella.timeout.value.previousValue +
                "min"
              }
              title={`${t(
                "pages.installationSettings.dhw.fieldsLabels.antiLegionellaTimeOut"
              )}`}
            />
          )}
          {differencesToShow?.antiLegionella?.interval && (
            <DefaultChange
              changedValue={formatWeeks(
                differencesToShow?.antiLegionella.interval.value.newValue
              )}
              previousValue={formatWeeks(
                differencesToShow?.antiLegionella.interval.value.previousValue
              )}
              title={`${t(
                "pages.installationSettings.dhw.fieldsLabels.antiLegionellaInterval"
              )}`}
            />
          )}
          {differencesToShow?.antiLegionella.startHour && (
            <DefaultChange
              changedValue={
                differencesToShow?.antiLegionella.startHour.value.newValue +
                ":00 h"
              }
              previousValue={
                differencesToShow?.antiLegionella.startHour.value
                  .previousValue + ":00 h"
              }
              title={`${t(
                "pages.installationSettings.dhw.fieldsLabels.antiLegionellaStartHour"
              )}`}
            />
          )}
          {differencesToShow?.antiLegionella.endHour && (
            <DefaultChange
              changedValue={
                differencesToShow?.antiLegionella.endHour.value.newValue +
                ":00 h"
              }
              previousValue={
                differencesToShow?.antiLegionella.endHour.value.previousValue +
                ":00 h"
              }
              title={`${t(
                "pages.installationSettings.dhw.fieldsLabels.antiLegionellaEndHour"
              )}`}
            />
          )}
        </>
      )}
    </>
  );
}
