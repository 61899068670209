import React from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Save01 } from "untitledui-js-base";
import { Button } from "../../../../../../components/Button/Button";

export interface INotificationModalProps {
  isOpen: boolean;
  title?: string;
  description?: string;
  onOkPress?: () => void;
  icon?: any;
}

export default function NotificationModal({
  isOpen,
  title,
  description,
  onOkPress,
  icon,
}: INotificationModalProps) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      sx={{ "& .MuiPaper-root": { maxWidth: "400px" } }}
    >
      <DialogTitle
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginBottom: theme.spacing.spacing2xl,
          padding: `${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl} 0`,
        }}
      >
        <Box
          sx={{
            width: "48px",
            height: "48px",
            borderRadius: "50%",
            backgroundColor:
              theme.colors.componentColorsUtilityErrorUtilityError50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: theme.spacing.spacingXl,
          }}
        >
          {icon ? (
            icon
          ) : (
            <Save01
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          )}
        </Box>
        <Typography sx={{ ...theme.typography[".text-lg-semibold"] }}>
          {t("pages.installationSettings.notificationModal.commonTitle")}
        </Typography>
        <Typography fontSize={14} color={theme.colors.colorsTextTextTertiary}>
          {t("pages.installationSettings.notificationModal.commonDescription")}
        </Typography>
      </DialogTitle>
      <DialogActions
        sx={{
          padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
          justifyContent: "space-between",
        }}
      >
        <Button
          label={`${t("actions.ok")}`}
          onClick={onOkPress}
          sx={{
            width: `100%`,
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
