import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NumberInput from "../../../components/NumberInput/NumberInput";
import useTheme from "../../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import {
  IPointValues,
  PointKey,
} from "../../../../../../../store/services/models/installations/installationSettings";

export interface IPointInputsRowProps {
  points: { [key in PointKey]?: IPointValues };
  index: number;
  value: IPointValues;
  changePointsValues: (
    val: any,
    property: PointKey,
    type: "flow" | "outdoor"
  ) => void;
  minFlow: number;
  maxFlow: number;
  title: string;
}

export default function PointInputsRow({
  points,
  changePointsValues,
  index,
  value,
  maxFlow,
  minFlow,
  title,
}: IPointInputsRowProps) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");

  const [initialValue, setInitialValue] = useState<IPointValues>(value);
  const [minOutdoorTemp, setMinOutdoorTemp] = useState<number>(
    value?.outdoorTemp?.min
  );
  const [maxOutdoorTemp, setMaxOutdoorTemp] = useState<number>(
    value?.outdoorTemp?.max
  );

  useEffect(() => {
    setInitialValue(value);
  }, [value]);

  useEffect(() => {
    if (index !== 0 && points) {
      setMinOutdoorTemp(
        points[`point${index}` as PointKey]?.outdoorTemp.value! + 1
      );
    }

    let nextPoint = points[`point${index + 2}` as PointKey];
    if (nextPoint && nextPoint.outdoorTemp.value <= value.outdoorTemp.value) {
      changePointsValues(
        { ...value.outdoorTemp, value: value.outdoorTemp.value + 1 },
        `point${index + 2}` as PointKey,
        "outdoor"
      );
    }

    setInitialValue(value);
  }, [points, index, value.outdoorTemp.value]);

  useEffect(() => {
    if (title === "Heating") {
      setMaxOutdoorTemp(index + (value.outdoorTemp.max - 4));
    } else {
      setMaxOutdoorTemp(index + (value.outdoorTemp.max - 2));
    }
  }, [index, value.outdoorTemp.max]);

  // useEffect(() => {
  //   if (
  //     initialValue?.flowTemp.min !== undefined &&
  //     initialValue?.flowTemp.value
  //   ) {
  //     if (initialValue?.flowTemp.min > initialValue.flowTemp.value) {
  //       const property = `point${index + 1}`;
  //       changePointsValues(
  //         { ...initialValue?.flowTemp, value: initialValue?.flowTemp.min },
  //         property as PointKey,
  //         "flow"
  //       );
  //     }
  //   }
  // }, [initialValue?.flowTemp.min, initialValue.flowTemp.value]);

  // useEffect(() => {
  //   if (
  //     initialValue?.flowTemp.max !== undefined &&
  //     initialValue?.flowTemp.value
  //   ) {
  //     if (initialValue?.flowTemp.max < initialValue.flowTemp.value) {
  //       const property = `point${index + 1}`;
  //       changePointsValues(
  //         { ...initialValue?.flowTemp, value: initialValue?.flowTemp.max },
  //         property as PointKey,
  //         "flow"
  //       );
  //     }
  //   }
  // }, [initialValue?.flowTemp.max, initialValue.flowTemp.value]);

  return (
    <Box
      key={`point_${index}`}
      sx={{
        display: "flex",
        alignItems: "center",
        width: "408px",
        justifyContent: "space-between",
      }}
    >
      <Typography
        sx={{
          ...theme.typography[".text-sm-medium"],
          color: theme.colors.colorsTextTextTertiary,
        }}
      >
        {`${t("pages.installationSettings.zones.fieldsLabels.point")} ${
          index + 1
        }`}
      </Typography>
      <NumberInput
        initialValue={initialValue?.outdoorTemp?.value}
        min={minOutdoorTemp}
        max={maxOutdoorTemp}
        changeValue={(val) => {
          const property = `point${index + 1}`;
          changePointsValues(
            { ...initialValue?.outdoorTemp, value: val },
            property as PointKey,
            "outdoor"
          );
        }}
        decimalPlaces={2}
        step={0.5}
        labelColor={theme.colors.colorsTextTextQuaternary}
        label={`${t(
          "pages.installationSettings.zones.fieldsLabels.outdoorTempereature"
        )} ${index + 1}`}
      />
      <NumberInput
        initialValue={initialValue?.flowTemp?.value}
        min={initialValue?.flowTemp?.min}
        max={initialValue?.flowTemp?.max}
        changeValue={(val) => {
          const property = `point${index + 1}`;
          changePointsValues(
            { ...initialValue?.flowTemp, value: val },
            property as PointKey,
            "flow"
          );
        }}
        decimalPlaces={2}
        step={0.5}
        labelColor={theme.colors.colorsTextTextQuaternary}
        label={`${t(
          "pages.installationSettings.zones.fieldsLabels.flowTemperature"
        )} ${index + 1}`}
      />
    </Box>
  );
}
