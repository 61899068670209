import React, { useState, useEffect } from "react";
import { Loading01 } from "untitledui-js-base";
import useTheme from "../../theme/hooks/useTheme";
import { Props } from "react-apexcharts";

const Loader2 = () => {
  const theme = useTheme();
  const [currentIndex, setCurrentIndex] = useState(0);
  const iconProps: Props = { size: "28", strokeWidth: 2 };
  const iconStyles = { color: theme.colors.colorsForegroundFgBrandPrimary };
  const icons = ["0", "45", "90", "135", "180", "225", "270", "315"].map(
    (angle: string) => (
      <Loading01
        {...iconProps}
        style={{
          ...iconStyles,
          transform: `rotate(${angle}deg)`,
        }}
      />
    )
  );
  

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % icons.length);
    }, 150);

    return () => clearInterval(interval);
  }, []);

  return <>{icons[currentIndex]}</>;
};

export default Loader2;
