import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../store";
import { redirect } from "../../store/reducers/authSlice";

const AuthHandler: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const redirectPath = useSelector(
    (state: RootState) => state.authSlice.redirectPath
  );

  useEffect(() => {
    if (redirectPath) {
      navigate(redirectPath);
      dispatch(redirect(""));
    }
  }, [redirectPath, navigate, dispatch]);

  return null;
};

export default AuthHandler;
