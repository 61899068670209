import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useTheme from "../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { Button, ButtonType } from "../../../components/Button/Button";
import { ChevronDown } from "untitledui-js-base";
import { useNavigate } from "react-router";
import {
  IParendChildOrganization,
  OrganizationsAPI,
} from "../../../api/OrganizationsAPI";
import { useSnackbar } from "../../../components/Snackbar/SnackbarContext";

export const ShareInstallationModal = ({
  dialogOpen,
  onShareCallback,
  setDialogOpen,
  childrenOrganizations,
  installationForAction,
}: ShareInstallationDialogProperties) => {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  const showSnackbar = useSnackbar();
  const navigate = useNavigate();
  const api = new OrganizationsAPI(navigate);

  const [organizationForShare, setOrganizationForShare] = useState<string>("");

  const [shareInProgress, setShareInProgress] = useState<boolean>(false);

  const handleOrganizationForShareChange = (event: any) => {
    setOrganizationForShare(event.target?.value);
  };

  const shareInstallation = () => {
    setShareInProgress(true);

    api
      .shareInstallation(installationForAction.uuid, {
        childOrgUuid: organizationForShare,
      })
      .then((res) => {
        setShareInProgress(false);
        setDialogOpen(false);
        showSnackbar(
          t(
            "pages.commercialInstallation.shareInstallation.installationSuccessfullyShared"
          ),
          {
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            autoHideDuration: 3000,
            severity: "success",
          }
        );
      })
      .catch((err) => {
        setShareInProgress(false);
        showSnackbar(t("errors.somethingWentWrong"), {
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          autoHideDuration: 3000,
          severity: "error",
        });
      });
  };

  return (
    <Dialog
      open={dialogOpen}
      fullWidth={true}
      sx={{ "& .MuiPaper-root": { maxWidth: "400px" } }}
    >
      <DialogTitle sx={{ padding: `${theme.spacing.spacing3xl}` }}>
        <Typography
          sx={{
            ...theme.typography[".text-lg-semibold"],
            color: theme.colors.colorsTextTextPrimary,
            mb: theme.spacing.spacingXs,
          }}
        >
          {t("pages.commercialInstallation.shareInstallation.title")}
        </Typography>
        <Typography
          sx={{
            ...theme.typography[".text-sm-regular"],
            color: theme.colors.colorsTextTextTertiary,
          }}
        >
          {t("pages.commercialInstallation.shareInstallation.descriptionPt1")}
          <b>
            {t("pages.commercialInstallation.shareInstallation.descriptionPt2")}
          </b>
          ).
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: `0 ${theme.spacing.spacing3xl}`,
          mb: theme.spacing.spacing4xl,
        }}
      >
        <InputLabel
          shrink
          sx={{
            color: theme.colors.colorsTextTextSecondary,
            fontWeight: 500,
          }}
        >
          {t(
            "pages.commercialInstallation.shareInstallation.organizationSelectLabel"
          )}
        </InputLabel>
        <FormControl fullWidth variant="outlined">
          <Select
            displayEmpty
            value={organizationForShare}
            onChange={handleOrganizationForShareChange}
            IconComponent={ChevronDown}
            placeholder={`${t(
              "pages.commercialInstallation.shareInstallation.organizationSelectPlaceholder"
            )}`}
            sx={{
              "& .MuiSelect-select": { padding: "0 10px" },
              "& .MuiSelect-icon": { top: "11px" },
              height: 44,
              color: theme.colors.colorsTextTextPlaceholder,
              fontSize: "16px",
            }}
            renderValue={(selected) => {
              if (selected === "") {
                return (
                  <span
                    style={{ color: theme.colors.colorsTextTextPlaceholder }}
                  >
                    {t(
                      "pages.commercialInstallation.shareInstallation.organizationSelectPlaceholder"
                    )}
                  </span>
                );
              }
              return (
                <span style={{ color: theme.colors.colorsTextTextPrimary }}>
                  {
                    childrenOrganizations?.find(
                      (option) => option.uuid === selected
                    )?.name
                  }{" "}
                </span>
              );
            }}
          >
            <MenuItem disabled value="" sx={{ display: "none" }}>
              <span style={{ color: theme.colors.colorsTextTextPlaceholder }}>
                {t(
                  "pages.commercialInstallation.shareInstallation.organizationSelectPlaceholder"
                )}
              </span>
            </MenuItem>
            {childrenOrganizations.map((option, index) => (
              <MenuItem
                key={`KEY_${index}`}
                value={option.uuid}
                sx={{
                  fontSize: "16px",
                  color: theme.colors.colorsTextTextPrimary,
                }}
              >
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions
        sx={{
          padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
          justifyContent: "space-between",
        }}
      >
        <Button
          label={`${t("actions.cancel")}`}
          onClick={() => setDialogOpen(false)}
          buttonType={ButtonType.SecondaryGray}
          sx={{
            width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
          }}
        />
        <Button
          label={`${t(
            "pages.commercialInstallation.shareInstallation.shareButtonText"
          )}`}
          disabled={!organizationForShare}
          onClick={shareInstallation}
          loadingInProgress={shareInProgress}
          sx={{
            pointerEvents: false ? "none" : "initial",
            ml: "0 !important",
            width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
          }}
        />
      </DialogActions>
    </Dialog>
  );
};

export type ShareInstallationDialogProperties = {
  childrenOrganizations: Array<IParendChildOrganization>;
  installationForAction: any;
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  onShareCallback: () => void;
};
