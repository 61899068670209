import { CalendarTodayOutlined } from "@mui/icons-material";
import { Box, SxProps, TextField } from "@mui/material";
import React from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { XClose } from "untitledui-js-base";

export interface IDatePickerInputProps {
  date: Date | null;
  handleDateChange: (date: Date | null) => void;
  maxDate: Date;
  minDate: Date;
  placeholder: string;
  sx?: SxProps;
  width?: string;
  showClearButton?: boolean;
  onClearClick?: () => void;
}

export default function DatePickerInput({
  date,
  handleDateChange,
  maxDate,
  minDate,
  placeholder,
  sx,
  width = "170",
  showClearButton,
  onClearClick,
}: IDatePickerInputProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        ...sx,
        position: "relative",
        ".MuiPickersFadeTransitionGroup-root": {
          ".Mui-selected": {
            backgroundColor:
              theme.colors
                .componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg,
          },
        },
      }}
    >
      <CalendarTodayOutlined
        sx={{
          position: "absolute",
          left: "13px",
          top: "50%",
          transform: "translateY(-50%)",
          color:
            theme.colors
              .componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg,
          fontSize: "18px",
        }}
      />
      {showClearButton && (
        <XClose
          strokeWidth={2}
          onClick={() => {
            onClearClick && onClearClick();
          }}
          size="18"
          style={{
            position: "absolute",
            right: "13px",
            top: "50%",
            transform: "translateY(-50%)",
            color:
              theme.colors
                .componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg,
            zIndex: 3,
            cursor: "pointer",
          }}
        />
      )}
      <DatePicker
        value={date}
        format="dd/MM/yyyy"
        onChange={handleDateChange}
        maxDate={maxDate}
        minDate={minDate}
        sx={{
          position: "relative",
        }}
        slotProps={{
          popper: {
            disablePortal: false,
            modifiers: [
              {
                name: "preventOverflow",
                options: {
                  altAxis: true,
                  tether: false,
                },
              },
            ],
          },
        }}
        slots={{
          textField: (params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                readOnly: true,
                disabled: true,
              }}
              sx={{
                position: "relative",
                height: "40px",
                maxWidth: `${width}px`,
                ".MuiInputBase-root": {
                  ...theme.typography[".text-sm-semibold"],
                  color:
                    theme.colors
                      .componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg,
                  height: "40px",
                  p: 0,
                  opacity: 1,
                  display: "flex",
                  borderRadius: "8px",
                  border: `1px solid ${theme.colors.componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBorder}`,
                },
                ".MuiInputAdornment-root": {
                  opacity: 0,
                  position: "relative",
                  button: {
                    ml: "-9px",
                    width: showClearButton
                      ? `calc(${width}px - 30px)`
                      : `${width}px`,
                    opacity: 1,
                    position: "absolute",
                  },
                },
                "input::placeholder": {
                  opacity: 1,
                },
                input: {
                  height: "40px",
                  boxSizing: "border-box",
                  ml: "20px",
                  mr: showClearButton ? "30px" : "0",
                  order: "1",
                  // pointerEvents: "none",
                  p: `0 ${theme.spacing.spacingMd}`,
                  "&:placeholder-shown": {
                    opacity: "1",
                    color:
                      theme.colors
                        .componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg,
                  },
                },
                fieldset: {
                  "&:hover": {
                    borderColor:
                      theme.colors
                        .componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBorder,
                  },
                  outline: "none",
                  border: "none",
                  // pointerEvents: "none",
                },
              }}
              placeholder={placeholder}
            />
          ),
        }}
      />
    </Box>
  );
}
