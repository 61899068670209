import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en';
import sv from './translations/sv';

i18n
  .use(initReactI18next)
  .init({
      resources: {
          en: en,
          sv: sv
      },
      fallbackLng: 'en'
  });

export default i18n;