import React from "react";
import { useTranslation } from "react-i18next";
import { InstallationZoneOperationControllerEnum } from "../../../../../../store/services/models/installations/installationSettings";
import DefaultChange from "../../components/ConfirmChangesModal/DefaultChage";
import PointsChange from "../../components/ConfirmChangesModal/PointsChange";
import { Divider, Typography } from "@mui/material";
import useTheme from "../../../../../../theme/hooks/useTheme";

export default function DifferencesToShowInModal({
  differencesToShow,
}: {
  differencesToShow: Array<any>;
}) {
  const { t } = useTranslation("cloud_ui");
  const theme = useTheme();
  return (
    <>
      {differencesToShow.map((zone) => {
        const differences = zone.differences;
        const controllers = {
          [InstallationZoneOperationControllerEnum.CURVE]: t(
            "pages.installationSettings.zones.controllerTypes.curve"
          ),
          [InstallationZoneOperationControllerEnum.FIXED]: t(
            "pages.installationSettings.zones.controllerTypes.fixedFlow"
          ),
        };
        return (
          <React.Fragment key={`zone${zone.zoneNumber}`}>
            <Typography
              sx={{
                ...theme.typography[".text-md-semibold"],
                color: theme.colors.colorsTextTextPrimary,
                mb: theme.spacing.spacingXl,
              }}
            >{`${t("pages.installationSettings.zones.zoneTitle")} ${
              zone.zoneNumber
            }`}</Typography>
            {differences?.preferredRoomTemp && (
              <>
                <DefaultChange
                  changedValue={
                    differences?.preferredRoomTemp.value.newValue + "°C"
                  }
                  previousValue={
                    differences?.preferredRoomTemp.value.previousValue + "°C"
                  }
                  title={t(
                    "pages.installationSettings.zones.fieldsLabels.desiredRoomTemperature"
                  )}
                />
                <Divider
                  sx={{
                    width: "100%",
                    borderColor: theme.colors.colorsBorderBorderSecondary,
                  }}
                />
              </>
            )}
            {/*HEATING CHANGES */}
            {(differences?.preferredFixedFlowHeatingTemp ||
              differences?.heatingCurve ||
              differences?.heatingController) && (
              <Typography
                sx={{
                  ...theme.typography[".text-sm-medium"],
                  color: theme.colors.colorsTextTextPrimary,
                  mb: theme.spacing.spacingLg,
                  mt: theme.spacing.spacingLg,
                }}
              >
                {`${t(
                  "pages.installationSettings.zones.flowTemperatureSetPoint"
                )} - ${t(`pages.installationSettings.zones.Heating`)}`}
              </Typography>
            )}
            {differences?.heatingController && (
              <DefaultChange
                changedValue={
                  controllers[
                    differences?.heatingController
                      .newValue as InstallationZoneOperationControllerEnum
                  ]
                }
                previousValue={
                  controllers[
                    differences?.heatingController
                      .previousValue as InstallationZoneOperationControllerEnum
                  ]
                }
                title={t(
                  "pages.installationSettings.zones.fieldsLabels.controllerType"
                )}
              />
            )}
            {differences?.heatingCurve && (
              <PointsChange
                title={`${t(
                  "pages.installationSettings.zones.flowTemperatureSetPoint"
                )} - ${t(`pages.installationSettings.zones.Heating`)}`}
                diffs={differences?.heatingCurve}
              />
            )}
            {differences?.preferredFixedFlowHeatingTemp && (
              <DefaultChange
                changedValue={
                  differences?.preferredFixedFlowHeatingTemp.value.newValue +
                  "°C"
                }
                previousValue={
                  differences?.preferredFixedFlowHeatingTemp.value
                    .previousValue + "°C"
                }
                title={t(
                  "pages.installationSettings.zones.fieldsLabels.fixedFlowTemperature"
                )}
              />
            )}
            {(differences?.preferredFixedFlowHeatingTemp ||
              differences?.heatingCurve ||
              differences?.heatingController) && (
              <Divider
                sx={{
                  width: "100%",
                  borderColor: theme.colors.colorsBorderBorderSecondary,
                }}
              />
            )}
            {/*COOLING CHANGES*/}
            {(differences?.preferredFixedFlowCoolingTemp ||
              differences?.coolingCurve ||
              differences?.coolingController) && (
              <Typography
                sx={{
                  ...theme.typography[".text-sm-medium"],
                  color: theme.colors.colorsTextTextPrimary,
                  mb: theme.spacing.spacingLg,
                  mt: theme.spacing.spacingLg,
                }}
              >
                {`${t(
                  "pages.installationSettings.zones.flowTemperatureSetPoint"
                )} - ${t(`pages.installationSettings.zones.Cooling`)}`}
              </Typography>
            )}
            {differences?.coolingController && (
              <DefaultChange
                changedValue={
                  controllers[
                    differences?.coolingController
                      .newValue as InstallationZoneOperationControllerEnum
                  ]
                }
                previousValue={
                  controllers[
                    differences?.coolingController
                      .previousValue as InstallationZoneOperationControllerEnum
                  ]
                }
                title={t(
                  "pages.installationSettings.zones.fieldsLabels.controllerType"
                )}
              />
            )}
            {differences?.coolingCurve && (
              <PointsChange
                title={`${t("pages.installationSettings.zones.zoneTitle")} ${
                  zone.zoneNumber
                } - ${t(
                  "pages.installationSettings.zones.flowTemperatureSetPoint"
                )} - ${t(`pages.installationSettings.zones.Cooling`)}`}
                diffs={differences?.coolingCurve}
              />
            )}
            {differences?.preferredFixedFlowCoolingTemp && (
              <DefaultChange
                changedValue={
                  differences?.preferredFixedFlowCoolingTemp.value.newValue +
                  "°C"
                }
                previousValue={
                  differences?.preferredFixedFlowCoolingTemp.value
                    .previousValue + "°C"
                }
                title={t(
                  "pages.installationSettings.zones.fieldsLabels.fixedFlowTemperature"
                )}
              />
            )}
            {(differences?.preferredFixedFlowCoolingTemp ||
              differences?.coolingCurve ||
              differences?.coolingController) && (
              <Divider
                sx={{
                  width: "100%",
                  borderColor: theme.colors.colorsBorderBorderSecondary,
                }}
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
}
