import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputLabel,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import useTheme from "../../../theme/hooks/useTheme";
import { useSnackbar } from "../../Snackbar/SnackbarContext";
import { IChangePasswordRequest, UsersAPI } from "../../../api/UsersAPI";
import { Button, ButtonType } from "../../Button/Button";
import { Eye, EyeOff } from "untitledui-js-base";

export default function ChangePasswordDialog({
  dialogOpen,
  setDialogOpen,
  onAddCallback,
}: AddUserDialogProperties) {
  const theme = useTheme();
  const showSnackbar = useSnackbar();
  const { t } = useTranslation("cloud_ui");
  const navigate = useNavigate();
  const usersApi = new UsersAPI(navigate);

  const passwordRegExp = new RegExp(
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"
  );

  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [oldPasswordHiddenMode, setOldPasswordHiddenMode] =
    useState<boolean>(true);
  const [passwordHiddenMode, setPasswordHiddenMode] = useState<boolean>(true);
  const [confirmPasswordHiddenMode, setConfirmPasswordHiddenMode] =
    useState<boolean>(true);
  const [passwordInputError, setPasswordInputError] = useState<string>();
  const [confirmPasswordInputError, setConfirmPasswordInputError] =
    useState<string>();

  const [editAPICallInProgress, setEditAPICallInProgress] =
    useState<boolean>(false);

  useEffect(() => {
    const passwordValid = passwordRegExp.test(password);
    if (!passwordValid && password) {
      setPasswordInputError(
        `${t("pages.userVerificationPage.passwordInputError")}`
      );
    } else {
      setPasswordInputError("");
    }

    if (password !== confirmPassword && (password || confirmPassword)) {
      setConfirmPasswordInputError(
        `${t("pages.userVerificationPage.confirmPasswordInputError")}`
      );
    } else {
      setConfirmPasswordInputError("");
    }
    setSubmitEnabled(passwordValid && password === confirmPassword);
  }, [password, confirmPassword]);

  const handleAddDialogClose = () => {
    setDialogOpen(false);
  };

  const changePassword = () => {
    setEditAPICallInProgress(true);

    const requestBody: IChangePasswordRequest = {
      newPassword: password,
      oldPassword: oldPassword,
    };

    usersApi
      .changePassword(requestBody)
      .then((_res) => {
        showSnackbar(t("pages.changePassword.successfullyChanged"), {
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          autoHideDuration: 3000,
          severity: "success",
        });
        setEditAPICallInProgress(false);
        onAddCallback();
      })
      .catch((err) => {
        showSnackbar(
          err.response.data.code === "BadCredentials"
            ? t("pages.changePassword.errors.incorrectCurrentPasswordError")
            : t("pages.changePassword.errors.editError"),
          {
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            autoHideDuration: 3000,
            severity: "error",
          }
        );
        setEditAPICallInProgress(false);
      });
  };

  return (
    <Dialog
      open={dialogOpen}
      fullWidth={true}
      sx={{ "& .MuiPaper-root": { maxWidth: "400px" } }}
    >
      <DialogTitle sx={{ padding: `${theme.spacing.spacing3xl}` }}>
        <Typography
          color={theme.colors.colorsTextTextPrimary}
          fontSize={18}
          variant={"h5"}
        >
          {t("pages.changePassword.title")}
        </Typography>
        <Typography
          color={theme.colors.colorsTextTextTertiary}
          fontSize={18}
          variant={"h5"}
          sx={{
            ...theme.typography[".text-sm-regular"],
            mt: theme.spacing.spacingLg,
          }}
        >
          {t("pages.changePassword.subTitle")}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          padding: `0 ${theme.spacing.spacing3xl}`,
          mb: theme.spacing.spacing4xl,
        }}
      >
        <InputLabel
          shrink
          sx={{
            color: theme.colors.colorsTextTextSecondary,
            fontWeight: 500,
          }}
          htmlFor="password"
          disableAnimation
        >
          {t("pages.changePassword.oldPasswordLabel")}
        </InputLabel>
        <OutlinedInput
          id="passwordNew"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          type={oldPasswordHiddenMode ? "password" : "text"}
          inputProps={{ autoComplete: "old-password" }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setOldPasswordHiddenMode(!oldPasswordHiddenMode)}
                edge="end"
              >
                {passwordHiddenMode ? (
                  <Eye
                    size="16"
                    style={{
                      color: theme.colors.colorsTextTextPlaceholder,
                      opacity: ".4",
                    }}
                    strokeWidth={2}
                  />
                ) : (
                  <EyeOff
                    size="16"
                    style={{
                      color: theme.colors.colorsTextTextPlaceholder,
                      opacity: ".4",
                    }}
                    strokeWidth={2}
                  />
                )}
              </IconButton>
            </InputAdornment>
          }
          placeholder={`${t("pages.changePassword.oldPasswordPlaceholder")}`}
          sx={{
            margin: "0",
            width: "100%",
            "& .MuiInputBase-input": {
              height: 40,
              padding: "0 14px",
              input: {
                padding: 0,
                ":placeholder-shown": {
                  color: theme.colors.colorsTextTextPlaceholder,
                },
              },
            },
          }}
        />
        <InputLabel
          shrink
          sx={{
            color: theme.colors.colorsTextTextSecondary,
            fontWeight: 500,
            mt: theme.spacing.spacing2xl,
          }}
          htmlFor="password"
          disableAnimation
        >
          {t("pages.changePassword.newPasswordLabel")}
        </InputLabel>
        <OutlinedInput
          id="passwordNew"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type={passwordHiddenMode ? "password" : "text"}
          inputProps={{ autoComplete: "new-password" }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setPasswordHiddenMode(!passwordHiddenMode)}
                edge="end"
              >
                {passwordHiddenMode ? (
                  <Eye
                    size="16"
                    style={{
                      color: theme.colors.colorsTextTextPlaceholder,
                      opacity: ".4",
                    }}
                    strokeWidth={2}
                  />
                ) : (
                  <EyeOff
                    size="16"
                    style={{
                      color: theme.colors.colorsTextTextPlaceholder,
                      opacity: ".4",
                    }}
                    strokeWidth={2}
                  />
                )}
              </IconButton>
            </InputAdornment>
          }
          placeholder={`${t("pages.changePassword.newPasswordPlaceholder")}`}
          sx={{
            margin: "0",
            width: "100%",
            "& .MuiInputBase-input": {
              height: 40,
              padding: "0 14px",
              input: {
                padding: 0,
                ":placeholder-shown": {
                  color: theme.colors.colorsTextTextPlaceholder,
                },
              },
            },
          }}
        />
        {passwordInputError && (
          <FormHelperText
            sx={{ color: theme.colors.colorsTextTextErrorPrimary }}
          >
            {passwordInputError}
          </FormHelperText>
        )}
        <InputLabel
          shrink
          sx={{
            color: theme.colors.colorsTextTextSecondary,
            fontWeight: 500,
            mt: theme.spacing.spacing2xl,
          }}
          htmlFor="password"
          disableAnimation
        >
          {t("pages.changePassword.confirmNewPasswordLabel")}
        </InputLabel>
        <OutlinedInput
          id="passwordConfirm"
          value={confirmPassword}
          inputProps={{ autoComplete: "new-password-confirm" }}
          onChange={(e) => setConfirmPassword(e.target.value)}
          type={confirmPasswordHiddenMode ? "password" : "text"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() =>
                  setConfirmPasswordHiddenMode(!confirmPasswordHiddenMode)
                }
                edge="end"
              >
                {confirmPasswordHiddenMode ? (
                  <Eye
                    size="16"
                    style={{
                      color: theme.colors.colorsTextTextPlaceholder,
                      opacity: ".4",
                    }}
                    strokeWidth={2}
                  />
                ) : (
                  <EyeOff
                    size="16"
                    style={{
                      color: theme.colors.colorsTextTextPlaceholder,
                      opacity: ".4",
                    }}
                    strokeWidth={2}
                  />
                )}
              </IconButton>
            </InputAdornment>
          }
          placeholder={`${t(
            "pages.changePassword.confirmNewPasswordPlaceholder"
          )}`}
          sx={{
            margin: "0",
            width: "100%",
            "& .MuiInputBase-input": {
              height: 40,
              padding: "0 14px",
              input: {
                padding: 0,
                ":placeholder-shown": {
                  color: theme.colors.colorsTextTextPlaceholder,
                },
              },
            },
          }}
        />
        {confirmPasswordInputError && (
          <FormHelperText
            sx={{ color: theme.colors.colorsTextTextErrorPrimary }}
          >
            {confirmPasswordInputError}
          </FormHelperText>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
          justifyContent: "space-between",
        }}
      >
        <Button
          label={`${t("actions.cancel")}`}
          onClick={handleAddDialogClose}
          buttonType={ButtonType.SecondaryGray}
          sx={{
            width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
          }}
        />
        <Button
          label={`${t("pages.changePassword.changePasswordButtonText")}`}
          disabled={!submitEnabled}
          onClick={editAPICallInProgress ? () => {} : changePassword}
          loadingInProgress={editAPICallInProgress}
          sx={{
            pointerEvents: editAPICallInProgress ? "none" : "initial",
            ml: "0 !important",
            width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

export type AddUserDialogProperties = {
  dialogOpen: boolean;
  setDialogOpen: (open: boolean) => void;
  onAddCallback: () => void;
};
