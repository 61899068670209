import React from "react";
import { ITabsNavigationProps } from "../../../../../../components/TabsNavigation/TabsNavigation";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Box, List, ListItem, ListItemText } from "@mui/material";

export default function ZonesTabs({ tabs }: ITabsNavigationProps) {
  const theme = useTheme();
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <Box sx={{ display: "flex" }}>
      <List
        sx={{
          display: "flex",
          width: "100%",
          borderBottom: `1px solid ${theme.colors.colorsBorderBorderSecondary}`,
          padding: "0",
        }}
      >
        {tabs?.map((tab, index) => (
          <ListItem
            key={`tab-item-residential-installation-detail-${index}`}
            button
            component={RouterLink}
            to={tab.href}
            sx={{
              width: "max-content",
              borderRadius: "10px 10px 0 0",
              padding: `${theme.spacing.spacingSm} ${theme.spacing.spacingSm} ${theme.spacing.spacingLg}`,
              mr: theme.spacing.spacingLg,
              position: "relative",
            }}
          >
            <ListItemText
              sx={{ display: "flex", justifyContent: "center" }}
              primary={tab.label}
              primaryTypographyProps={{
                style: {
                  ...(theme.typography[".text-sm-semibold"] as any),
                  color: currentPath.includes(tab.href)
                    ? theme.colors.colorsTextTextBrandSecondary
                    : theme.colors.colorsTextTextQuaternary,
                },
              }}
            />
            {currentPath.includes(tab.href) && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  right: "0",
                  height: "2px",
                  backgroundColor: theme.colors.colorsTextTextBrandSecondary,
                }}
              />
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
