import React from "react";
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "@mui/material";
import useTheme from "../../../../../../../../theme/hooks/useTheme";
import DefaultChange from "../../../../components/ConfirmChangesModal/DefaultChage";
import {
  AdditionalSourcePriorityForDhwEnum,
  AdditionalSourcePriorityForHeatingEnum,
  StageStateEnum,
} from "../../../../../../../../store/services/models/installations/installationSettings";

export default function DifferencesToShowInModal({
  differencesToShow,
}: {
  differencesToShow: any;
}) {
  const { t } = useTranslation("cloud_ui");
  const { t: heatingRadioT } = useTranslation("cloud_ui", {
    keyPrefix:
      "pages.installationSettings.installerSettings.additionalHeatingSourcePrioritiesAndThresholds.heatingRadio",
  });
  const { t: DHWRadioT } = useTranslation("cloud_ui", {
    keyPrefix:
      "pages.installationSettings.installerSettings.additionalHeatingSourcePrioritiesAndThresholds.dhwRadio",
  });
  const theme = useTheme();

  const heatingOptionsToShow = {
    [AdditionalSourcePriorityForHeatingEnum.INLINE]: heatingRadioT(
      "labels.additionalInlineHeatingSource"
    ),
    [AdditionalSourcePriorityForHeatingEnum.BUFFER_TANK]: heatingRadioT(
      "labels.additionalBufferTankHeatingSource"
    ),
  };

  const dhwOptionsToShow = {
    [AdditionalSourcePriorityForDhwEnum.INLINE]: DHWRadioT(
      "labels.additionalInlineHeatingSource"
    ),
    [AdditionalSourcePriorityForDhwEnum.DHW_TANK]: DHWRadioT(
      "labels.additionalBufferTankHeatingSource"
    ),
  };

  return (
    <>
      {(differencesToShow.additionalSourcePriorityForHeating ||
        differencesToShow.additionalSourcePriorityForDhw) && (
        <Typography
          sx={{
            ...theme.typography[".text-md-semibold"],
            color: theme.colors.colorsTextTextPrimary,
            mb: theme.spacing.spacingXl,
          }}
        >
          {t(
            "pages.installationSettings.installerSettings.additionalHeatingSourcePrioritiesAndThresholds.prioritiesTitle"
          )}
        </Typography>
      )}
      {differencesToShow?.additionalSourcePriorityForHeating && (
        <DefaultChange
          isVertical
          changedValue={
            heatingOptionsToShow[
              differencesToShow?.additionalSourcePriorityForHeating
                .newValue as AdditionalSourcePriorityForHeatingEnum
            ]
          }
          previousValue={
            heatingOptionsToShow[
              differencesToShow?.additionalSourcePriorityForHeating
                .previousValue as AdditionalSourcePriorityForHeatingEnum
            ]
          }
          title={`${t(
            "pages.installationSettings.installerSettings.additionalHeatingSourcePrioritiesAndThresholds.priorityForHeating"
          )}`}
        />
      )}
      {differencesToShow?.additionalSourcePriorityForDhw && (
        <DefaultChange
          isVertical
          changedValue={
            dhwOptionsToShow[
              differencesToShow?.additionalSourcePriorityForDhw
                .newValue as AdditionalSourcePriorityForDhwEnum
            ]
          }
          previousValue={
            dhwOptionsToShow[
              differencesToShow?.additionalSourcePriorityForDhw
                .previousValue as AdditionalSourcePriorityForDhwEnum
            ]
          }
          title={`${t(
            "pages.installationSettings.installerSettings.additionalHeatingSourcePrioritiesAndThresholds.priorityForDHW"
          )}`}
        />
      )}
      {(differencesToShow.additionalSourcePriorityForDhw ||
        differencesToShow.additionalSourcePriorityForHeating) && (
        <Divider
          sx={{
            width: "100%",
            borderColor: theme.colors.colorsBorderBorderSecondary,
          }}
        />
      )}

      {(differencesToShow.outdoorThresholdTemp ||
        differencesToShow.heatBalanceForHeating ||
        differencesToShow.heatBalanceForDwh) && (
        <Typography
          sx={{
            ...theme.typography[".text-md-semibold"],
            color: theme.colors.colorsTextTextPrimary,
            mb: theme.spacing.spacingLg,
            mt: theme.spacing.spacingLg,
          }}
        >
          {t(
            "pages.installationSettings.installerSettings.additionalHeatingSourcePrioritiesAndThresholds.thresholds"
          )}
        </Typography>
      )}
      {differencesToShow?.outdoorThresholdTemp && (
        <DefaultChange
          changedValue={
            differencesToShow?.outdoorThresholdTemp.value.newValue + "°C"
          }
          previousValue={
            differencesToShow?.outdoorThresholdTemp.value.previousValue + "°C"
          }
          title={`${t(
            "pages.installationSettings.installerSettings.additionalHeatingSourcePrioritiesAndThresholds.fieldsLabels.outdorTempThreshold"
          )}`}
        />
      )}
      {differencesToShow?.heatBalanceForHeating && (
        <DefaultChange
          changedValue={
            differencesToShow?.heatBalanceForHeating.value.newValue + "°C"
          }
          previousValue={
            differencesToShow?.heatBalanceForHeating.value.previousValue + "°C"
          }
          title={`${t(
            "pages.installationSettings.installerSettings.additionalHeatingSourcePrioritiesAndThresholds.fieldsLabels.heatBalanceThresholdForHeating"
          )}`}
        />
      )}
      {differencesToShow?.heatBalanceForDwh && (
        <DefaultChange
          changedValue={
            differencesToShow?.heatBalanceForDwh.value.newValue + "°C"
          }
          previousValue={
            differencesToShow?.heatBalanceForDwh.value.previousValue + "°C"
          }
          title={`${t(
            "pages.installationSettings.installerSettings.additionalHeatingSourcePrioritiesAndThresholds.fieldsLabels.heatBalanceThresholdForDHW"
          )}`}
        />
      )}
    </>
  );
}
