import { AxiosResponse } from "axios";
import { BaseAPI } from "./BaseAPI";
import NewOrganizationRequest from "./requests/NewOrganizationRequest";
import NewEntityResponse from "./responses/NewEntityResponse";

export enum OrganizationTypes {
  DISTRIBUTOR = "DISTRIBUTOR",
  INSTALLER = "INSTALLER",
  COMMERCIAL_CLIENT = "COMMERCIAL_CLIENT",
  PRIVATE_CLIENT = "PRIVATE_CLIENT",
}

export enum OrganizationStatuses {
  PENDING = "PENDING",
  ACTIVE = "ACTIVE",
}

export interface IOrganization {
  uuid: string;
  country: string;
  name: string;
  type: OrganizationTypes;
}

export interface IParendChildOrganization {
  uuid?: string;
  name: string;
  type: OrganizationTypes;
  address: string;
  phone: string;
  status: OrganizationStatuses;
}

export interface IParendAndChildOrganizations {
  parents: Array<IParendChildOrganization>;
  children: Array<IParendChildOrganization>;
}

export interface IOrganizationDetails {
  uuid: string;
  parentUuid: string | null;
  name: string;
  country: string;
  address: string;
  phone: string | null;
  loggameraAccess: boolean;
  type: OrganizationTypes;
}

export interface IOrganizationUpdateBody {
  name: string | null;
  country: string | null;
  address: string | null;
  phone: string | null;
  loggameraAccess: boolean;
  parents: Array<string> | null;
  children: Array<string> | null;
}

export interface IOrganizationsToShare {
  parentOrgUuid?: string;
  childOrgUuid?: string;
}

export class OrganizationsAPI extends BaseAPI {
  constructor(navigate: Function) {
    super(navigate);
  }

  public async getOrganizations(
    country?: string
  ): Promise<AxiosResponse<IOrganization[]>> {
    const token = this.getAuthToken();
    const options = {
      headers: this.getHeaders(token),
    };

    return await this.getAxios().get<IOrganization[]>(
      `/organizations`,
      options
    );
  }

  public async getParentAndChildCompanies(): Promise<
    AxiosResponse<IParendAndChildOrganizations>
  > {
    const token = this.getAuthToken();
    const options = {
      headers: this.getHeaders(token),
    };

    return await this.getAxios().get<IParendAndChildOrganizations>(
      `/organizations/me/partnerships`,
      options
    );
  }

  public async getOrganization(
    uuid: string
  ): Promise<AxiosResponse<IOrganizationDetails>> {
    const token = this.getAuthToken();
    const options = {
      headers: this.getHeaders(token),
    };

    return await this.getAxios().get<IOrganizationDetails>(
      `/organizations/${uuid}`,
      options
    );
  }

  public async createInstaller(
    request: NewOrganizationRequest
  ): Promise<AxiosResponse<NewEntityResponse<string>>> {
    const token = this.getAuthToken();
    const options = {
      headers: this.getHeaders(token),
    };

    return await this.getAxios().post<NewEntityResponse<string>>(
      "/organizations",
      request.toJSON(),
      options
    );
  }

  public async editOrganization(
    organizationId: string,
    organization: IOrganizationUpdateBody
  ): Promise<AxiosResponse<void>> {
    const token = this.getAuthToken();
    const options = {
      headers: this.getHeaders(token),
    };
    const { loggameraAccess, ...dataWithoutLoggameraAccess } = organization;

    return await this.getAxios().put<void>(
      `/organizations/${organizationId}`,
      JSON.stringify(dataWithoutLoggameraAccess),
      options
    );
  }

  public async shareInstallation(
    installationId: string,
    organizationsToShare: IOrganizationsToShare
  ): Promise<AxiosResponse<void>> {
    const token = this.getAuthToken();
    const options = {
      headers: this.getHeaders(token),
    };

    return await this.getAxios().post<void>(
      `/installations/${installationId}/sharing`,
      organizationsToShare,
      options
    );
  }

  public async deleteOrganization(
    installerId: string
  ): Promise<AxiosResponse<void>> {
    const token = this.getAuthToken();
    const options = {
      headers: this.getHeaders(token),
    };

    return await this.getAxios().delete<void>(
      `/organizations/${installerId}`,
      options
    );
  }
}
