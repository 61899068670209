import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useTheme from "../../theme/hooks/useTheme";
import { formatDate } from "../../util/DateUtil";

const Timer = ({
  timestamp,
}: {
  timestamp: any;
}) => {
  const { t } = useTranslation("cloud_ui");
  const theme = useTheme();
  const [lastUpdate, setLastUpdate] = useState(new Date());

  useEffect(() => {
    setLastUpdate(new Date(timestamp));
  }, [timestamp])

  return (
    <Typography
      sx={{
        ...theme.typography[".text-md-medium"],
        color: theme.colors.colorsTextTextQuaternary,
        display: "flex",
        justifyContent: "center",
      }}
    >
      {t("pages.realTimeData.timerLabel")} {formatDate(`${lastUpdate}`)}
    </Typography>
  );
};

export default Timer;
