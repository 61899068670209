import React from "react";
import useTheme from "../../../theme/hooks/useTheme";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Button, ButtonType } from "../../../components/Button/Button";

interface IInforPageProps {
  icon: any;
  title: string;
  description: string;
  hideButton?: boolean;
}

export const InfoPage = ({
  icon,
  title,
  description,
  hideButton,
}: IInforPageProps) => {
  const { t } = useTranslation("cloud_ui");
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "100vw" }}>
      <Box
        component="form"
        sx={{
          maxWidth: "375px",
          padding: theme.spacing.spacing2xl,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mb: theme.spacing.spacing4xl,
            mt: theme.spacing.spacing6xl,
          }}
        >
          <Box
            sx={{
              width: "56px",
              height: "56px",
              border: `1px solid ${theme.colors.componentColorsComponentsIconsFeaturedIconsModernFeaturedIconModernBorder}`,
              borderRadius: "12px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mb: theme.spacing.spacing3xl,
            }}
          >
            {icon}
          </Box>
          <Typography
            sx={{
              ...theme.typography[".display-xs-semibold"],
              color: theme.colors.colorsTextTextPrimary,
              mb: theme.spacing.spacingMd,
              textAlign: "center",
              maxWidth: "210px",
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              ...theme.typography[".text-md-regular"],
              color: theme.colors.colorsTextTextTertiary,
              mb: theme.spacing.spacingMd,
              textAlign: "center",
            }}
          >
            {description}
          </Typography>
        </Box>
        {!hideButton && (
          <Button
            label={`${t(
              "pages.pairingVerificationPage.backToFleetManagerButtonText"
            )}`}
            buttonType={ButtonType.LinkGray}
            onClick={() => navigate("/portal/commercial-installations")}
          />
        )}
      </Box>
    </Box>
  );
};
