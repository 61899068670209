export const policyEN = {
    privacy_policy: `
        <h1 style="text-transform: uppercase">privacy policy for {{productName}}</h1>
        <p class="Huvudrubrikenklaavtal"><span lang="EN-GB" style="font-size:10.0pt;

        font-family:&quot;Arial&quot;,sans-serif;text-transform:none;font-weight:normal">ES

        Energy Save Holding AB (publ), company registration number 559117-1292,

        Nitgatan 2, 441 38 Alingsås, (”</span><span lang="EN-GB" style="font-size:10.0pt;

        font-family:&quot;Arial&quot;,sans-serif;text-transform:none">Energy Save</span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;text-transform:

        none;font-weight:normal">“, ”</span><span lang="EN-GB" style="font-size:10.0pt;

        font-family:&quot;Arial&quot;,sans-serif;text-transform:none">we</span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;text-transform:none;

        font-weight:normal">” or ”</span><span lang="EN-GB" style="font-size:10.0pt;

        font-family:&quot;Arial&quot;,sans-serif;text-transform:none">us</span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;text-transform:none;

        font-weight:normal">”) is the controller for the processing of your personal

        data regarding {{productName}}.</span></p>



        <p class="Huvudrubrikenklaavtal"><span lang="EN-GB" style="font-size:10.0pt;

        font-family:&quot;Arial&quot;,sans-serif;text-transform:none;font-weight:normal">Energy

        Save cares about your personal integrity and strives to always protect your

        personal data in the best possible way. It is Energy Save's goal to comply with

        all applicable laws and regulations for personal data protection. This privacy

        policy explains what kind of information Energy Save collects including why and

        how Energy Save processes your personal data. The privacy policy describes how

        we collect, use, store, disclose and protect your personal data and it explains

        your rights in relation to Energy Save regarding the processing of your

        personal data. </span></p>



        <p class="Huvudrubrikenklaavtal"><span lang="EN-GB" style="font-size:10.0pt;

        font-family:&quot;Arial&quot;,sans-serif;text-transform:none;font-weight:normal">When we

        mention the Service, we mean our application {{productName}} and related web pages

        (the “</span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        text-transform:none">Service</span><span lang="EN-GB" style="font-size:10.0pt;

        font-family:&quot;Arial&quot;,sans-serif;text-transform:none;font-weight:normal">”). When

        you use the Service, interact with us, or as a representative or contact person

        on behalf of your organization, communicate with us, Energy Save collects your

        personal data and processes it in accordance with this privacy policy.

        Therefore, it is important that you read and understand the privacy policy

        before you interact with us, use the Service or enter into an agreement with

        Energy Save.</span></p>



        <p class="Huvudrubrikenklaavtal"><span lang="EN-GB" style="font-size:10.0pt;

        font-family:&quot;Arial&quot;,sans-serif;text-transform:none;font-weight:normal">Energy

        Save uses cookies and similar technologies on our website. You can read more

        about cookies in section </span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;text-transform:

        none;font-weight:normal">5.3</span><span lang="EN-GB" style="font-size:10.0pt;

        font-family:&quot;Arial&quot;,sans-serif;text-transform:none;font-weight:normal"> and in

        our Cookie consent form on our website. </span></p>



        <p class="Huvudrubrikenklaavtal"><span lang="EN-GB" style="font-size:10.0pt;

        font-family:&quot;Arial&quot;,sans-serif;text-transform:none;font-weight:normal">Some sites

        in the Service may contain links to services from third parties and such

        services may link to the Service. This privacy policy does not cover

        information about such processing that takes place within other services.

        Energy Save is not responsible for the activities of third parties, their

        processing of your personal data or their information practices. If you follow

        a link to any of these third-party services, please note that they have their

        own privacy policies which you should check before submitting any personal data

        to them.</span></p>



        <h2 style="text-indent:0in"><a name="Position"></a><span lang="EN-GB">Contents </span></h2>



        <p class="MsoNormal" style="margin-left:96.4pt;text-indent:-48.2pt"><b><span lang="EN-GB">Section 1: About our processing of personal data. </span></b><span lang="EN-GB">Here we describe what personal data is and how we process personal

        data in general. </span></p>



        <p class="MsoNormal" style="margin-left:96.4pt;text-indent:-48.2pt"><b><span lang="EN-GB">Section 2: Our processing</span></b><span lang="EN-GB">. Here we

        describe in more detail how we process your personal data when you use our

        Service and interact with Energy Save.</span></p>



        <p class="MsoNormal" style="margin-left:96.4pt;text-indent:-48.2pt"><b><span lang="EN-GB">Section 3: How we collect your data, where it is processed and how

        we share it</span></b><b><span lang="EN-GB">. </span></b><span lang="EN-GB">Here we

        describe how we collect and obtain data, which parties we may share the data

        with and where your personal data is processed.</span></p>



        <p class="MsoNormal" style="margin-left:96.4pt;text-indent:-48.2pt"><b><span lang="EN-GB">Section 4: Security. </span></b><span lang="EN-GB">Here we describe

        Energy Save's technical and organizational security measures applied to protect

        your personal data.</span></p>



        <p class="MsoNormal" style="margin-left:96.4pt;text-indent:-48.2pt"><b><span lang="EN-GB">Section 5: Your rights</span></b><span lang="EN-GB">. Here you can

        read about the rights you have towards us and how you can exercise them</span><span lang="EN-GB">.</span></p>



        <p class="MsoNormal" style="margin-left:96.4pt;text-indent:-48.2pt"><b><span lang="EN-GB">Section 6: </span></b><b><span lang="EN-GB">Changes to the privacy

        policy and contact details</span></b><span lang="EN-GB">. As our privacy policy

        may change over time as a result of new or changed processing activities, we

        encourage you to continuously read the latest version of the privacy policy and

        contact us if you have any questions at the contact details provided in this

        section.</span></p>



        <h2><a name="_Toc106015676"><span lang="EN-GB">1.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">About our processing of personal data</span></a></h2>



       <h3 style="margin-left:48.2pt"><span lang="EN-GB">1.1<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">What is personal data and what is a processing

        of personal data?</span></h3>



        <p class="IndragNormal"><span lang="EN-GB">Personal data is any kind of information

        that can be directly or indirectly attributed to a living natural person.

        Encrypted data and various types of electronic identities (e.g. IP numbers) are

        personal data if they can be linked to natural persons.</span></p>



        <p class="IndragNormal"><span lang="EN-GB">Processing of personal data is

        everything that happens with the personal data. Every action taken with

        personal data constitutes processing, regardless of whether it is performed

        automatically or not. Examples of common types of processing are collection,

        registration, organization, structuring, storage, processing, transmission and

        deletion.<a name="_Toc106015677"></a></span></p>



        <h2><span lang="EN-GB">2.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">Our processing </span><a name="_Toc106015678"></a></h2>



        <p class="IndragNormal"><span lang="EN-GB">Energy Save is responsible for the

        processing of your personal data carried out by Energy Save and such personal

        data processing carried out by our suppliers on behalf of us.</span></p>



       <h3 style="margin-left:48.2pt"><span lang="EN-GB">2.1<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">When you use the Service </span></h3>



        <table class="TableNormal1" border="1" cellspacing="0" cellpadding="0" width="604" style="margin-left:6.85pt;border-collapse:collapse;border:none">

        <tbody><tr style="height:12.2pt">

        <td width="155" valign="top" style="width:116.25pt;border:solid black 1.0pt;

        background:#BFBFBF;padding:0in 0in 0in 0in;height:12.2pt">

        <p class="TableParagraph" style="margin-left:5.5pt;text-indent:0in;line-height:

        11.2pt;page-break-after:avoid"><b><span lang="EN-GB" style="font-size:10.0pt;

        font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.1pt">Purpose</span></b></p>

        </td>

        <td width="247" valign="top" style="width:185.3pt;border:solid black 1.0pt;

        border-left:none;background:#BFBFBF;padding:0in 0in 0in 0in;height:12.2pt">

        <p class="TableParagraph" style="margin-left:5.35pt;text-indent:0in;line-height:

        11.2pt;page-break-after:avoid"><b><span lang="EN-GB" style="font-size:10.0pt;

        font-family:&quot;Arial&quot;,sans-serif;color:black">Processing performed</span></b></p>

        </td>

        <td width="201" valign="top" style="width:151.1pt;border:solid black 1.0pt;

        border-left:none;background:#BFBFBF;padding:0in 0in 0in 0in;height:12.2pt">

        <p class="TableParagraph" style="margin-left:5.45pt;text-indent:0in;line-height:

        11.2pt;page-break-after:avoid"><b><span lang="EN-GB" style="font-size:10.0pt;

        font-family:&quot;Arial&quot;,sans-serif;color:black">Categories of personal data</span></b></p>

        </td>

        </tr>

        <tr style="height:30.85pt">

        <td width="155" valign="top" style="width:116.25pt;border:solid black 1.0pt;

        border-top:none;background:white;padding:0in 0in 0in 0in;height:30.85pt">

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:7.25pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in;

        page-break-after:avoid"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif;color:black">To be able to provide, evaluate, develop and

        improve the user experience in the Service as well as development and

        adaptation of services, products and systems.</span></p>

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:7.25pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in;

        page-break-after:avoid"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif;letter-spacing:-.1pt">&nbsp;</span></p>

        </td>

        <td width="247" valign="top" style="width:185.3pt;border-top:none;border-left:

        none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;

        background:white;padding:0in 0in 0in 0in;height:30.85pt">

        <p class="TableParagraph" style="margin-top:0in;margin-right:10.95pt;

        margin-bottom:0in;margin-left:15.2pt;margin-bottom:.0001pt;page-break-after:

        avoid"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">Collecting

        and storing for the purpose of providing functionality in the Service.</span></p>

        <p class="TableParagraph" style="margin-top:0in;margin-right:10.95pt;

        margin-bottom:0in;margin-left:15.2pt;margin-bottom:.0001pt;page-break-after:

        avoid"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">Collecting,

        storing and analysing behaviour in the Service after given consent. </span></p>

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:12.35pt;

        margin-bottom:0in;margin-left:15.2pt;margin-bottom:.0001pt;page-break-after:

        avoid"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">Production

        of documentation to improve IT systems with the aim of generally increasing

        the security of Energy Save and our users<span style="letter-spacing:-.1pt">.</span></span></p>

        <p class="TableParagraph" style="margin-top:0in;margin-right:10.95pt;

        margin-bottom:0in;margin-left:15.2pt;margin-bottom:.0001pt;page-break-after:

        avoid"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">Customisation

        of the Service to make it more user-friendly.</span></p>

        <p class="TableParagraph" style="margin-top:0in;margin-right:10.95pt;

        margin-bottom:0in;margin-left:15.2pt;margin-bottom:.0001pt;page-break-after:

        avoid"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black">Collection

        of data from your ES heat pump in order to improve our services and products.</span></p>

        </td>

        <td width="201" valign="top" style="width:151.1pt;border-top:none;border-left:

        none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;

        padding:0in 0in 0in 0in;height:30.85pt">

        <p class="TableParagraph" style="margin-top:.1pt;margin-right:29.8pt;

        margin-bottom:0in;margin-left:15.3pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Name

        and email address.</span></p>

        <p class="TableParagraph" style="margin-top:.1pt;margin-right:29.8pt;

        margin-bottom:0in;margin-left:15.3pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Technical

        data concerning devices used and their settings (e.g. language setting, IP

        address, browser settings, time zone, operating system, screen resolution and

        platform and user-generated data, such as browsing history</span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:

        -.1pt">)</span></p>

        <p class="TableParagraph" style="margin-top:.1pt;margin-right:29.8pt;

        margin-bottom:0in;margin-left:15.3pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Information

        about how you have interacted with us and used the Service, from where and

        how long different pages were visited, response times, download errors, how

        you access and leave the Service, etc.</span></p>

        </td>

        </tr>

        <tr style="height:27.9pt">

        <td width="604" colspan="3" valign="top" style="width:452.65pt;border:solid black 1.0pt;

        border-top:none;padding:0in 0in 0in 0in;height:27.9pt">

        <p class="TableParagraph" style="margin-top:0in;margin-right:55.45pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in;

        line-height:12.0pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">Lawful grounds:</span></b></p>

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:5.15pt;

        margin-bottom:0in;margin-left:41.5pt;margin-bottom:.0001pt;text-indent:-.5in;

        line-height:12.0pt"><i><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">(i)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span></i><i><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">Fulfilment of agreement. </span></i><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Processing in

        connection with our provision of the Service and Premium Services so that we

        can fulfil our agreement with you.</span></p>

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:5.15pt;

        margin-bottom:0in;margin-left:41.5pt;margin-bottom:.0001pt;text-indent:-.5in;

        line-height:12.0pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">(ii)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><i><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Legitimate

        interest. </span></i><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">In the event that you represent a corporate customer, we

        process your personal data based on our legitimate interest in providing the

        Service and the Premium Services in accordance with our agreement with your

        employer, which we deem to be justified in a balancing of interests between

        our interest and your interest in protecting your personal integrity.</span></p>

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:5.15pt;

        margin-bottom:0in;margin-left:41.5pt;margin-bottom:.0001pt;text-indent:-.5in;

        line-height:12.0pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">(iii)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><i><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Legitimate

        interest. </span></i><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">Processing also takes place with the support of Energy

        Save's legitimate interest in developing functions in its systems for

        adaptation to user behaviour, increased security and increased

        user-friendliness, which we deem to be justified in a balancing of interests

        between our interest and your interest in protecting your personal privacy. </span></p>

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:5.15pt;

        margin-bottom:0in;margin-left:41.5pt;margin-bottom:.0001pt;text-indent:-.5in;

        line-height:12.0pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">(iv)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><i><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Consent.

        </span></i><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Technical

        data about your device and how you interacted on our website is collected

        when you visit our website. You are always given an opportunity to approve or

        deny the use of cookies when you use the Service. You can read more in

        section </span><b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;text-transform:

        uppercase">5.3</span></b><b><span lang="EN-GB" style="font-size:10.0pt;

        font-family:&quot;Arial&quot;,sans-serif;text-transform:uppercase"> </span></b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">or in our

        Cookie policy</span><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">.</span></p>

        </td>

        </tr>

        <tr style="height:17.5pt">

        <td width="604" colspan="3" valign="top" style="width:452.65pt;border:solid black 1.0pt;

        border-top:none;padding:0in 0in 0in 0in;height:17.5pt">

        <p class="TableParagraph" style="margin-top:0in;margin-right:55.45pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in;

        line-height:12.0pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">Retention period:<span style="letter-spacing:-.1pt"> </span></span></b></p>

        <p class="TableParagraph" style="margin-top:0in;margin-right:55.55pt;

        margin-bottom:0in;margin-left:5.4pt;margin-bottom:.0001pt;text-indent:0in;

        line-height:12.0pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">Until the user account is terminated.</span></p>

        <p class="TableParagraph" style="margin-top:0in;margin-right:55.55pt;

        margin-bottom:0in;margin-left:5.4pt;margin-bottom:.0001pt;text-indent:0in;

        line-height:12.0pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">See Energy Save’s Cookie Policy for information on

        retention period for cookies</span><span lang="EN-GB" style="font-size:10.0pt;

        font-family:&quot;Arial&quot;,sans-serif">.</span></p>

        </td>

        </tr>

        <tr style="height:15.65pt">

        <td width="604" colspan="3" valign="top" style="width:452.65pt;border:solid black 1.0pt;

        border-top:none;padding:0in 0in 0in 0in;height:15.65pt">

        <p class="TableParagraph" style="margin-top:0in;margin-right:55.45pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in;

        line-height:12.0pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">Source: </span></b><span lang="EN-GB" style="font-size:

        10.0pt;font-family:&quot;Arial&quot;,sans-serif">The data is collected from the data

        subject. </span></p>

        </td>

        </tr>

        <tr style="height:15.95pt">

        <td width="604" colspan="3" valign="top" style="width:452.65pt;border:solid black 1.0pt;

        border-top:none;padding:0in 0in 0in 0in;height:15.95pt">

        <p class="TableParagraph" style="margin-top:0in;margin-right:55.45pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in;

        line-height:12.0pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">Sharing:</span></b><span lang="EN-GB" style="font-size:

        10.0pt;font-family:&quot;Arial&quot;,sans-serif"> The data may be shared with our IT

        service providers.</span></p>

        </td>

        </tr>

        </tbody></table>



        <p class="IndragNormal" style="margin-left:0in"><span lang="EN-GB">&nbsp;</span></p>



       <h3 style="page-break-after:auto"><span lang="EN-GB">2.2<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">Provision of support and customer service, case

        management and processing of data subject requests</span></h3>



        <table class="TableNormal1" border="1" cellspacing="0" cellpadding="0" width="604" style="margin-left:6.85pt;border-collapse:collapse;border:none">

        <tbody><tr style="height:20.65pt">

        <td width="155" valign="top" style="width:116.25pt;border:solid black 1.0pt;

        background:#BFBFBF;padding:0in 0in 0in 0in;height:20.65pt">

        <p class="TableParagraph" style="margin-left:5.5pt;text-indent:0in;line-height:

        11.2pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        letter-spacing:-.1pt">Purpose</span></b></p>

        </td>

        <td width="247" valign="top" style="width:185.3pt;border:solid black 1.0pt;

        border-left:none;background:#BFBFBF;padding:0in 0in 0in 0in;height:20.65pt">

        <p class="TableParagraph" style="margin-left:5.35pt;text-indent:0in;line-height:

        11.2pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        color:black">Processing performed</span></b></p>

        </td>

        <td width="201" valign="top" style="width:151.1pt;border:solid black 1.0pt;

        border-left:none;background:#BFBFBF;padding:0in 0in 0in 0in;height:20.65pt">

        <p class="TableParagraph" style="margin-left:5.45pt;text-indent:0in;line-height:

        11.2pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        color:black">Categories of personal data</span></b></p>

        </td>

        </tr>

        <tr style="height:2.8pt">

        <td width="155" valign="top" style="width:116.25pt;border:solid black 1.0pt;

        border-top:none;background:white;padding:0in 0in 0in 0in;height:2.8pt">

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:7.25pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in"><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black;

        letter-spacing:-.1pt">Handling of customer cases, support and customer

        service.</span></p>

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:7.25pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in"><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:

        -.1pt">&nbsp;</span></p>

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:7.25pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in"><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black;

        letter-spacing:-.1pt">Follow-up of customer service matters.</span></p>

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:7.25pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.1pt">&nbsp;</span></p>

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:7.25pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in"><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black;

        letter-spacing:-.1pt">Handling of requests for rectification, deletion or record

        extracts in accordance with this privacy policy and applicable data protection

        legislation in order to comply with applicable data protection legislation.&nbsp; </span></p>

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:7.25pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in"><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:

        -.1pt">&nbsp;</span></p>

        <p class="TableParagraph" style="margin-left:5.5pt;text-indent:0in"><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">&nbsp;</span></p>

        </td>

        <td width="247" valign="top" style="width:185.3pt;border-top:none;border-left:

        none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;

        background:white;padding:0in 0in 0in 0in;height:2.8pt">

        <p class="TableParagraph" style="margin-top:0in;margin-right:10.9pt;margin-bottom:

        0in;margin-left:15.2pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol;letter-spacing:-.1pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black;

        letter-spacing:-.1pt">Identification of the customer for customer service

        matters and other requests.</span></p>

        <p class="TableParagraph" style="margin-top:0in;margin-right:10.9pt;margin-bottom:

        0in;margin-left:15.2pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol;letter-spacing:-.1pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black;

        letter-spacing:-.1pt">Email communication with customers and answering

        questions for customer service.</span></p>

        <p class="TableParagraph" style="margin-top:0in;margin-right:10.9pt;margin-bottom:

        0in;margin-left:15.2pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol;letter-spacing:-.1pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black;

        letter-spacing:-.1pt">Investigation of complaints and support matters

        (including technical support).</span></p>

        <p class="TableParagraph" style="margin-top:0in;margin-right:10.9pt;margin-bottom:

        0in;margin-left:15.2pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol;letter-spacing:-.1pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black;

        letter-spacing:-.1pt">Investigation and handling of complaint cases.</span></p>

        <p class="TableParagraph" style="margin-top:0in;margin-right:10.9pt;margin-bottom:

        0in;margin-left:15.2pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol;letter-spacing:-.1pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black;

        letter-spacing:-.1pt">Handling requests for rectification, deletion or record

        extracts according to this privacy policy.</span></p>

        <p class="TableParagraph" style="margin-top:0in;margin-right:10.9pt;margin-bottom:

        0in;margin-left:15.2pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol;letter-spacing:-.1pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black;

        letter-spacing:-.1pt">Follow-up of Energy Save's handling of your case.</span></p>

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:7.25pt;

        margin-bottom:0in;margin-left:15.2pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        color:black;letter-spacing:-.1pt">Statistics.</span></p>

        </td>

        <td width="201" valign="top" style="width:151.1pt;border-top:none;border-left:

        none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;

        padding:0in 0in 0in 0in;height:2.8pt">

        <p class="TableParagraph"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        Symbol;letter-spacing:-.1pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        letter-spacing:-.1pt">Name</span></p>

        <p class="TableParagraph"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        Symbol;letter-spacing:-.1pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        letter-spacing:-.1pt">Date of birth</span></p>

        <p class="TableParagraph"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        Symbol;letter-spacing:-.1pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        letter-spacing:-.1pt">Contact details including address, phone number and

        email address</span></p>

        <p class="TableParagraph"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        Symbol;letter-spacing:-.1pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        letter-spacing:-.1pt">Customer number</span></p>

        <p class="TableParagraph"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        Symbol;letter-spacing:-.1pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        letter-spacing:-.1pt">Case number</span></p>

        <p class="TableParagraph"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        Symbol;letter-spacing:-.1pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        letter-spacing:-.1pt">Conversation history and notes in your customer service

        case</span></p>

        <p class="TableParagraph"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        Symbol;letter-spacing:-.1pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        letter-spacing:-.1pt">Service and delivery information</span></p>

        <p class="TableParagraph" style="margin-right:29.65pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        letter-spacing:-.1pt">Technical information about your equipment</span></p>

        </td>

        </tr>

        <tr style="height:36.65pt">

        <td width="604" colspan="3" valign="top" style="width:452.65pt;border:solid black 1.0pt;

        border-top:none;padding:0in 0in 0in 0in;height:36.65pt">

        <p class="TableParagraph" style="margin-top:0in;margin-right:5.15pt;margin-bottom:

        0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in;line-height:12.0pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Lawful

        grounds:<span style="letter-spacing:-.15pt"> </span></span></b></p>

        <p class="TableParagraph" style="margin-top:0in;margin-right:5.15pt;margin-bottom:

        0in;margin-left:41.5pt;margin-bottom:.0001pt;text-indent:-.5in;line-height:

        12.0pt"><i><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        letter-spacing:-.15pt">(i)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span></i><i><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">Legitimate interest. </span></i><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">The processing takes

        place with the support of Energy Save's legitimate interest in being able to

        defend itself against legal claims in the event of complaints, which we deem

        to be justified in a balancing of interests between our interest and your

        interest in protecting your personal integrity.</span></p>

        <p class="TableParagraph" style="margin-top:0in;margin-right:5.15pt;margin-bottom:

        0in;margin-left:41.5pt;margin-bottom:.0001pt;text-indent:-.5in;line-height:

        12.0pt"><i><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">(ii)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span></i><i><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">Fulfilment of agreement. </span></i><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">The processing is

        required for the fulfilment of Energy Save's commitments according to the

        agreement with you.</span></p>

        <p class="TableParagraph" style="margin-top:0in;margin-right:5.15pt;margin-bottom:

        0in;margin-left:41.5pt;margin-bottom:.0001pt;text-indent:-.5in;line-height:

        12.0pt"><i><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">(iii)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span></i><i><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">Legal obligation. </span></i><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Processing required

        to fulfil a request for deletion, correction or record extract takes place

        with the support of Energy Save's legal obligation to satisfy the data

        subject's request according to the applicable data protection legislation.</span></p>

        </td>

        </tr>

        <tr style="height:36.6pt">

        <td width="604" colspan="3" valign="top" style="width:452.65pt;border:solid black 1.0pt;

        border-top:none;padding:0in 0in 0in 0in;height:36.6pt">

        <p class="TableParagraph" style="margin-top:0in;margin-right:55.45pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in;

        line-height:12.0pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">Retention period:<span style="letter-spacing:-.1pt"> </span></span></b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Until the

        customer service case has been closed and for a period of 12 months

        thereafter. When requesting the deletion of personal data in accordance with

        the applicable data protection legislation, the data is deleted immediately

        after the request has been fulfilled, with the exception of data in Energy

        Save's system that Energy Save is obliged by law to save.</span></p>

        </td>

        </tr>

        <tr style="height:24.2pt">

        <td width="604" colspan="3" valign="top" style="width:452.65pt;border:solid black 1.0pt;

        border-top:none;padding:0in 0in 0in 0in;height:24.2pt">

        <p class="TableParagraph" style="margin-top:0in;margin-right:55.45pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in;

        line-height:12.0pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">Source: </span></b><span lang="EN-GB" style="font-size:

        10.0pt;font-family:&quot;Arial&quot;,sans-serif">The data is collected from the data

        subject. Case numbers are generated internally by Energy Save's system.</span></p>

        </td>

        </tr>

        </tbody></table>



       <h3 style="margin-left:48.2pt;text-indent:0in;page-break-after:auto"><span lang="EN-GB">&nbsp;</span></h3>



       <h3 style="margin-left:48.2pt;page-break-after:auto"><span lang="EN-GB">2.3<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">Surveys and questionnaires</span></h3>



        <table class="TableNormal1" border="1" cellspacing="0" cellpadding="0" width="604" style="margin-left:6.3pt;border-collapse:collapse;border:none">

        <tbody><tr style="height:12.2pt">

        <td width="156" valign="top" style="width:116.8pt;border:solid black 1.0pt;

        background:#BFBFBF;padding:0in 0in 0in 0in;height:12.2pt">

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:0in;margin-bottom:

        0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in;line-height:11.15pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:

        -.1pt">Purpose</span></b></p>

        </td>

        <td width="247" valign="top" style="width:185.3pt;border:solid black 1.0pt;

        border-left:none;background:#BFBFBF;padding:0in 0in 0in 0in;height:12.2pt">

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:0in;margin-bottom:

        0in;margin-left:5.35pt;margin-bottom:.0001pt;text-indent:0in;line-height:

        11.15pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        color:black">Processing performed</span></b></p>

        </td>

        <td width="201" valign="top" style="width:151.1pt;border:solid black 1.0pt;

        border-left:none;background:#BFBFBF;padding:0in 0in 0in 0in;height:12.2pt">

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:0in;margin-bottom:

        0in;margin-left:5.45pt;margin-bottom:.0001pt;text-indent:0in;line-height:

        11.15pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        color:black">Categories of personal data</span></b></p>

        </td>

        </tr>

        <tr style="height:150.25pt">

        <td width="156" valign="top" style="width:116.8pt;border:solid black 1.0pt;

        border-top:none;padding:0in 0in 0in 0in;height:150.25pt">

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:7.25pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in"><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">To be able

        to implement and manage participation in surveys and handle questionnaires

        and analyse the results thereof and compile statistics.</span></p>

        </td>

        <td width="247" valign="top" style="width:185.3pt;border-top:none;border-left:

        none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;

        padding:0in 0in 0in 0in;height:150.25pt">

        <p class="TableParagraph" style="margin-top:0in;margin-right:10.45pt;

        margin-bottom:0in;margin-left:15.2pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Communication

        before and after participation in a survey or filling in a questionnaire.</span></p>

        <p class="TableParagraph" style="margin-top:0in;margin-right:10.45pt;

        margin-bottom:0in;margin-left:15.2pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Telephone

        calls and email correspondence with participants.</span></p>

        <p class="TableParagraph" style="margin-top:0in;margin-right:10.45pt;

        margin-bottom:0in;margin-left:15.2pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Collection,

        storage, processing and analysis of responses and data in the survey or

        questionnaire.</span></p>

        <p class="TableParagraph" style="margin-top:0in;margin-right:16.95pt;

        margin-bottom:0in;margin-left:15.2pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Compilation

        of statistics, which are compiled on a completely anonymous basis.</span></p>

        </td>

        <td width="201" valign="top" style="width:151.1pt;border-top:none;border-left:

        none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;

        padding:0in 0in 0in 0in;height:150.25pt">

        <p class="TableParagraph"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        Symbol;letter-spacing:-.1pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        letter-spacing:-.1pt">Name</span></p>

        <p class="TableParagraph"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        Symbol;letter-spacing:-.1pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        letter-spacing:-.1pt">Age</span></p>

        <p class="TableParagraph"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        Symbol;letter-spacing:-.1pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        letter-spacing:-.1pt">Contact details (e.g. address, email and phone number)</span></p>

        <p class="TableParagraph"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        Symbol;letter-spacing:-.1pt">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        letter-spacing:-.1pt">Information provided in the survey or survey including

        the sales estimate</span></p>

        <p class="TableParagraph" style="margin-right:12.5pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        letter-spacing:-.1pt">Name or alias that you enter to participate in the

        survey or fill in a questionnaire</span></p>

        </td>

        </tr>

        <tr style="height:12.0pt">

        <td width="604" colspan="3" valign="top" style="width:453.2pt;border:solid black 1.0pt;

        border-top:none;padding:0in 0in 0in 0in;height:12.0pt">

        <p class="TableParagraph" style="margin-top:0in;margin-right:55.45pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in;

        line-height:12.0pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">Lawful grounds:<span style="letter-spacing:-.15pt"> </span></span></b><i><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Consent.<span style="letter-spacing:-.1pt"> </span></span></i><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:-.1pt">You

        will be asked to provide your consent in connection with the specific survey

        or questionnaire.</span></p>

        </td>

        </tr>

        <tr style="height:24.3pt">

        <td width="604" colspan="3" valign="top" style="width:453.2pt;border:solid black 1.0pt;

        border-top:none;padding:0in 0in 0in 0in;height:24.3pt">

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:0in;margin-bottom:

        0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in;line-height:11.15pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Retention

        period:<span style="letter-spacing:-.25pt"> </span></span></b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">During the

        time that the survey (including any evaluation) is in progress. You will be

        given information about the time period of the survey in connection with

        being invited to participate<span style="letter-spacing:-.1pt">.</span></span></p>

        </td>

        </tr>

        <tr style="height:10.6pt">

        <td width="604" colspan="3" valign="top" style="width:453.2pt;border:solid black 1.0pt;

        border-top:none;padding:0in 0in 0in 0in;height:10.6pt">

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:0in;margin-bottom:

        0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in;line-height:11.15pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Source: </span></b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">The data

        is collected from the data subject.</span></p>

        </td>

        </tr>

        <tr style="height:12.9pt">

        <td width="604" colspan="3" valign="top" style="width:453.2pt;border:solid black 1.0pt;

        border-top:none;padding:0in 0in 0in 0in;height:12.9pt">

        <p class="MsoNormal" style="margin-top:.05pt;margin-right:5.15pt;margin-bottom:

        0in;margin-left:5.5pt;text-autospace:none"><b><span lang="EN-GB">Sharing</span></b><b><span lang="EN-GB">:</span></b><span lang="EN-GB"> Where applicable, the data is shared

        with partners who help Energy Save with the survey. You will be informed in

        each individual case if this is applicable for the survey in which you are

        participating.</span></p>

        </td>

        </tr>

        </tbody></table>



       <h3 style="margin-left:0in;text-indent:0in;page-break-after:auto"><span lang="EN-GB">&nbsp;</span></h3>



       <h3 style="margin-left:48.2pt;page-break-after:auto"><span lang="EN-GB">2.4<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">Security purposes</span></h3>



        <table class="TableNormal1" border="1" cellspacing="0" cellpadding="0" width="617" style="margin-left:6.85pt;border-collapse:collapse;border:none">

        <tbody><tr style="height:13.55pt">

        <td width="159" valign="top" style="width:118.9pt;border:solid black 1.0pt;

        background:#BFBFBF;padding:0in 0in 0in 0in;height:13.55pt">

        <p class="TableParagraph" style="margin-left:5.5pt;text-indent:0in;line-height:

        11.2pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        letter-spacing:-.1pt">Purpose</span></b></p>

        </td>

        <td width="253" valign="top" style="width:189.55pt;border:solid black 1.0pt;

        border-left:none;background:#BFBFBF;padding:0in 0in 0in 0in;height:13.55pt">

        <p class="TableParagraph" style="margin-left:5.35pt;text-indent:0in;line-height:

        11.2pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        color:black">Processing performed</span></b></p>

        </td>

        <td width="206" valign="top" style="width:154.55pt;border:solid black 1.0pt;

        border-left:none;background:#BFBFBF;padding:0in 0in 0in 0in;height:13.55pt">

        <p class="TableParagraph" style="margin-left:5.45pt;text-indent:0in;line-height:

        11.2pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        color:black">Categories of personal data</span></b></p>

        </td>

        </tr>

        <tr style="height:32.1pt">

        <td width="159" valign="top" style="width:118.9pt;border:solid black 1.0pt;

        border-top:none;background:white;padding:0in 0in 0in 0in;height:32.1pt">

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:7.25pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in"><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;color:black;

        letter-spacing:-.1pt">Security purposes and prevention of abuse of service or

        to prevent and investigate crimes against Energy Save.</span></p>

        </td>

        <td width="253" valign="top" style="width:189.55pt;border-top:none;border-left:

        none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;

        background:white;padding:0in 0in 0in 0in;height:32.1pt">

        <p class="TableParagraph" style="margin-top:0in;margin-right:10.95pt;

        margin-bottom:0in;margin-left:15.2pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        color:black">Collection and storage of IP addresses.</span></p>

        <p class="TableParagraph" style="margin-top:0in;margin-right:10.95pt;

        margin-bottom:0in;margin-left:15.2pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        color:black">Protection and improvement of our IT environment against attacks

        and intrusions.</span></p>

        <p class="TableParagraph" style="margin-top:0in;margin-right:10.95pt;

        margin-bottom:0in;margin-left:15.2pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        color:black">Preventing spamming, phishing, harassment, attempted

        unauthorized logins to user accounts, or other actions prohibited by law or

        our terms.</span></p>

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:10.75pt;

        margin-bottom:0in;margin-left:15.2pt;margin-bottom:.0001pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;

        color:black">Investigation of possible fraud or other violations of the law.</span></p>

        </td>

        <td width="206" valign="top" style="width:154.55pt;border-top:none;border-left:

        none;border-bottom:solid black 1.0pt;border-right:solid black 1.0pt;

        padding:0in 0in 0in 0in;height:32.1pt">

        <p class="TableParagraph"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">IP

        addresses</span></p>

        <p class="TableParagraph"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Information

        about how our digital services are used, for example activities and system

        calls.</span></p>

        <p class="TableParagraph"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Technical

        data concerning devices used and their settings (e.g. language setting, IP

        address, browser settings, time zone, operating system, screen resolution and

        platform).</span></p>

        <p class="TableParagraph"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">User

        Generated Data</span></p>

        <p class="TableParagraph" style="margin-top:.1pt;text-indent:0in"><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">(e.g.

        browsing history).</span></p>

        </td>

        </tr>

        <tr style="height:13.7pt">

        <td width="617" colspan="3" valign="top" style="width:463.0pt;border:solid black 1.0pt;

        border-top:none;padding:0in 0in 0in 0in;height:13.7pt">

        <p class="TableParagraph" style="margin-top:0in;margin-right:55.45pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in;

        line-height:12.0pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">Lawful grounds:<span style="letter-spacing:-.15pt"> </span></span></b></p>

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:5.15pt;

        margin-bottom:0in;margin-left:41.5pt;margin-bottom:.0001pt;text-indent:-.5in;

        line-height:12.0pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">(i)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><i><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Consent.

        </span></i><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Technical

        data about your device and how you interacted with our platform is collected

        through the use of cookies. You are always given an opportunity to approve or

        deny the use of cookies when you use the Service. You can read more in

        section </span><b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;text-transform:

        uppercase">5.3</span></b><b><span lang="EN-GB" style="font-size:10.0pt;

        font-family:&quot;Arial&quot;,sans-serif;text-transform:uppercase"> </span></b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">or in our

        Cookie Policy</span><i><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">.</span></i></p>

        <p class="TableParagraph" style="margin-top:.05pt;margin-right:5.15pt;

        margin-bottom:0in;margin-left:41.5pt;margin-bottom:.0001pt;text-indent:-.5in;

        line-height:12.0pt"><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">(ii)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><i><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif">Legitimate

        interest. </span></i><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">The processing takes place in support of Energy Save's

        legitimate interest in monitoring security and calls in its systems and to

        ensure the investigation of legal claims and other rights, which we deem to

        be justified in a balancing of interests between our interest and your

        interest in the protection of your personal privacy. </span></p>

        </td>

        </tr>

        <tr style="height:18.2pt">

        <td width="617" colspan="3" valign="top" style="width:463.0pt;border:solid black 1.0pt;

        border-top:none;padding:0in 0in 0in 0in;height:18.2pt">

        <p class="TableParagraph" style="margin-top:0in;margin-right:55.45pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in;

        line-height:12.0pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">Retention period:<span style="letter-spacing:-.1pt"> </span></span></b><span lang="EN-GB" style="font-size:10.0pt;font-family:&quot;Arial&quot;,sans-serif;letter-spacing:

        -.1pt">The data is stored for 13 months from the IP address's visit or system

        call to the Service or Energy Save's system, with the exception of ongoing

        investigations. See also the Energy Save Cookie Policy for information on

        storage time for cookies.</span></p>

        </td>

        </tr>

        <tr style="height:16.25pt">

        <td width="617" colspan="3" valign="top" style="width:463.0pt;border:solid black 1.0pt;

        border-top:none;padding:0in 0in 0in 0in;height:16.25pt">

        <p class="TableParagraph" style="margin-top:0in;margin-right:55.45pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in;

        line-height:12.0pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">Source: </span></b><span lang="EN-GB" style="font-size:

        10.0pt;font-family:&quot;Arial&quot;,sans-serif">The data is collected from the data

        subject. </span></p>

        </td>

        </tr>

        <tr style="height:27.8pt">

        <td width="617" colspan="3" valign="top" style="width:463.0pt;border:solid black 1.0pt;

        border-top:none;padding:0in 0in 0in 0in;height:27.8pt">

        <p class="TableParagraph" style="margin-top:0in;margin-right:55.45pt;

        margin-bottom:0in;margin-left:5.5pt;margin-bottom:.0001pt;text-indent:0in;

        line-height:12.0pt"><b><span lang="EN-GB" style="font-size:10.0pt;font-family:

        &quot;Arial&quot;,sans-serif">Sharing:</span></b><span lang="EN-GB" style="font-size:

        10.0pt;font-family:&quot;Arial&quot;,sans-serif"> The data may be shared in individual

        cases with the police or the judiciary within the framework of a police

        investigation.</span></p>

        </td>

        </tr>

        </tbody></table>



        <h2><span lang="EN-GB">3.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB" style="color:black">How we collect your data,

        where it is processed and how we </span><span lang="EN-GB">share it</span></h2>



       <h3 style="margin-left:48.2pt"><span lang="EN-GB">3.1<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">Collection of personal data</span></h3>



        <p class="IndragNormal"><span lang="EN-GB">We collect data from you based on your

        or your organization's interaction with Energy Save and how you use our

        services. </span></p>



       <h3 style="margin-left:48.2pt"><span lang="EN-GB">3.2<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">With whom we may share your personal data?</span></h3>



        <p class="IndragNormal"><span lang="EN-GB">Energy Save does not sell your

        information to anyone else. We only pass on the information in accordance with

        what is described below. Energy Save always takes the utmost care when

        transferring your personal data.</span></p>



        <p class="IndragNormal" style="margin-left:1.0in;text-indent:-.25in"><span lang="EN-GB" style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><b><span lang="EN-GB">Legal Obligations.</span></b><span lang="EN-GB"> We will share user accounts and personal information when we

        believe it is necessary for Energy Save to comply with applicable law, to

        enforce our terms, or to protect rights, property, Energy Save, our users, or

        others. This includes sharing information with other companies and

        organizations for fraud prevention. This does not mean under any circumstances

        that Energy Save sells, rents or shares personal data of users for commercial

        purposes contrary to this privacy policy.</span></p>



        <p class="IndragNormal" style="margin-left:1.0in;text-indent:-.25in"><span lang="EN-GB" style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><b><span lang="EN-GB">Corporate Transactions.</span></b><span lang="EN-GB"> If all or parts of Energy Save's operations are sold or integrated

        with other operations, your personal data may be disclosed to our advisors, any

        buyer and their advisors, and passed on to the new owner of the business.</span></p>



        <p class="IndragNormal" style="margin-left:1.0in;text-indent:-.25in"><span lang="EN-GB" style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><b><span lang="EN-GB">Cookies and other similar technologies. </span></b><span lang="EN-GB">If you allow cookies and other similar technologies when using our

        Service, information about how you use the Service may be collected using

        cookies and other similar technologies. This information can be used for

        customer segmentation and for marketing and advertising in the Service. For the

        purposes stated above, this information is shared with our partners for social

        media, advertising and analysis to the extent necessary for the purpose.</span></p>



        <p class="IndragNormal" style="margin-left:1.0in;text-indent:-.25in"><span lang="EN-GB" style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><b><span lang="EN-GB">Processors. </span></b><span lang="EN-GB">In

        cases where it is necessary for us to be able to offer our services, we share

        your personal data with companies that are so-called processors for us. A

        personal data processor is a company that processes the information on our

        behalf and according to our instructions. We have processors who help us with:</span></p>



        <p class="IndragUndernumreringi"><span lang="EN-GB">(i)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">Marketing; and</span></p>



        <p class="IndragUndernumreringi"><span lang="EN-GB">(ii)<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">IT services regarding operation and security.</span></p>



        <p class="IndragNormal" style="margin-left:1.0in;text-indent:-.25in"><span lang="EN-GB" style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">When your personal data is shared with

        processors, it only happens for the purposes for which we have collected the

        information (e.g. to be able to fulfil our obligations according to the

        agreement or our terms and conditions). </span></p>



        <p class="IndragNormal" style="margin-left:1.0in;text-indent:-.25in"><span lang="EN-GB" style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><b><span lang="EN-GB">Companies that are independent controllers</span></b><span lang="EN-GB">. We also share your personal data with certain companies that are

        independent personal data controllers and where Energy Save therefore does not

        decide how the personal data is processed. Independent controllers can be

        government authorities towards whom we have obligations to disclose information

        according to law or in case of suspicion of crime.</span></p>



        <p class="IndragNormal" style="margin-left:1.0in"><span lang="EN-GB">When your

        personal data is shared with a company that is an independent controller, the

        company's privacy policy and personal data management apply.</span></p>



       <h3 style="margin-left:48.2pt"><a name="_Toc106015682"><span lang="EN-GB">3.3<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">Where do we process your personal data?</span></a></h3>



        <p class="IndragNormal"><span lang="EN-GB">We always aim for your personal data to

        be processed within the EU/EEA. However, in the case of technical support or

        similar, we may have to transfer the information outside the EU/EEA. </span></p>



        <p class="IndragNormal"><span lang="EN-GB">Regardless of the country in which your

        personal data is processed, we take appropriate technical and organizational

        measures to ensure that the level of protection is equivalent to ours. In cases

        where Energy Save uses, or will use, subcontractors or cooperates with partners

        established outside the EU/EEA area, Energy Save is responsible for ensuring

        that these partners can guarantee a level of security equivalent to that

        maintained within the EU.</span></p>



        <p class="IndragNormal"><span lang="EN-GB">In order to ensure adequate protection

        for your personal data during transfers to countries outside the EU/EEA when

        there is no decision on an adequate level of protection from the European

        Commission, Energy Save has, in applicable cases, entered into agreements with

        the respective recipients of personal data containing standard contractual

        clauses decided and approved by the European Commission, including but not

        limited to the standard clauses decided by Commission Decision 2021/914/EU or

        2010/87/EU.</span></p>



        <p class="IndragNormal"><span lang="EN-GB">In addition, Energy Save takes, when the

        level of protection in the recipient country cannot be considered equivalent to

        that within the EU/EEA, additional protection measures such as, for example,

        pseudonymisation, IP anonymisation and encryption. Additional information can

        be obtained via the contact details below.</span></p>



        <h2><span lang="EN-GB">4.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">Security</span></h2>



        <p class="IndragNormal"><span lang="EN-GB">We wish to ensure that your personal

        data is processed securely. To prevent unauthorized use or exposure of your

        personal data, we use appropriate and reasonable physical, technical and

        organizational security measures in relation to the amount and sensitivity of personal

        data, to ensure an appropriate level of security.</span></p>



        <p class="IndragNormal"><span lang="EN-GB">Energy Save also uses i.a. Transport

        Layer Security (TLS) which is a protocol for the secure transmission of data

        via the Internet (or other networks). As a customer, you need to check that TLS

        is not turned off in the settings of your browser. In order to prevent

        unauthorized access when processing your debit or credit card number, one-way

        encryption is also used.</span></p>



        <h2><span lang="EN-GB">5.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">Your rights</span></h2>



       <h3 style="margin-left:48.2pt"><span lang="EN-GB">5.1<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">Your rights as a data subject</span></h3>



        <p class="Undernumrering1"><span lang="EN-GB" style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><b><span lang="EN-GB">Right to record extracts (right to access)</span></b></p>



        <p class="IndragNormal"><span lang="EN-GB">You have the right to request

        information free of charge about which personal data we have registered about

        you and how this is used. If you want such information, you can contact us

        according to the contact details below. To protect your privacy and your personal

        data, we may require you to identify yourself in connection with your request.</span></p>



        <p class="IndragNormal"><span lang="EN-GB">Keep in mind that if we receive a

        request for access, we may ask for additional information to ensure efficient

        handling of your request and that the information is provided to the right

        person.</span></p>



        <p class="Undernumrering1"><span lang="EN-GB" style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><b><span lang="EN-GB">Right to rectification</span></b></p>



        <p class="IndragNormal"><span lang="EN-GB">Energy Save wants to ensure that your

        personal data is correct and up to date. Therefore, you can change your

        personal data in your user account at any time, you are also free to contact

        our customer service.</span></p>



        <p class="Undernumrering1"><span lang="EN-GB" style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><b><span lang="EN-GB">Right to erasure and right to portability</span></b></p>



        <p class="IndragNormal"><span lang="EN-GB">You can request at any time that we

        delete personal data concerning you or that we delete your user account in the

        Service. Energy Save will then delete the relevant personal data if they are no

        longer necessary for the purposes for which they were collected or otherwise

        processed. Energy Save will also do this when you withdraw your consent to the

        processing. In addition, you have the right to request that Energy Save

        transfer your personal data to another controller in an electronic format.</span></p>



        <p class="IndragNormal"><span lang="EN-GB">We may refuse your request if there are

        legal obligations that prevent us from deleting certain personal data. This

        can, for example, follow from accounting and tax legislation, banking and money

        laundering legislation, but also from consumer law legislation. In some cases,

        our processing may also be necessary for us to establish, assert or defend

        legal claims. Should we not comply with your request for deletion, we will

        instead, to the greatest extent possible, block the personal data from being

        used for purposes other than the purpose that prevents the requested deletion.</span></p>



        <p class="Undernumrering1"><span lang="EN-GB" style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><b><span lang="EN-GB">Right to withdraw consent</span></b></p>



        <p class="IndragNormal"><span lang="EN-GB">If processing is based on your consent,

        you have the right to withdraw your consent for future processing of your

        personal data at any time. If you withdraw your consent, we will stop the

        processing.</span></p>



        <p class="Undernumrering1"><span lang="EN-GB" style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><b><span lang="EN-GB">The right to object to certain types of

        processing and the right to limitation</span></b></p>



        <p class="IndragNormal"><span lang="EN-GB">You always have the right to object to

        the processing of your personal data based on a balancing of interests, i.e.

        processing supported by Energy Save's legitimate interest, and you also have

        the right to object to us processing your personal data to send you offers or

        information based on your history (direct marketing). If you object to such

        processing, we will cease personal data processing and will only process the

        data to establish, exercise or defend legal claims, to protect the rights of

        others or if you have given your consent.</span></p>



        <p class="IndragNormal"><span lang="EN-GB">Furthermore, you have the right to request

        that Energy Save limit its processing of the personal data in certain cases. If

        you believe that the personal data we process is not correct, you can request

        limited processing during the time we need to check whether the personal data

        is correct. If you have objected to processing based on a legitimate interest

        assessment, you can request limited processing for the time we need to check

        whether our legitimate interests outweigh your interests in having the data

        deleted.</span></p>



        <p class="Undernumrering1"><span lang="EN-GB" style="font-family:Symbol">·<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><b><span lang="EN-GB">Right to lodge a complaint with a supervisory

        authority</span></b></p>



        <p class="IndragNormal"><span lang="EN-GB">You can file a complaint with the

        Swedish Authority for Privacy Protection (IMY) if you believe that Energy

        Save's processing of your personal data is not in accordance with applicable

        law.</span></p>



       <h3 style="margin-left:48.2pt"><span lang="EN-GB">5.2<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">How do we handle social security numbers?</span></h3>



        <p class="IndragNormal"><span lang="EN-GB">We will only process your social security

        number when it is clearly justified with regard to the purpose, necessary for

        secure identification or if there is some other considerable reason. We always

        minimize the use of your social security number as much as possible by, in

        cases where it is sufficient, using your date of birth instead.</span></p>



       <h3 style="margin-left:48.2pt"><a name="_Ref106266984"><span lang="EN-GB">5.3<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">Cookies</span></a></h3>



        <p class="IndragNormal"><span lang="EN-GB">In order to be able to deliver our

        services with the highest possible quality, Energy Save uses cookies and

        similar technical means of storage in the Service.</span></p>



        <p class="IndragNormal"><span lang="EN-GB">When you use the Service for the first

        time, you will be asked if you agree to our use of cookies. You can remove

        cookies from your browser at any time and adjust your settings for the use of

        cookies. You can read about how to do this in our cookie policy which can be

        found at the link below.</span></p>



       <h3 style="margin-left:48.2pt;text-indent:0in"><span lang="SV"><a href="https://energysave.se/cookiepolicy/"><span lang="EN-GB" style="font-weight:

        normal">Here</span></a></span><span lang="EN-GB" style="font-weight:normal"> you

        can read Energy Save's policy for cookies and similar technical storage

        devices, which provides more information about which cookies and pixels are in

        the Service, who places them and what they are used for.</span></h3>



        <h2><span lang="EN-GB">6.<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">Changes to the privacy policy and contact details</span></h2>



       <h3 style="margin-left:48.2pt"><span lang="EN-GB">6.1<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">Changes</span></h3>



        <p class="IndragNormal"><span lang="EN-GB">Energy Save reserves the right to make

        changes to this privacy policy at any time to the extent that the changes are

        necessary. All changes are published in the Service. You should therefore

        review this policy periodically to ensure that you are happy with the changes.

        In the event of changes of material importance, we will, however, notify you

        via e-mail, if you have notified us of your email address, that changes have

        taken place.</span></p>



        <p class="IndragNormal"><span lang="EN-GB">If the changes concern such personal

        data processing that we carry out based on your consent, we will give you the

        opportunity to give your consent again.</span></p>



       <h3 style="margin-left:48.2pt"><span lang="EN-GB">6.2<span style="font:7.0pt &quot;Times New Roman&quot;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        </span></span><span lang="EN-GB">Contact details</span></h3>



        <p class="IndragNormal"><span lang="EN-GB">If you have any questions related to

        this privacy policy, if you suspect that there may have been a breach of it or

        if you wish to contact us regarding our processing of your personal data,

        please contact Energy Saves by sending a letter or email to the addresses as

        listed below.</span></p>



        <p class="IndragNormal"><span lang="EN-GB">Energy Save AB<br>

        Email: cloud@energysave.se</span></p>



        <p class="IndragNormal"><span lang="EN-GB">Postal address: Nitgatan 2, 441 38

        Alingsås</span></p>



        <p class="IndragNormal"><span lang="EN-GB">The privacy policy was last updated 21-08-2023</span></p>
    `
}