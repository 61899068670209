import { ConfigureStoreOptions, configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { setupListeners } from "@reduxjs/toolkit/query";
import commonSlice from "./reducers/commonSlice";
import authSlice from "./reducers/authSlice";
import installationSlice from "./reducers/installationSlice";
import { api } from "./services/api";
import { persistReducer, persistStore } from "redux-persist";
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist/es/constants';
import storage from "redux-persist/lib/storage";
import organizationSlice from "./reducers/organizationSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage: storage,
};

const persistedApi = persistReducer(persistConfig, api.reducer);

const middlewareList: any = [api.middleware];

const createStore = (
  options?: ConfigureStoreOptions["preloadedState"] | undefined
) =>
  configureStore({
    reducer: {
      commonSlice,
      authSlice,
      installationSlice,
      organizationSlice,
      [api.reducerPath]: persistedApi,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(middlewareList),
    devTools: process.env.NODE_ENV !== "production",
    // enhancers: getDefaultEnhancers => getDefaultEnhancers().concat([reactotron.createEnhancer!()]),
    ...options,
  });

export const store = createStore();
export const persistor = persistStore(store);
setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
