import {
  ActiveOrInactiveEnum,
  ActiveOrInactiveForDHWEnum,
  IInstallationBaseValue,
  IInstallationBaseValueWithMeasurement,
  OnOrOffEnum,
  ZoneOperationsKey,
} from "./installationsCommon";

export enum InstallationZoneOperationModeEnum {
  NONE = "NONE",
  HEATING = "HEATING",
  COOLING = "COOLING",
  BOTH = "BOTH",
}

export enum InstallationIndoorSensorStateEnum {
  NONE = "NONE",
  WIRED = "WIRED",
  WIRELESS_1 = "WIRELESS_1",
  WIRELESS_2 = "WIRELESS_2",
  UNDEFINED = "UNDEFINED",
}

export enum InstallationZoneOperationControllerEnum {
  CURVE = "CURVE",
  FIXED = "FIXED",
}

export enum InstallationOperationSpaceModeEnum {
  AUTO = "AUTO",
  HEATING = "HEATING",
  COOLING = "COOLING",
  OFF = "OFF",
}

export enum InstallationOperationHPStateEnum {
  ON = "ON",
  OFF = "OFF",
  UNDEFINED = "UNDEFINED",
}

export enum InstallationComissioningZoneCircuitTypeEnum {
  MIXING = "MIXING",
  DIRECT = "DIRECT",
}

export enum StageStateEnum {
  OFF = "OFF",
  ON_COMPRESSOR_OFF = "ON_COMPRESSOR_OFF",
  AVAILABLE = "AVAILABLE",
}

export enum AdditionalSourcePriorityForDhwEnum {
  INLINE = "INLINE",
  DHW_TANK = "DHW_TANK",
}

export enum AdditionalSourcePriorityForHeatingEnum {
  INLINE = "INLINE",
  BUFFER_TANK = "BUFFER_TANK",
}

export enum SeasonSwitchEnum {
  OUTDOOR_SENSOR = "SEASON",
  DIGITAL_SIGNAL = "DIGITAL_SIGNAL",
}

export interface IPointValues {
  outdoorTemp: IInstallationBaseValueWithMeasurement;
  flowTemp: IInstallationBaseValueWithMeasurement;
}

export interface ISetPointValues {
  outdoorTemp: number;
  flowTemp: number;
}

export type PointKey = "point1" | "point2" | "point3" | "point4" | "point5";

export interface IInstallationZoneOperation {
  heatingController: InstallationZoneOperationControllerEnum;
  coolingController: InstallationZoneOperationControllerEnum;
  preferredRoomTemp: IInstallationBaseValueWithMeasurement;
  preferredFixedFlowHeatingTemp: IInstallationBaseValueWithMeasurement;
  preferredFixedFlowCoolingTemp: IInstallationBaseValueWithMeasurement;
  heatingCurve: {
    [key in PointKey]?: IPointValues;
  };
  coolingCurve: {
    [key in PointKey]?: IPointValues;
  };
}

export interface IInstallationDHWAntoLegionella {
  state: ActiveOrInactiveEnum;
  temp: IInstallationBaseValueWithMeasurement;
  duration: IInstallationBaseValueWithMeasurement;
  startHour: IInstallationBaseValueWithMeasurement;
  endHour: IInstallationBaseValueWithMeasurement;
  interval: IInstallationBaseValueWithMeasurement;
  timeout: IInstallationBaseValueWithMeasurement;
}

export interface IInstallationDHWSettings {
  preferredTemp: IInstallationBaseValueWithMeasurement;
  // deltaDeviationTemp: IInstallationBaseValueWithMeasurement;
  antiLegionella: IInstallationDHWAntoLegionella;
}

export interface IInstallationsOperationSettings {
  spaceMode: InstallationOperationSpaceModeEnum;
  hpState: InstallationOperationHPStateEnum;
  dhwState: OnOrOffEnum;
}

export interface IInstallationUserSettingsVacationMode {
  state: ActiveOrInactiveEnum;
  startDate: string;
  endDate: string;
  reduceHeatingDeltaTemp: IInstallationBaseValue;
  reduceDHWDeltaTemp: IInstallationBaseValue;
}

export interface IInstallationUserSettings {
  paired: boolean;
  vacationMode: IInstallationUserSettingsVacationMode;
  heatingSeasonStartTemp: IInstallationBaseValueWithMeasurement;
  coolingSeasonStartTemp: IInstallationBaseValueWithMeasurement;
}

export type ZoneComissioningKey = "zone1" | "zone2" | "zone3" | "zone4";

export interface IZoneComissioningValues {
  circuitType: InstallationComissioningZoneCircuitTypeEnum;
  mode: InstallationZoneOperationModeEnum;
  indoorSensor: InstallationIndoorSensorStateEnum;
  minFlowHeatTemp: IInstallationBaseValueWithMeasurement;
  maxFlowHeatTemp: IInstallationBaseValueWithMeasurement;
  minFlowCoolTemp: IInstallationBaseValueWithMeasurement;
  maxFlowCoolTemp: IInstallationBaseValueWithMeasurement;
  minIndoorHeatTemp: IInstallationBaseValue;
  maxIndoorHeatTemp: IInstallationBaseValue;
  minIndoorCoolTemp: IInstallationBaseValue;
  maxIndoorCoolTemp: IInstallationBaseValue;
}

export interface IAdditionalDhwHeatingSource {
  state: ActiveOrInactiveForDHWEnum;
  inputPower: IInstallationBaseValue;
  // outdoorThresholdTemp: IInstallationBaseValue;
}

export interface IAdditionalInlineHeatingSource {
  state: ActiveOrInactiveEnum;
  inputPower: IInstallationBaseValueWithMeasurement;
  stage1?: StageStateEnum;
  stage2?: StageStateEnum;
  stage3?: StageStateEnum;
  outdoorThresholdTemp: IInstallationBaseValueWithMeasurement;
  // heatBalanceForDwh: IInstallationBaseValueWithMeasurement;
  heatBalanceForHeating: IInstallationBaseValueWithMeasurement;
}

export interface IBufferTank {
  state: ActiveOrInactiveEnum;
  inlineHeaterState: ActiveOrInactiveEnum;
  inputPower: IInstallationBaseValueWithMeasurement;
  // outdoorThresholdTemp: IInstallationBaseValueWithMeasurement;
  additionalSourcePriorityForHeating: AdditionalSourcePriorityForHeatingEnum 
}

export interface IPriorities {
  heating: IInstallationBaseValueWithMeasurement;
  // heatingDhw: IInstallationBaseValueWithMeasurement;
  cooling: IInstallationBaseValueWithMeasurement;
  // coolingDhw: IInstallationBaseValueWithMeasurement;
  // additionalSourcePriorityForHeating: AdditionalSourcePriorityForHeatingEnum;
  // additionalSourcePriorityForDhw: AdditionalSourcePriorityForDhwEnum;
  dhw: IInstallationBaseValueWithMeasurement;
}

export interface IInstallationInstallerSettingsComissioningDHW {
  active: ActiveOrInactiveForDHWEnum;
  deltaDeviationTemp: IInstallationBaseValueWithMeasurement;
  additionalSourcePriorityForDhw: AdditionalSourcePriorityForDhwEnum
  // dhwMinTemp: IInstallationBaseValue;
  // dhwMaxTemp: IInstallationBaseValue;
}

export interface IInstallationInstallerSettingsComissioning {
  zonesCount: IInstallationBaseValueWithMeasurement;
  zoneOperations: {
    [key in ZoneOperationsKey]?: IZoneComissioningValues;
  };
  additionalDhwHeatingSource: IAdditionalDhwHeatingSource;
  additionalInlineHeatingSource: IAdditionalInlineHeatingSource;
  bufferTank: IBufferTank;
  dhw: IInstallationInstallerSettingsComissioningDHW;
  shiftingPriorities: IPriorities;
}

export interface IWaterPumpP0Speed {
  heating: IInstallationBaseValueWithMeasurement;
  cooling: IInstallationBaseValueWithMeasurement;
  dhw: IInstallationBaseValueWithMeasurement;
}

export interface IInstallationInstallerSettingsOthers {
  seasonSwitch: SeasonSwitchEnum;
  waterPumpP0Speed: IWaterPumpP0Speed;
}

export interface IInstallationInstallerSettings {
  commissioning: IInstallationInstallerSettingsComissioning;
  others: IInstallationInstallerSettingsOthers;
}

//SET HELPERS MODELS
export interface ISetOperationZonesParams {
  heatingController: InstallationZoneOperationControllerEnum;
  coolingController: InstallationZoneOperationControllerEnum;
  preferredRoomTemp: number;
  preferredFixedFlowHeatingTemp: number;
  preferredFixedFlowCoolingTemp: number;
  heatingCurve: {
    [key in PointKey]?: ISetPointValues;
  };
  coolingCurve: {
    [key in PointKey]?: ISetPointValues;
  };
}

export interface ISetAntilegionellaSettings {
  state: ActiveOrInactiveForDHWEnum;
  temp: number;
  duration: number;
  startHour: number;
  endHour: number;
  interval: number;
}

export interface ISetDhwSettings {
  // enabled: boolean;
  preferredTemp: number;
  // deltaDeviationTemp: number;
  antiLegionella: ISetAntilegionellaSettings;
}

export interface ISetOperationSettings {
  spaceMode: InstallationOperationSpaceModeEnum;
  hpState: InstallationOperationHPStateEnum;
  dhwState: OnOrOffEnum;
}

export interface ISetVacationMode {
  // state: ActiveOrInactiveEnum;
  startDate: string; //"2024-08-04" Schedule start date in user timezone. Timezone will be picked up from device datetime.
  endDate: string; //"2024-08-04" Schedule end date in user timezone. Timezone will be picked up from device datetime.
  reduceHeatingDeltaTemp: number;
  reduceDHWDeltaTemp: number;
}

export interface ISetUserSettings {
  vacationMode: ISetVacationMode;
  heatingSeasonStartTemp: number;
  coolingSeasonStartTemp: number;
  // language: string; //ISO-639 language code
  // dateTime: string; //($date-time) example: 2007-12-03T10:15:30+01:00 ISO-8601 date-time with offset
}

export interface ISetInstallerSettignsComissioningZoneOperation {
  circuitType: InstallationComissioningZoneCircuitTypeEnum;
  mode: InstallationZoneOperationModeEnum;
  indoorSensor: InstallationIndoorSensorStateEnum;
  minFlowHeatTemp: number;
  maxFlowHeatTemp: number;
  minFlowCoolTemp: number;
  maxFlowCoolTemp: number;
}

export interface ISetInstallerSettignsComissioningAdditionalDHWHeatingSource {
  state: ActiveOrInactiveForDHWEnum;
  inputPower: number;
  // outdoorThresholdTemp: number;
}

export interface ISetInstallerSettignsComissioningAdditionalInlineHeatingSource {
  state: ActiveOrInactiveEnum;
  inputPower: number;
  stage1?: StageStateEnum;
  stage2?: StageStateEnum;
  stage3?: StageStateEnum;
  // additionalSourcePriorityForDhw: AdditionalSourcePriorityForDhwEnum;
  // additionalSourcePriorityForHeating: AdditionalSourcePriorityForHeatingEnum;
  outdoorThresholdTemp: number;
  // heatBalanceThresholdForDwh: number;
  heatBalanceThresholdForHeating: number;
}

export interface ISetInstallerSettignsComissioningBufferTank {
  state: ActiveOrInactiveEnum;
  inlineHeaterState: ActiveOrInactiveEnum;
  inputPower: number;
  additionalSourcePriorityForHeating: AdditionalSourcePriorityForHeatingEnum;
}

export interface ISetInstallerSettignsComissioningPriorities {
  // heatingMinTemp: number;
  // heatingDhwMinTemp: number;
  // coolingMinTemp: number;
  // coolingDhwMinTemp: number;
  heating: number;
  cooling: number;
  dhw: number;
}

export interface ISetInstallerSettingsComissioningDHW {
  active: ActiveOrInactiveForDHWEnum;
  deltaDeviationTemp: number;
  additionalSourcePriorityForDhw: AdditionalSourcePriorityForDhwEnum;
}

export interface ISetComissioningIstallerSettings {
  zonesCount?: number;
  zoneOperations: {
    [key in ZoneOperationsKey]?: ISetInstallerSettignsComissioningZoneOperation;
  };
  additionalDhwHeatingSource: ISetInstallerSettignsComissioningAdditionalDHWHeatingSource;
  additionalInlineHeatingSource: ISetInstallerSettignsComissioningAdditionalInlineHeatingSource;
  bufferTank: ISetInstallerSettignsComissioningBufferTank;
  dhw: ISetInstallerSettingsComissioningDHW;
  shiftingPriorities: ISetInstallerSettignsComissioningPriorities;
}

export interface ISetOthersInstallerSettings {
  seasonSwitch: SeasonSwitchEnum;
  waterPumpP0Speed: {
    heating: number;
    cooling: number;
    dhw: number;
  };
}

export interface ISetInstallerSettings {
  commissioning: ISetComissioningIstallerSettings;
  others: ISetOthersInstallerSettings;
}

//main
//GET
export interface IInstallationSettingsByIdResponse {
  lastUpdatedAt: string;
  // zonesCount: IInstallationBaseValue;
  zoneOperations: {
    [key in ZoneOperationsKey]?: IInstallationZoneOperation;
  };
  dhw: IInstallationDHWSettings;
  operation: IInstallationsOperationSettings;
  userSettings: IInstallationUserSettings;
  installerSettings: IInstallationInstallerSettings;
}

//SET
export interface ISetInstallationSettings {
  // zonesCount?: number;
  zoneOperations?: {
    [key in ZoneOperationsKey]?: ISetOperationZonesParams;
  };
  dhw: ISetDhwSettings;
  operation: ISetOperationSettings;
  userSettings: ISetUserSettings;
  installerSettings: ISetInstallerSettings;
}
