import { Box, Typography } from "@mui/material";
import React from "react";

export interface ISectionItem {
  title: string;
  value: string;
}

export interface ISectionData {
  title?: string;
  items?: Array<ISectionItem>;
}

export const InfoSectionComponent = ({ title, items }: ISectionData) => {
  return (
    <Box mb={"20px"}>
      <Typography
        sx={{
          color: "#212121",
          fontWeight: "bold",
          lineHeight: "24px",
          letterSpacing: "0.15px",
          padding: "6px 0",
          borderBottom: "2px solid #95C11F",
        }}
      >
        {title}
      </Typography>
      {items?.map(
        (item, index) =>
          item?.value && (
            <Box
              key={item?.value + index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "6px 0",
                borderBottom: "1px solid #95C11F",
              }}
            >
              <Typography fontSize={14} lineHeight={"20px"} mr={"5px"}>
                {item.title}
              </Typography>
              <Typography fontSize={14} lineHeight={"20px"} fontWeight={"bold"}>
                {item.value}
              </Typography>
            </Box>
          )
      )}
    </Box>
  );
};
