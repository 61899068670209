import React, { useEffect, useState } from "react";
import useTheme from "../../../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import {
  installationApi,
  useGetInstallationSettingsQuery,
  useSetInstallationSettingsMutation,
} from "../../../../../../../../store/services/installation";
import {
  AdditionalSourcePriorityForHeatingEnum,
  IBufferTank,
  IInstallationSettingsByIdResponse,
  ISetInstallationSettings,
  ISetInstallerSettignsComissioningBufferTank,
} from "../../../../../../../../store/services/models/installations/installationSettings";
import TabWrapper from "../../../../TabWrapper";
import MainTitle from "../../../../components/MainTitle/MainTitle";
import { Box, Divider, Typography } from "@mui/material";
import SettingsSwitcher from "../../../../components/SettingsSwitcher/SettingsSwitcher";
import SecondaryTitle from "../../../../components/SecondaryTitle/SecondaryTitle";
import NumberInput from "../../../../components/NumberInput/NumberInput";
import {
  baseFindDifferences,
  cleanValuesForRequest,
} from "../../../../helpers/findDifferences";
import { ActiveOrInactiveEnum } from "../../../../../../../../store/services/models/installations/installationsCommon";
import UnsavedChangesModal from "../../../../components/UnsavedChangesModal/UnsavedChangesModal";
import ConfirmChangesModal from "../../../../components/ConfirmChangesModal/ConfirmChangesModal";
import { Tool02 } from "untitledui-js-base";
import DefaultChange from "../../../../components/ConfirmChangesModal/DefaultChage";
import NotificationModal from "../../../../components/NotificationModal/NotificationModal";
import { useAppDispatch } from "../../../../../../../../store";
import NoParametersBlock from "../../../../NoParametersBlock";

export default function BufferTank() {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const [isPoolingIntervalEnabled, setIsPoolingIntervalEnabled] =
    useState<boolean>(true);

  const {
    data: settings,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchSettings,
  } = useGetInstallationSettingsQuery(
    {
      uuid: id!,
      isRealData: params.get("dummyData") === "false",
    },
    {
      pollingInterval: isPoolingIntervalEnabled ? 60000 : 0,
    }
  );

  const [setInstallationSettings] = useSetInstallationSettingsMutation();
  const [settingsParameters, setSettingsParameters] = useState<
    IInstallationSettingsByIdResponse | undefined
  >(settings);

  const [bufferTankSettingsData, setBufferTankSettingsData] =
    useState<IBufferTank>();
  const [initialBufferTankSettingsData, setInitialBufferTankSettingsData] =
    useState<IBufferTank>();

  const [initialSettings, setInitialSettings] =
    useState<IInstallationSettingsByIdResponse | null>(null);

  const [isSomethingChanged, setIsSomethingChanged] = useState(false);
  const [isConfirmChangesModalOpen, setIsConfirmChangesModalOpen] =
    useState<boolean>(false);
  const [differencesToShow, setDifferencesToShow] = useState<any>();
  const [isNotificationModalOpen, setIsNotificationModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (settings) {
      setSettingsParameters(settings);
    }
  }, [settings]);

  useEffect(() => {
    if (settingsParameters) {
      setInitialSettings(JSON.parse(JSON.stringify(settingsParameters)));
      setBufferTankSettingsData(
        JSON.parse(
          JSON.stringify(
            settingsParameters.installerSettings?.commissioning?.bufferTank ||
              {}
          )
        )
      );
      setInitialBufferTankSettingsData(
        JSON.parse(
          JSON.stringify(
            settingsParameters.installerSettings?.commissioning?.bufferTank ||
              {}
          )
        )
      );
    }
  }, [settingsParameters]);

  const checkForChanges = () => {
    if (
      JSON.stringify(bufferTankSettingsData) !==
      JSON.stringify(initialBufferTankSettingsData)
    ) {
      setIsSomethingChanged(true);
      setIsPoolingIntervalEnabled(false);
    } else {
      setIsSomethingChanged(false);
      setIsPoolingIntervalEnabled(true);
    }
  };

  useEffect(() => {
    checkForChanges();
  }, [bufferTankSettingsData]);

  const updateSettings = (field: string, value: any) => {
    const updatedSettings = { ...bufferTankSettingsData };
    (updatedSettings as any)[field] = value;
    setBufferTankSettingsData(updatedSettings as IBufferTank);
  };

  const onDiscardChanges = () => {
    setBufferTankSettingsData(
      JSON.parse(JSON.stringify(initialBufferTankSettingsData))
    );
    setIsPoolingIntervalEnabled(true);
  };

  const onSaveChanges = () => {
    const differences = baseFindDifferences(
      initialBufferTankSettingsData!,
      bufferTankSettingsData!
    );
    setDifferencesToShow(differences);

    setIsConfirmChangesModalOpen(true);
  };

  const createRequest = () => {
    let newSettings: Partial<ISetInstallerSettignsComissioningBufferTank> =
      cleanValuesForRequest(differencesToShow);

    return {
      installerSettings: {
        commissioning: {
          bufferTank: newSettings,
        },
      },
    };
  };

  const onConfirmSettigsChanges = () => {
    const updatedSettings = { ...initialSettings };
    updatedSettings.installerSettings = {
      ...(updatedSettings.installerSettings as any),
      commissioning: {
        ...(updatedSettings.installerSettings?.commissioning as any),
        bufferTank: bufferTankSettingsData,
      },
    };
    setIsConfirmChangesModalOpen(false);
    const requestBody = createRequest();
    setInstallationSettings({
      ...(requestBody as ISetInstallationSettings),
      uuid: id!,
    })
      .unwrap()
      .then(() => {
        dispatch(
          installationApi.util.updateQueryData(
            "getInstallationSettings",
            { uuid: id!, isRealData: params.get("dummyData") === "false" },
            (draftSettings) => {
              Object.assign(draftSettings, updatedSettings);
            }
          )
        );
        setIsNotificationModalOpen(true);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsPoolingIntervalEnabled(true);
      });
  };

  if (!settings || !settings?.installerSettings?.commissioning?.bufferTank) {
    return <NoParametersBlock />;
  }

  return (
    <TabWrapper
      onDiscardPress={onDiscardChanges}
      onSaveChangesPress={onSaveChanges}
      isDiscardDisabled={!isSomethingChanged}
      isSaveDisabled={!isSomethingChanged}
    >
      <MainTitle
        title={t(
          "pages.installationSettings.installerSettings.bufferTank.mainTitle"
        )}
      />
      <Box sx={{ mt: theme.spacing.spacingXl }} />

      {bufferTankSettingsData?.state && (
        <>
          <SettingsSwitcher
            label={`${t(
              "pages.installationSettings.installerSettings.bufferTank.fieldsLabels.bufferTank"
            )}`}
            state={
              bufferTankSettingsData?.state === ActiveOrInactiveEnum.ACTIVE
                ? true
                : false
            }
            changeState={(val) =>
              updateSettings(
                "state",
                val
                  ? ActiveOrInactiveEnum.ACTIVE
                  : ActiveOrInactiveEnum.INACTIVE
              )
            }
            statusOn={`${t("actions.activate")}`}
            statusOff={`${t("actions.deactivate")}`}
          />
          <Divider
            sx={{
              width: "100%",
              borderColor: theme.colors.colorsBorderBorderSecondary,
            }}
          />
        </>
      )}
      {bufferTankSettingsData?.state === ActiveOrInactiveEnum.ACTIVE && (
        <>
          {bufferTankSettingsData?.inputPower?.value && (
            <SecondaryTitle
              title={`${t(
                "pages.installationSettings.installerSettings.bufferTank.secondaryTitle"
              )}`}
              variant="md"
            />
          )}
          {bufferTankSettingsData?.inlineHeaterState && (
            <SettingsSwitcher
              label={`${t(
                "pages.installationSettings.installerSettings.bufferTank.fieldsLabels.additionalBuffweTankHeationgSource"
              )}`}
              state={
                bufferTankSettingsData?.inlineHeaterState ===
                ActiveOrInactiveEnum.ACTIVE
                  ? true
                  : false
              }
              statusOn={`${t("actions.activate")}`}
              statusOff={`${t("actions.deactivate")}`}
              changeState={(val) => {
                updateSettings(
                  "inlineHeaterState",
                  val
                    ? ActiveOrInactiveEnum.ACTIVE
                    : ActiveOrInactiveEnum.INACTIVE
                );
              }}
            />
          )}
          {bufferTankSettingsData?.inlineHeaterState ===
            ActiveOrInactiveEnum.ACTIVE && (
            <>
              {bufferTankSettingsData?.inputPower?.value && (
                <NumberInput
                  initialValue={bufferTankSettingsData?.inputPower.value}
                  changeValue={(val) =>
                    updateSettings("inputPower", {
                      ...bufferTankSettingsData?.inputPower,
                      value: val,
                    })
                  }
                  decimalPlaces={1}
                  unit={bufferTankSettingsData?.inputPower?.unit}
                  step={0.5}
                  hint={`${t(
                    "pages.installationSettings.installerSettings.dhw.fieldsHints.inputPower"
                  )}`}
                  label={`${t(
                    "pages.installationSettings.installerSettings.dhw.fieldsLabels.inputPower"
                  )}`}
                  min={bufferTankSettingsData?.inputPower?.min}
                  max={bufferTankSettingsData?.inputPower?.max}
                  inputWidth="105px"
                />
              )}
              {bufferTankSettingsData?.additionalSourcePriorityForHeating && (
                <SettingsSwitcher
                  label={`${t(
                    "pages.installationSettings.installerSettings.bufferTank.fieldsLabels.highPriorityForHeating"
                  )}`}
                  hint={`${t(
                    "pages.installationSettings.installerSettings.bufferTank.fieldsHints.highPriorityForHeating"
                  )}`}
                  statusOff={`${t("statuses.makeItPriority")}`}
                  statusOn={`${t("statuses.makeItPriority")}`}
                  state={
                    bufferTankSettingsData?.additionalSourcePriorityForHeating ===
                    AdditionalSourcePriorityForHeatingEnum.BUFFER_TANK
                      ? true
                      : false
                  }
                  disabled={
                    settingsParameters?.installerSettings.commissioning
                      ?.additionalInlineHeatingSource?.state ===
                    ActiveOrInactiveEnum.INACTIVE
                  }
                  changeState={(val) => {
                    updateSettings(
                      "additionalSourcePriorityForHeating",
                      val
                        ? AdditionalSourcePriorityForHeatingEnum.BUFFER_TANK
                        : AdditionalSourcePriorityForHeatingEnum.INLINE
                    );
                  }}
                />
              )}
            </>
          )}
        </>
      )}
      <UnsavedChangesModal
        tabName={t("pages.installationSettings.tabsNames.installerBufferTank")}
        condition={isSomethingChanged}
      />
      {isConfirmChangesModalOpen && (
        <ConfirmChangesModal
          isOpen={isConfirmChangesModalOpen}
          onCancel={() => setIsConfirmChangesModalOpen(false)}
          onOk={() => onConfirmSettigsChanges()}
          page={`${t(
            "pages.installationSettings.tabsNames.installerBufferTank"
          )}`}
          block={`${t(
            "pages.installationSettings.tabsNames.installerSettings"
          )}`}
          showHeatPumpNote={
            differencesToShow.additionalSourcePriorityForHeating ||
            differencesToShow.state
          }
          icon={
            <Tool02
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        >
          <>
            {differencesToShow.state && (
              <Typography
                sx={{
                  ...theme.typography[".text-md-semibold"],
                  color: theme.colors.colorsTextTextPrimary,
                  mb: theme.spacing.spacingXl,
                }}
              >
                {t(
                  "pages.installationSettings.installerSettings.bufferTank.mainTitle"
                )}
              </Typography>
            )}
            {differencesToShow?.state && (
              <DefaultChange
                changedValue={differencesToShow?.state.newValue}
                previousValue={differencesToShow?.state.previousValue}
                title={`${t(
                  "pages.installationSettings.installerSettings.bufferTank.mainTitle"
                )}`}
              />
            )}
            {differencesToShow.state && (
              <Divider
                sx={{
                  width: "100%",
                  borderColor: theme.colors.colorsBorderBorderSecondary,
                }}
              />
            )}
            {(differencesToShow.inputPower ||
              differencesToShow.inlineHeaterState ||
              differencesToShow.additionalSourcePriorityForHeating) && (
              <Typography
                sx={{
                  ...theme.typography[".text-md-semibold"],
                  color: theme.colors.colorsTextTextPrimary,
                  mb: theme.spacing.spacingXl,
                  mt: theme.spacing.spacingXl,
                }}
              >
                {t(
                  "pages.installationSettings.installerSettings.bufferTank.secondaryTitle"
                )}
              </Typography>
            )}
            {differencesToShow?.inlineHeaterState && (
              <DefaultChange
                changedValue={
                  differencesToShow?.inlineHeaterState.newValue ===
                  ActiveOrInactiveEnum.ACTIVE
                    ? t("actions.activate")
                    : t("actions.deactivate")
                }
                previousValue={
                  differencesToShow?.inlineHeaterState.previousValue ===
                  ActiveOrInactiveEnum.ACTIVE
                    ? t("actions.activate")
                    : t("actions.deactivate")
                }
                title={`${t(
                  "pages.installationSettings.installerSettings.additionalInlineHeatingSource.fieldsLabels.additionalInlineHeatingSource"
                )}`}
              />
            )}
            {differencesToShow?.inputPower && (
              <DefaultChange
                changedValue={
                  differencesToShow?.inputPower.value.newValue + "kW"
                }
                previousValue={
                  differencesToShow?.inputPower.value.previousValue + "kW"
                }
                title={`${t(
                  "pages.installationSettings.installerSettings.dhw.fieldsLabels.inputPower"
                )}`}
              />
            )}
            {differencesToShow.additionalSourcePriorityForHeating && (
              <DefaultChange
                changedValue={
                  differencesToShow?.additionalSourcePriorityForHeating
                    .newValue === AdditionalSourcePriorityForHeatingEnum.INLINE
                    ? `${t(
                        "pages.installationSettings.installerSettings.dhw.notPrioritized"
                      )}`
                    : `${t(
                        "pages.installationSettings.installerSettings.dhw.prioritized"
                      )}`
                }
                previousValue={
                  differencesToShow?.additionalSourcePriorityForHeating
                    .previousValue ===
                  AdditionalSourcePriorityForHeatingEnum.INLINE
                    ? `${t(
                        "pages.installationSettings.installerSettings.dhw.notPrioritized"
                      )}`
                    : `${t(
                        "pages.installationSettings.installerSettings.dhw.prioritized"
                      )}`
                }
                title={`${t(
                  "pages.installationSettings.installerSettings.bufferTank.fieldsLabels.highPriorityForHeating"
                )}`}
              />
            )}
          </>
        </ConfirmChangesModal>
      )}
      {isNotificationModalOpen && (
        <NotificationModal
          isOpen={isNotificationModalOpen}
          onOkPress={() => setIsNotificationModalOpen(false)}
          icon={
            <Tool02
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          }
        />
      )}
    </TabWrapper>
  );
}
