import { api } from "./api";
import { AcceptExistOrganizationInvitationRequestParams, AcceptOrganizationInvitationRequestParams, FindOrganizationRequestParams, FindOrganizationResponse, GetOrganizationRequestParams, OrganizationInvitationInfoRequestParams, OrganizationInvitationInfoResponse, OrganizationsMePartnershipsResponse, RelatedCompanyResponse, SendOrganizationInvitationRequestParams } from "./models/organizations/organizations";


export const organizationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPartnerShips: build.query<OrganizationsMePartnershipsResponse, GetOrganizationRequestParams>({
      query: () => ({
        url: 'organizations/me/partnerships'
      }),
    }),
    getOrganization: build.query<RelatedCompanyResponse, GetOrganizationRequestParams>({
      query: ({ uuid }) => ({
        url: `organizations/${uuid}`
      }),
    }),
    findOrganization: build.mutation<FindOrganizationResponse, FindOrganizationRequestParams>({
      query: ({ uuid, email }) => ({
        url: `organizations/${uuid}/child?email=${email}`,
        method: 'GET',
      }),
    }),
    sendOrganizationInvitation: build.mutation<void, SendOrganizationInvitationRequestParams>({
      query: ({ uuid, ...props }) => ({
        url: `organizations/${uuid}/child/invitations`,
        method: 'POST',
        body: props
      }),
    }),
    acceptOrganizationInvitation: build.mutation<void, AcceptOrganizationInvitationRequestParams | AcceptExistOrganizationInvitationRequestParams>({
      query: (props) => ({
        url: `organizations/child`,
        method: 'POST',
        body: props
      }),
    }),
    getOrganizationInvitationInfo: build.query<OrganizationInvitationInfoResponse, OrganizationInvitationInfoRequestParams>({
      query: ({ token }) => ({
        url: `organizations/child/invitations/${token}`
      }),
    })
  }),
});

export const {
  useGetPartnerShipsQuery,
  useGetOrganizationQuery,
  useFindOrganizationMutation,
  useSendOrganizationInvitationMutation,
  useAcceptOrganizationInvitationMutation,
  useGetOrganizationInvitationInfoQuery,
  endpoints: {
    getPartnerShips,
    getOrganization,
    findOrganization,
    sendOrganizationInvitation,
    acceptOrganizationInvitation,
    getOrganizationInvitationInfo,
  },
} = organizationsApi;
