import { Box } from "@mui/material";
import React, { useLayoutEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { LoggameraAPI } from "../../../api/LoggameraAPI";
import Loader from "../../../components/Loader/Loader";

export const LoggameraIFramePage = () => {
  const navigate = useNavigate();
  const logOutSrc = "https://portal.loggamera.se/Login/Logout";
  const loggameraApi = new LoggameraAPI(navigate);
  const [searchParams, _setSearchParams] = useSearchParams();
  const [iframeSrc, setIframeSrc] = useState<string>(logOutSrc);

  const [installationUuid, setInstallationUuid] = useState<string | null>();

  useLayoutEffect(() => {
    const installationUuid = searchParams.get("installationUuid");
    setInstallationUuid(installationUuid);
  }, [searchParams]);

  useLayoutEffect(() => {
    setIframeSrc(logOutSrc);
    if (installationUuid) {
      loggameraApi
        .getNewPortalURLForInstallation(installationUuid)
        .then((res) => {
          setTimeout(() => {
            setIframeSrc(res.data.portalLink);
          }, 100);
        })
        .catch((err) => {})
        .finally(() => {});
    }
  }, [installationUuid]);

  useLayoutEffect(() => {
    const header = document.querySelector("header");
    if (header) {
      header.remove();
    }
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        setIframeSrc(logOutSrc);
        const installationId = searchParams.get("installationUuid");
        loggameraApi
          .getNewPortalURLForInstallation(installationId!)
          .then((res) => {
            setTimeout(() => {
              setIframeSrc(res.data.portalLink);
            }, 100);
          })
          .catch((err) => {})
          .finally(() => {});
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return useMemo(
    () => (
      <>
        {iframeSrc !== logOutSrc && (
          <Box
            sx={{
              position: "fixed",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <iframe width={"100%"} height={"100%"} src={iframeSrc}></iframe>
          </Box>
        )}
        {iframeSrc === logOutSrc && (
          <Box
            sx={{
              position: "fixed",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <iframe width={"1px"} height={"1px"} src={logOutSrc}></iframe>
            <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(255,255,255,0.8)",
              }}
            >
              <Loader />
            </Box>
          </Box>
        )}
      </>
    ),
    [iframeSrc]
  );
};
