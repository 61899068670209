import { Box, Typography } from "@mui/material";
import React from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";

export interface ISimpleDisplayValueProps {
  label: string;
  value: string;
}

export default function SimpleDisplayValue({
  label,
  value,
}: ISimpleDisplayValueProps) {
  const theme = useTheme();
  return (
    <Box sx={{ mb: theme.spacing.spacingXl }}>
      <Typography
        sx={{
          ...theme.typography[".text-sm-medium"],
          color: theme.colors.colorsTextTextSecondary,
          mb: theme.spacing.spacingSm,
        }}
      >
        {label}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "fit-content",
          p: `${theme.spacing.spacingMd} 0`,
        }}
      >
        <Typography
          sx={{
            ...theme.typography[".text-md-medium"],
            color: theme.colors.colorsTextTextPrimary,
          }}
        >
          {value || ""}
        </Typography>
      </Box>
    </Box>
  );
}
