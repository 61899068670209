import { IInstallationBaseValue, IInstallationBaseValueWithMeasurement } from "../../../../../store/services/models/installations/installationsCommon";
import {
  IInstallationDHWSettings,
  IInstallationSettingsByIdResponse,
  IInstallationZoneOperation,
  InstallationComissioningZoneCircuitTypeEnum,
  InstallationIndoorSensorStateEnum,
  InstallationZoneOperationModeEnum,
  IZoneComissioningValues,
} from "../../../../../store/services/models/installations/installationSettings";
import { IInstallationZoneOperationAsArrayItem } from "../Tabs/ZonesTab/Zones";

export const isEmpty = (obj: Object | undefined) => {
  if (obj) {
    return Object.keys(obj).length === 0;
  } else {
    return true;
  }
};

export const cleanValuesForRequest = (obj: any) => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (obj.hasOwnProperty("value")) {
    return obj.value.newValue;
  }

  if (obj.hasOwnProperty("newValue") && obj.hasOwnProperty("previousValue")) {
    return obj.newValue;
  }

  const newObj: any = Array.isArray(obj) ? [] : {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key] = cleanValuesForRequest(obj[key]);
    }
  }

  return newObj;
};

export const findZonesSettingsDifferences = (
  initial: IInstallationZoneOperationAsArrayItem[],
  current: IInstallationZoneOperationAsArrayItem[]
) => {
  const differences = current.map((zone, index) => {
    const initialZone = initial[index];
    const zoneDifferences: any = {};

    const compareFields = (
      initialField: any,
      currentField: any,
      fieldName: string
    ) => {
      if (
        typeof initialField === "object" &&
        initialField !== null &&
        !Array.isArray(initialField)
      ) {
        Object.keys(initialField).forEach((subField) => {
          compareFields(
            initialField[subField],
            currentField[subField],
            `${fieldName}.${subField}`
          );
        });
      } else if (
        JSON.stringify(initialField) !== JSON.stringify(currentField)
      ) {
        const fieldPath = fieldName.split(".");
        const fieldKey = fieldPath.pop();
        let nestedDifferences = zoneDifferences;
        fieldPath.forEach((key) => {
          if (!nestedDifferences[key]) {
            nestedDifferences[key] = {};
          }
          nestedDifferences = nestedDifferences[key];
        });

        nestedDifferences[fieldKey!] = {
          previousValue: initialField,
          newValue: currentField,
        };
      }
    };

    Object.keys(zone).forEach((key) => {
      compareFields((initialZone as any)[key], (zone as any)[key], key);
    });

    return {
      zonePropertyName: zone.zonePropertyName,
      zoneNumber: zone.zoneNumber,
      differences: zoneDifferences,
    };
  });

  return differences.filter((zone) => Object.keys(zone.differences).length > 0);
};

export const mapZonesArrayToResponse = (
  zonesArray: IInstallationZoneOperationAsArrayItem[],
  initialSettings: IInstallationSettingsByIdResponse
): IInstallationSettingsByIdResponse => {
  const newZoneOperations: { [key: string]: IInstallationZoneOperation } = {};
  const newComissioningZonesOperations: {
    [key: string]: IZoneComissioningValues;
  } = {};

  zonesArray.forEach((zone) => {
    const zoneKey =
      zone.zonePropertyName as keyof typeof initialSettings.zoneOperations;

    if (zoneKey) {
      const {
        zonePropertyName,
        zoneNumber,
        minFlowHeatTemp,
        maxFlowHeatTemp,
        minFlowCoolTemp,
        maxFlowCoolTemp,
        mode,
        indoorSensor,
        minIndoorHeatTemp,
        maxIndoorHeatTemp,
        minIndoorCoolTemp,
        maxIndoorCoolTemp,
        circuitType,
        isZoneActivated,
        ...restZone
      } = zone;
      newZoneOperations[zoneKey] = {
        ...initialSettings.zoneOperations[zoneKey],
        ...restZone,
      };

      newComissioningZonesOperations[zoneKey] = {
        ...initialSettings.installerSettings.commissioning.zoneOperations[
          zoneKey
        ],
        minFlowHeatTemp: minFlowHeatTemp as IInstallationBaseValueWithMeasurement,
        maxFlowHeatTemp: maxFlowHeatTemp as IInstallationBaseValueWithMeasurement,
        minFlowCoolTemp: minFlowCoolTemp as IInstallationBaseValueWithMeasurement,
        maxFlowCoolTemp: maxFlowCoolTemp as IInstallationBaseValueWithMeasurement,
        minIndoorHeatTemp: minIndoorHeatTemp as IInstallationBaseValue,
        maxIndoorHeatTemp: maxIndoorHeatTemp as IInstallationBaseValue,
        minIndoorCoolTemp: minIndoorCoolTemp as IInstallationBaseValue,
        maxIndoorCoolTemp: maxIndoorCoolTemp as IInstallationBaseValue,
        circuitType: circuitType as InstallationComissioningZoneCircuitTypeEnum,
        mode: mode as InstallationZoneOperationModeEnum,
        indoorSensor: indoorSensor as InstallationIndoorSensorStateEnum
      };
    }
  });

  return {
    ...initialSettings,
    zoneOperations: newZoneOperations,
    installerSettings: {
      ...initialSettings.installerSettings,
      commissioning: {
        ...initialSettings.installerSettings.commissioning,
        zoneOperations: newComissioningZonesOperations,
      },
    },
  };
};

export const baseFindDifferences = (initial: any, current: any) => {
  const zoneDifferences: any = {};

  const compareFields = (
    initialField: any,
    currentField: any,
    fieldName: string
  ) => {
    if (
      typeof initialField === "object" &&
      initialField !== null &&
      !Array.isArray(initialField)
    ) {
      Object.keys(initialField).forEach((subField) => {
        compareFields(
          initialField[subField],
          currentField[subField],
          `${fieldName}.${subField}`
        );
      });
    } else if (JSON.stringify(initialField) !== JSON.stringify(currentField)) {
      const fieldPath = fieldName.split(".");
      const fieldKey = fieldPath.pop();
      let nestedDifferences = zoneDifferences;
      fieldPath.forEach((key) => {
        if (!nestedDifferences[key]) {
          nestedDifferences[key] = {};
        }
        nestedDifferences = nestedDifferences[key];
      });

      nestedDifferences[fieldKey!] = {
        previousValue: initialField,
        newValue: currentField,
      };
    }
  };

  Object.keys(current).forEach((key) => {
    compareFields((initial as any)[key], (current as any)[key], key);
  });

  return zoneDifferences;
};
