import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import SimpleSideMenu, {
  ISideMenuItem,
} from "../../../../components/SimpleSideMenu/SimpleSideMenu";
import { useTranslation } from "react-i18next";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router";
import { HomeSmile, Tool02, UserEdit } from "untitledui-js-base";

import { ReactComponent as DHWIcon } from "../RealTimeData/SectionIcons/DHWIcon.svg";
import { ReactComponent as SystemOperationIcon } from "../RealTimeData/SectionIcons/SystemOperationIcon.svg";
import useTheme from "../../../../theme/hooks/useTheme";
import PrivateRoute from "../../../../components/PrivateRoute/PrivateRoute";
import Zones from "./Tabs/ZonesTab/Zones";
import DomesticHotWater from "./Tabs/DomesticHotWaterTab/DomesticHotWater";
import Operation from "./Tabs/OperationTab/Operation";
import UserSettings from "./Tabs/UserSettingsTab/UserSettings";
import InstallerSettings from "./Tabs/InstallerSettingsTab/InstallerSettings";

export default function SettingsPage() {
  const { t } = useTranslation("cloud_ui");
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams();

  const [menuItems, setMenuItems] = useState<Array<ISideMenuItem>>([]);
  useEffect(() => {
    if (location.pathname.endsWith("/settings")) {
      navigate(
        `/portal/residential-installations/${id}/settings/installation-settings-zones${location.search}`
      );
    }
    if (id) {
      setMenuItems([
        {
          label: t("pages.installationSettings.sideMenu.zones"),
          href: `/portal/residential-installations/${id}/settings/installation-settings-zones${location.search}`,
          icon: (
            <HomeSmile size="24" style={{ color: "inherit" }} strokeWidth={2} />
          ),
        },
        {
          label: t("pages.installationSettings.sideMenu.DHW"),
          href: `/portal/residential-installations/${id}/settings/installation-settings-dhw${location.search}`,
          icon: <DHWIcon style={{ color: "inherit" }} strokeWidth={2} />,
        },
        {
          label: t("pages.installationSettings.sideMenu.operation"),
          href: `/portal/residential-installations/${id}/settings/installation-settings-operation${location.search}`,
          icon: (
            <SystemOperationIcon style={{ color: "inherit" }} strokeWidth={2} />
          ),
        },
        {
          label: t("pages.installationSettings.sideMenu.userSettings"),
          href: `/portal/residential-installations/${id}/settings/installation-settings-user-settings${location.search}`,
          icon: (
            <UserEdit size="24" style={{ color: "inherit" }} strokeWidth={2} />
          ),
        },
        {
          label: t("pages.installationSettings.sideMenu.installerSettings"),
          href: `/portal/residential-installations/${id}/settings/installation-settings-installer-settings${location.search}`,
          icon: (
            <Tool02 size="24" style={{ color: "inherit" }} strokeWidth={2} />
          ),
        },
      ]);
    }
  }, [id]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          height: "calc((var(--vh, 1vh) * 100) - 216px)",
          overflowY: "hidden",
          mr: `-${theme.spacing.spacing2xl}`,
        }}
      >
        <Box
          sx={{
            width: "280px",
            height: "100%",
            borderRight: `1px solid ${theme.colors.colorsBorderBorderSecondary}`,
          }}
        >
          <SimpleSideMenu menuItems={menuItems} />
        </Box>
        <Box sx={{ width: "100%" }}>
          <Routes>
            <Route
              path="/installation-settings-zones/*"
              element={
                <PrivateRoute
                  element={<Zones />}
                  rolesToCheck={["INSTALLATION_READ"]}
                />
              }
            />
            <Route
              path="/installation-settings-dhw"
              element={
                <PrivateRoute
                  element={<DomesticHotWater />}
                  rolesToCheck={["INSTALLATION_READ"]}
                />
              }
            />
            <Route
              path="/installation-settings-operation"
              element={
                <PrivateRoute
                  element={<Operation />}
                  rolesToCheck={["INSTALLATION_READ"]}
                />
              }
            />
            <Route
              path="/installation-settings-user-settings"
              element={
                <PrivateRoute
                  element={<UserSettings />}
                  rolesToCheck={["INSTALLATION_READ"]}
                />
              }
            />
            <Route
              path="/installation-settings-installer-settings/*"
              element={
                <PrivateRoute
                  element={<InstallerSettings />}
                  rolesToCheck={["INSTALLATION_READ"]}
                />
              }
            />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
}
