import React, { ReactNode } from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Save01 } from "untitledui-js-base";
import { Button, ButtonType } from "../../../../../../components/Button/Button";
import { PointKey } from "../../../../../../store/services/models/installations/installationSettings";

export enum ChangesTypes {
  DEFAULT,
  POINTS,
  CONTROLLER,
}

export interface IBasePointsValues {
  originalTemperature: number;
  changedTemperature: number;
}

export interface IChangesData {
  title: string;
  previousValue: string;
  changedValue: string;
}

export interface IPointValues {
  outdoor: IBasePointsValues;
  flow: IBasePointsValues;
  keyTitle: string;
}

export type IPointDiffsData = {
  [key in PointKey]?: IPointValues;
};

export interface IPointsChangesData {
  title: string;
  diffs: IPointDiffsData;
}

export interface IChange {
  type: ChangesTypes;
  data: IChangesData | IPointsChangesData;
}

export interface IConfirmChangesModalProps {
  changes?: Array<IChange>;
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
  children?: ReactNode;
  block: string;
  page: string;
  showHeatPumpNote?: boolean;
  icon?: any;
}

export default function ConfirmChangesModal({
  isOpen,
  changes,
  onCancel,
  onOk,
  children,
  block,
  page,
  showHeatPumpNote,
  icon,
}: IConfirmChangesModalProps) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");

  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      sx={{ "& .MuiPaper-root": { maxWidth: "400px" } }}
    >
      <DialogTitle
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginBottom: theme.spacing.spacingXl,
          padding: `${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl} 0`,
        }}
      >
        <Box
          sx={{
            width: "48px",
            height: "48px",
            borderRadius: "50%",
            backgroundColor:
              theme.colors.componentColorsUtilityErrorUtilityError50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: theme.spacing.spacingXl,
          }}
        >
          {icon ? (
            icon
          ) : (
            <Save01
              size="24"
              style={{
                color: theme.colors.colorsForegroundFgWarningPrimary,
              }}
              strokeWidth={2}
            />
          )}
        </Box>
        <Typography sx={{ ...theme.typography[".text-lg-semibold"] }}>
          {t("pages.installationSettings.confirmChangesModal.title", {
            page: block,
          })}
        </Typography>
        <Typography fontSize={14} color={theme.colors.colorsTextTextTertiary}>
          {t("pages.installationSettings.confirmChangesModal.description", {
            page: page,
          })}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          maxHeight: "300px",
          mb: theme.spacing.spacingXl,
          "&::-webkit-scrollbar": {
            width: "4px",
            height: "4px",
            backgroundColor: theme.colors.colorsBackgroundBgSecondaryAlt,
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            backgroundColor: "rgba(0,0,0,0.2)",
            minHeight: "24px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: theme.colors.colorsTextTextQuaternary,
          },
        }}
      >
        {children}
        {/* {changes.map((change, index) => {
          if (change.type === ChangesTypes.DEFAULT) {
            const { title, changedValue, previousValue } =
              change.data as IChangesData;
            return (
              <DefaultChange
                key={`${index}_change`}
                changedValue={changedValue}
                previousValue={previousValue}
                title={title}
              />
            );
          }
          if (change.type === ChangesTypes.POINTS) {
            const { title, diffs } = change.data as IPointsChangesData;
            return <PointsChange title={title} diffs={diffs} />;
          }

          if (change.type === ChangesTypes.CONTROLLER) {
            const { title, changedValue, previousValue } =
              change.data as IChangesData;
            return (
              <DefaultChange
                key={`${index}_change`}
                changedValue={changedValue}
                previousValue={previousValue}
                title={title}
              />
            );
          }
        })} */}
      </DialogContent>
      {showHeatPumpNote && (
        <Typography
          fontSize={14}
          sx={{
            padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
            display: "flex",
          }}
          color={theme.colors.colorsTextTextTertiary}
        >
          <Typography
            fontSize={14}
            color={"black"}
            sx={{
              ...theme.typography[".text-sm-medium"],
              mr: theme.spacing.spacingSm,
            }}
          >
            {t("pages.installationSettings.confirmChangesModal.note")}
          </Typography>
          {t(
            `pages.installationSettings.confirmChangesModal.${
              showHeatPumpNote ? "heatPumpNote" : "description2"
            }`
          )}
        </Typography>
      )}
      <DialogActions
        sx={{
          padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
          justifyContent: "space-between",
        }}
      >
        <Button
          label={`${t("actions.cancel")}`}
          onClick={onCancel}
          buttonType={ButtonType.SecondaryGray}
          sx={{
            width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
          }}
        />
        <Button
          label={`${t(
            "pages.installationSettings.confirmChangesModal.confirmButtonText"
          )}`}
          onClick={onOk}
          sx={{
            ml: "0 !important",
            width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
