import { Box, Typography } from "@mui/material";
import React from "react";
import { ArrowRight } from "untitledui-js-base";
import useTheme from "../../../../../../theme/hooks/useTheme";

export interface IDefaultChangeProps {
  title: string;
  previousValue: string;
  changedValue: string;
  isVertical?: boolean;
}

export default function DefaultChange({
  changedValue,
  previousValue,
  title,
  isVertical = false,
}: IDefaultChangeProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        mb: theme.spacing.spacingXl,
        display: "flex",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <Typography
        sx={{
          ...theme.typography[".text-sm-medium"],
          color: theme.colors.colorsTextTextTertiary,
          width: "50%",
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "flex-end",
          flexDirection: isVertical ? "column" : "row",
          width: "50%",
        }}
      >
        <Typography
          sx={{
            ...theme.typography[".text-sm-medium"],
            color: theme.colors.colorsTextTextPrimary,
          }}
        >
          {previousValue}
        </Typography>
        <ArrowRight
          size="14"
          style={{
            color: theme.colors.colorsTextTextPrimary,
            margin: `3px ${theme.spacing.spacingMd}`,
            minWidth: "14px",
            alignSelf: isVertical ? "center" : "initial",
          }}
          strokeWidth={2}
        />
        <Typography
          sx={{
            ...theme.typography[".text-sm-medium"],
            color: theme.colors.colorsTextTextPrimary,
          }}
        >
          {changedValue}
        </Typography>
      </Box>
    </Box>
  );
}
