const typography = {
  ".display-2-xl-regular": {
    fontSize: "72px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "90px",
    letterSpacing: "-0.02em",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-2-xl-medium": {
    fontSize: "72px",
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "90px",
    letterSpacing: "-0.02em",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-2-xl-semibold": {
    fontSize: "72px",
    fontFamily: "Inter",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "90px",
    letterSpacing: "-0.02em",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-2-xl-bold": {
    fontSize: "72px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "90px",
    letterSpacing: "-0.02em",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-xl-regular": {
    fontSize: "60px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "72px",
    letterSpacing: "-0.02em",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-xl-medium": {
    fontSize: "60px",
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "72px",
    letterSpacing: "-0.02em",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-xl-semibold": {
    fontSize: "60px",
    fontFamily: "Inter",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "72px",
    letterSpacing: "-0.02em",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-xl-bold": {
    fontSize: "60px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "72px",
    letterSpacing: "-0.02em",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-lg-regular": {
    fontSize: "48px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "60px",
    letterSpacing: "-0.02em",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-lg-medium": {
    fontSize: "48px",
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "60px",
    letterSpacing: "-0.02em",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-lg-semibold": {
    fontSize: "48px",
    fontFamily: "Inter",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "60px",
    letterSpacing: "-0.02em",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-lg-bold": {
    fontSize: "48px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "60px",
    letterSpacing: "-0.02em",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-md-regular": {
    fontSize: "36px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "44px",
    letterSpacing: "-0.02em",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-md-medium": {
    fontSize: "36px",
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "44px",
    letterSpacing: "-0.02em",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-md-semibold": {
    fontSize: "36px",
    fontFamily: "Inter",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "44px",
    letterSpacing: "-0.02em",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-md-bold": {
    fontSize: "36px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "44px",
    letterSpacing: "-0.02em",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-sm-regular": {
    fontSize: "30px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "38px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-sm-medium": {
    fontSize: "30px",
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "38px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-sm-semibold": {
    fontSize: "30px",
    fontFamily: "Inter",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "38px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-sm-bold": {
    fontSize: "30px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "38px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-sm-medium-italic": {
    fontSize: "30px",
    fontFamily: "Inter",
    fontStyle: "italic",
    fontWeight: 500,
    lineHeight: "44px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-xs-regular": {
    fontSize: "24px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "32px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-xs-medium": {
    fontSize: "24px",
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "32px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-xs-semibold": {
    fontSize: "24px",
    fontFamily: "Inter",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "32px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-xs-bold": {
    fontSize: "24px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "32px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".display-xs-medium-italic": {
    fontSize: "24px",
    fontFamily: "Inter",
    fontStyle: "italic",
    fontWeight: 500,
    lineHeight: "36px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-xl-regular": {
    fontSize: "20px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "30px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-xl-medium": {
    fontSize: "20px",
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "30px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-xl-semibold": {
    fontSize: "20px",
    fontFamily: "Inter",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "30px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-xl-bold": {
    fontSize: "20px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "30px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-xl-regular-italic": {
    fontSize: "20px",
    fontFamily: "Inter",
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "30px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-xl-medium-italic": {
    fontSize: "20px",
    fontFamily: "Inter",
    fontStyle: "italic",
    fontWeight: 500,
    lineHeight: "30px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-xl-semibold-italic": {
    fontSize: "20px",
    fontFamily: "Inter",
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "30px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-xl-bold-italic": {
    fontSize: "20px",
    fontFamily: "Inter",
    fontStyle: "italic",
    fontWeight: 700,
    lineHeight: "30px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-xl-regular-underlined": {
    fontSize: "20px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "30px",
    textDecoration: "underline",
    textTransform: "none",
  },
  ".text-lg-regular": {
    fontSize: "18px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "28px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-lg-medium": {
    fontSize: "18px",
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "28px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-lg-semibold": {
    fontSize: "18px",
    fontFamily: "Inter",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "28px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-lg-bold": {
    fontSize: "18px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "28px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-lg-regular-italic": {
    fontSize: "18px",
    fontFamily: "Inter",
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "28px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-lg-medium-italic": {
    fontSize: "18px",
    fontFamily: "Inter",
    fontStyle: "italic",
    fontWeight: 500,
    lineHeight: "28px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-lg-semibold-italic": {
    fontSize: "18px",
    fontFamily: "Inter",
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "28px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-lg-bold-italic": {
    fontSize: "18px",
    fontFamily: "Inter",
    fontStyle: "italic",
    fontWeight: 700,
    lineHeight: "28px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-lg-regular-underlined": {
    fontSize: "18px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "28px",
    textDecoration: "underline",
    textTransform: "none",
  },
  ".text-lg-medium-underlined": {
    fontSize: "18px",
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "28px",
    textDecoration: "underline",
    textTransform: "none",
  },
  ".text-lg-semibold-underlined": {
    fontSize: "18px",
    fontFamily: "Inter",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "28px",
    textDecoration: "underline",
    textTransform: "none",
  },
  ".text-md-regular": {
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "24px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-md-medium": {
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "24px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-md-semibold": {
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "24px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-md-bold": {
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "24px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-md-regular-italic": {
    fontSize: "16px",
    fontFamily: "Inter",
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "24px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-md-medium-italic": {
    fontSize: "16px",
    fontFamily: "Inter",
    fontStyle: "italic",
    fontWeight: 500,
    lineHeight: "24px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-md-semibold-italic": {
    fontSize: "16px",
    fontFamily: "Inter",
    fontStyle: "italic",
    fontWeight: 400,
    lineHeight: "24px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-md-bold-italic": {
    fontSize: "16px",
    fontFamily: "Inter",
    fontStyle: "italic",
    fontWeight: 700,
    lineHeight: "24px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-md-regular-underlined": {
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "24px",
    textDecoration: "underline",
    textTransform: "none",
  },
  ".text-md-medium-underlined": {
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "24px",
    textDecoration: "underline",
    textTransform: "none",
  },
  ".text-md-semibold-underlined": {
    fontSize: "16px",
    fontFamily: "Inter",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "24px",
    textDecoration: "underline",
    textTransform: "none",
  },
  ".text-sm-regular": {
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "20px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-sm-medium": {
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "20px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-sm-semibold": {
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "20px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-sm-bold": {
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "20px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-sm-regular-underlined": {
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "20px",
    textDecoration: "underline",
    textTransform: "none",
  },
  ".text-sm-medium-underlined": {
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "20px",
    textDecoration: "underline",
    textTransform: "none",
  },
  ".text-sm-semibold-underlined": {
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "20px",
    textDecoration: "underline",
    textTransform: "none",
  },
  ".text-sm-heading": {
    fontSize: "14px",
    fontFamily: "Inter",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "20px",
    letterSpacing: "0.1em",
    textDecoration: "none",
    textTransform: "uppercase",
  },
  ".text-xs-regular": {
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "18px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-xs-medium": {
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "18px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-xs-semibold": {
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 600,
    fontStyle: "normal",
    lineHeight: "18px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".text-xs-bold": {
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 700,
    fontStyle: "normal",
    lineHeight: "18px",
    textDecoration: "none",
    textTransform: "none",
  },
  ".focus-rings-focus-brand": {
    boxShadow: "0px 0px 0px 4px rgba(149,193,31,0.240)",
  },
  ".focus-rings-focus-brand-shadow-xs": {
    boxShadow:
      "0px 1px 2px rgba(0,0,0,0.050), 0px 0px 0px 4px rgba(149,193,31,0.240)",
  },
  ".focus-rings-focus-brand-shadow-sm": {
    boxShadow:
      "0px 1px 3px rgba(16,24,40,0.100), 0px 1px 2px rgba(16,24,40,0.060), 0px 0px 0px 4px rgba(149,193,31,0.240)",
  },
  ".focus-rings-focus-gray": {
    boxShadow: "0px 0px 0px 4px rgba(152,162,179,0.140)",
  },
  ".focus-rings-focus-gray-shadow-xs": {
    boxShadow:
      "0px 1px 2px rgba(16,24,40,0.050), 0px 0px 0px 4px rgba(152,162,179,0.140)",
  },
  ".focus-rings-focus-gray-shadow-sm": {
    boxShadow:
      "0px 1px 3px rgba(16,24,40,0.100), 0px 1px 2px rgba(16,24,40,0.060), 0px 0px 0px 4px rgba(152,162,179,0.140)",
  },
  ".focus-rings-focus-gray-secondary": {
    boxShadow: "0px 0px 0px 4px rgba(152,162,179,0.200)",
  },
  ".focus-rings-focus-error": {
    boxShadow: "0px 0px 0px 4px rgba(240,68,56,0.240)",
  },
  ".focus-rings-focus-error-shadow-xs": {
    boxShadow:
      "0px 1px 2px rgba(16,24,40,0.050), 0px 0px 0px 4px rgba(240,68,56,0.240)",
  },
  ".shadows-shadow-xs": { boxShadow: "0px 1px 2px rgba(16,24,40,0.050)" },
  ".shadows-shadow-sm": {
    boxShadow:
      "0px 1px 3px rgba(16,24,40,0.100), 0px 1px 2px rgba(16,24,40,0.060)",
  },
  ".shadows-shadow-md": {
    boxShadow:
      "0px 4px 8px -2px rgba(16,24,40,0.100), 0px 2px 4px -2px rgba(16,24,40,0.060)",
  },
  ".shadows-shadow-lg": {
    boxShadow:
      "0px 12px 16px -4px rgba(16,24,40,0.080), 0px 4px 6px -2px rgba(16,24,40,0.030)",
  },
  ".shadows-shadow-xl": {
    boxShadow:
      "0px 20px 24px -4px rgba(16,24,40,0.080), 0px 8px 8px -4px rgba(16,24,40,0.030)",
  },
  ".shadows-shadow-2-xl": {
    boxShadow: "0px 24px 48px -12px rgba(16,24,40,0.180)",
  },
  ".shadows-shadow-3-xl": {
    boxShadow: "0px 32px 64px -12px rgba(16,24,40,0.140)",
  },
  ".shadows-portfolio-mockup-shadows-shadow-main-centre-md": {
    boxShadow: "0px 75px 150px rgba(52,64,84,0.140)",
  },
  ".shadows-portfolio-mockup-shadows-shadow-main-centre-lg": {
    boxShadow: "0px 100px 200px rgba(52,64,84,0.180)",
  },
  ".shadows-portfolio-mockup-shadows-shadow-overlay-right-lg": {
    boxShadow: "-40px 40px 72px rgba(52,64,84,0.120)",
  },
  ".shadows-portfolio-mockup-shadows-shadow-overlay-left-lg": {
    boxShadow: "100px 100px 150px rgba(52,64,84,0.120)",
  },
  ".shadows-portfolio-mockup-shadows-shadow-grid-md": {
    boxShadow: "32px 32px 64px rgba(52,64,84,0.080)",
  },
  ".backdrop-blurs-backdrop-blur-sm": { backdropFilter: "blur(8px)" },
  ".backdrop-blurs-backdrop-blur-md": { backdropFilter: "blur(16px)" },
  ".backdrop-blurs-backdrop-blur-lg": { backdropFilter: "blur(24px)" },
  ".backdrop-blurs-backdrop-blur-xl": { backdropFilter: "blur(40px)" },
};

export default typography;

