import React from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";

export interface IOrganizationSectionProps {
  // title: string;
  name: string;
  phone: string;
  address: string;
  showArrow?: boolean;
  paddingLeft?: string;
}

export default function OrganizationSection({
  address,
  name,
  phone,
  showArrow,
  paddingLeft,
}: // title,
IOrganizationSectionProps) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui", {
    keyPrefix:
      "pages.infoDetailsPage.installationDetails.responsibleOrganizations",
  });
  return (
    <Box sx={{ width: "100%", pl: paddingLeft }}>
      <Typography
        sx={{
          ...theme.typography[".text-lg-semibold"],
          color: theme.colors.colorsTextTextPrimary,
          mb: theme.spacing.spacingXl,
        }}
      >
        {/* {showArrow && "↳"} {title} */}
        {showArrow && "↳"} {name}
      </Typography>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: theme.spacing.spacingXl,
        }}
      >
        <Typography
          sx={{
            ...theme.typography[".text-md-medium"],
            color: theme.colors.colorsTextTextTertiary,
          }}
        >
          {t("orgName")}
        </Typography>
        <Typography
          sx={{
            ...theme.typography[".text-md-semibold"],
            color: theme.colors.colorsTextTextPrimary,
          }}
        >
          {name}
        </Typography>
      </Box> */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: theme.spacing.spacingXl,
        }}
      >
        <Typography
          sx={{
            ...theme.typography[".text-md-medium"],
            color: theme.colors.colorsTextTextTertiary,
          }}
        >
          {t("orgPhone")}
        </Typography>
        <Typography
          sx={{
            ...theme.typography[".text-md-semibold"],
            color: theme.colors.colorsTextTextPrimary,
          }}
        >
          {phone}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: theme.spacing.spacingXl,
        }}
      >
        <Typography
          sx={{
            ...theme.typography[".text-md-medium"],
            color: theme.colors.colorsTextTextTertiary,
          }}
        >
          {t("orgAddress")}
        </Typography>
        <Typography
          sx={{
            ...theme.typography[".text-md-semibold"],
            color: theme.colors.colorsTextTextPrimary,
          }}
        >
          {address}
        </Typography>
      </Box>
    </Box>
  );
}
