import React from "react";
import ReactRouterPrompt from "react-router-prompt";
import ConfirmationModal from "../../../../../../components/ConfirmationModal/confirmationModal";
import { AlertCircle } from "untitledui-js-base";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";

export default function UnsavedChangesModal({
  condition,
  tabName,
  discardChanges,
  title,
  description,
}: {
  condition: boolean;
  tabName: string;
  discardChanges?: () => void;
  title?: string;
  description?: string;
}) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  return (
    <ReactRouterPrompt when={condition}>
      {({ isActive, onConfirm, onCancel }) => (
        <ConfirmationModal
          title={
            title
              ? title
              : `${t("pages.installationSettings.confirmationModal.title")}`
          }
          text={
            description
              ? description
              : `${t(
                  "pages.installationSettings.confirmationModal.description",
                  { tabName }
                )}`
          }
          icon={
            <AlertCircle
              size="24"
              style={{
                color: theme.colors.colorsBackgroundBgErrorSolid,
              }}
              strokeWidth={2}
            />
          }
          isOpen={isActive}
          onCancel={onCancel}
          onOk={() => {
            if (discardChanges) {
              discardChanges();
            }
            onConfirm();
          }}
          confirmButtonStyles={{}}
          confirmButtonText={t(
            "pages.installationSettings.confirmationModal.confirmButton"
          )}
        />
      )}
    </ReactRouterPrompt>
  );
}
