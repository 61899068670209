const radius = {
  radiusNone: "0rem",
  radiusXxs: "0.125rem",
  radiusXs: "0.25rem",
  radiusSm: "0.375rem",
  radiusMd: "0.5rem",
  radiusLg: "0.625rem",
  radiusXl: "0.75rem",
  radius2xl: "1rem",
  radius3xl: "1.25rem",
  radius4xl: "1.5rem",
  radiusFull: "624.9375rem",
};


export default radius