import { Box, SxProps, Typography } from "@mui/material";
import React from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";

export interface IMainTitleProps {
  text: string;
  sx?: SxProps;
}

export default function MainTitle({ text, sx }: IMainTitleProps) {
  const theme = useTheme();
  return (
    <Box sx={{ ...sx, width: "100%" }}>
      <Typography
        sx={{
          ...theme.typography[".text-xl-semibold"],
          color: theme.colors.colorsTextTextPrimary,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
}
