import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { SwitchHorizontal01 } from "untitledui-js-base";
import useTheme from "../../../../../../../theme/hooks/useTheme";
import NumberInput from "../../../components/NumberInput/NumberInput";
import { IInstallationBaseValueWithMeasurement } from "../../../../../../../store/services/models/installations/installationsCommon";

export interface IFixedFlowPointSection {
  flowTemperature: IInstallationBaseValueWithMeasurement | undefined;
  setFlowTemperature: (val: number) => void;
  updateTypeToCurve: () => void;
  maxFlowTemp: number;
  minFlowTemp: number;
}

export default function FixedFlowPointSection({
  flowTemperature,
  setFlowTemperature,
  updateTypeToCurve,
  minFlowTemp,
  maxFlowTemp,
}: IFixedFlowPointSection) {
  const { t } = useTranslation("cloud_ui");
  const theme = useTheme();

  const [flowTemperatureValue, setFlowTemperatureValue] = useState<
    IInstallationBaseValueWithMeasurement | undefined
  >(flowTemperature || undefined);

  const [minTemp, setMinTemp] = useState<number>(flowTemperature?.min || -50);
  const [maxTemp, setMaxTemp] = useState<number>(flowTemperature?.max || 50);

  useEffect(() => {
    setFlowTemperatureValue(flowTemperature || undefined);
  }, [flowTemperature]);

  // useEffect(() => {
  //   setMinTemp(minFlowTemp);
  //   if (minFlowTemp > flowTemperatureValue) {
  //     setFlowTemperature(minFlowTemp);
  //   }
  // }, [flowTemperature?.min]);

  // useEffect(() => {
  //   setMaxTemp(maxFlowTemp);
  //   if (maxFlowTemp < flowTemperatureValue) {
  //     setFlowTemperature(maxFlowTemp);
  //   }
  // }, [maxFlowTemp]);

  return (
    <>
      {flowTemperatureValue && (
        <NumberInput
          initialValue={flowTemperatureValue.value}
          changeValue={(val) => {
            setFlowTemperature(val);
          }}
          min={flowTemperatureValue.min}
          max={flowTemperatureValue.max}
          decimalPlaces={2}
          step={0.5}
          hint={`${t(
            "pages.installationSettings.zones.fieldHints.flowTemperature",
            { from: flowTemperatureValue.min, to: flowTemperatureValue.max }
          )}`}
        />
      )}
      {/* <Box
        component={"button"}
        onClick={updateTypeToCurve}
        sx={{
          mb: theme.spacing.spacingXl,
          border: "none",
          height: "36px",
          width: "fit-content",
          padding: `${theme.spacing.spacingMd} ${theme.spacing.spacingLg}`,
          color:
            theme.colors
              .componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFg,
          backgroundColor: "initial",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          "&:hover": {
            color:
              theme.colors
                .componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFgHover,
          },
        }}
      >
        <SwitchHorizontal01 size="20" strokeWidth={2} />
        <Typography
          sx={{
            ...theme.typography[".text-sm-semibold"],
            ml: theme.spacing.spacingXs,
          }}
        >
          {t("pages.installationSettings.zones.buttons.useCurve")}
        </Typography>
      </Box> */}
    </>
  );
}
