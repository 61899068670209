import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Button,
  Typography,
  useMediaQuery,
  Theme,
} from "@mui/material";
import useTheme from "../../theme/hooks/useTheme";
import {
  ChevronLeftDouble,
  ChevronRightDouble,
  Minus,
} from "untitledui-js-base";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { IMainNavigationGroup } from "../MainNavigation/MainNavigation";

interface ISidebarProps {
  menuGroups: Array<IMainNavigationGroup>;
  isOpen?: boolean;
  onToggleSidebar: (isOpen: boolean) => void;
}

const Sidebar = ({ menuGroups, isOpen, onToggleSidebar }: ISidebarProps) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const theme = useTheme();

  const [open, setOpen] = useState<boolean>();

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  useEffect(() => {
    if (isOpen !== undefined) {
      setOpen(!!isOpen as any);
    }
  }, [isOpen]);

  useEffect(() => {
    const savedState = localStorage.getItem("sidebarOpen");
    if (savedState !== null) {
      setOpen((savedState === "true") as any);
      onToggleSidebar((savedState === "true") as any);
    } else {
      setOpen(true);
      onToggleSidebar(true);
    }
  }, []);

  useEffect(() => {
    if (open !== undefined) {
      localStorage.setItem("sidebarOpen", (open as any).toString());
    }
  }, [open]);

  const toggleDrawer = (open: boolean) => () => {
    setOpen(open);
    onToggleSidebar(open);
  };

  const currentPathUpdate = (path: string) => {
    return path.split("/").slice(0, 3).join("/");
  };

  return (
    <Box
      sx={{
        display: currentPath.includes("loggamera-frame") ? "none" : "block",
      }}
    >
      <Drawer
        variant={isMobile ? "temporary" : "permanent"}
        anchor={isMobile ? "top" : "left"}
        open={open}
        sx={{
          "& .MuiDrawer-paper": {
            paddingTop: `calc(64px + ${theme.spacing.spacingXl})`,
            paddingBottom: theme.spacing.spacing3xl,
            width: isMobile ? "100%" : open ? 240 : 60,
            height: "100%",
            transition: "width 0.3s",
            overflowX: "hidden",
            boxShadow: "0 4px 12px rgba(16, 24, 40, 0.15)",
          },
        }}
      >
        <Box
          sx={{
            width: isMobile ? "100%" : open ? 240 : 60,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <List sx={{ padding: `0 ${theme.spacing.spacingMd}` }}>
            {menuGroups.map((item, groupIndex) => {
              return (
                item.groupItems.length && (
                  <React.Fragment key={`${groupIndex}_menuGroup`}>
                    {item.groupName && (
                      <Typography
                        sx={{
                          ...theme.typography[".text-sm-semibold"],
                          color: theme.colors.colorsTextTextQuaternary,
                          padding: `${theme.spacing.spacingMd} ${theme.spacing.spacingLg}`,
                          textTransform: "uppercase",
                          height: `calc(24px + (${theme.spacing.spacingMd} * 2))`,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {open ? (
                          item.groupName
                        ) : (
                          <Minus
                            size="16"
                            style={{
                              color: theme.colors.colorsBorderBorderTertiary,
                            }}
                            strokeWidth={2}
                          />
                        )}
                      </Typography>
                    )}
                    {item.groupItems.map((menuItem, index) => (
                      <ListItem
                        button
                        key={`${index}_menuItem`}
                        component={menuItem.isExternalLink ? "a" : RouterLink}
                        href={
                          menuItem.isExternalLink ? menuItem.href : undefined
                        }
                        to={menuItem.isExternalLink ? undefined : menuItem.href}
                        target={menuItem.isExternalLink ? "_blank" : undefined}
                        sx={{
                          ...theme.typography[".text-md-semibold"],
                          padding: theme.spacing.spacingMd,
                          color: theme.colors.colorsTextTextSecondary,
                          height: "40px",
                          ".MuiListItemIcon-root": {
                            color: currentPath.includes(menuItem.href)
                              ? // currentPathUpdate(currentPath) === menuItem.href
                                theme.colors.colorsTextTextBrandTertiaryAlt
                              : "inherit",
                          },
                          ".MuiTypography-root": {
                            color: currentPath.includes(menuItem.href)
                              ? // currentPathUpdate(currentPath) === menuItem.href
                                theme.colors.colorsTextTextBrandTertiaryAlt
                              : "inherit",
                          },
                          backgroundColor: currentPath.includes(menuItem.href)
                            ? // currentPathUpdate(currentPath) === menuItem.href
                              theme.colors.colorsBackgroundBgActive
                            : "inital",
                        }}
                        rel={
                          menuItem.isExternalLink
                            ? "noopener noreferrer"
                            : undefined
                        }
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: "fit-content",
                            mr: theme.spacing.spacingLg,
                          }}
                        >
                          {menuItem.icon}
                        </ListItemIcon>
                        {open && (
                          <ListItemText
                            sx={{
                              ...theme.typography[".text-md-semibold"],
                              color: theme.colors.colorsTextTextSecondary,
                              fontWeight: "600 !important",
                            }}
                            primary={menuItem.label}
                            primaryTypographyProps={{
                              style: {
                                fontWeight: 600,
                              },
                            }}
                          />
                        )}
                      </ListItem>
                    ))}
                  </React.Fragment>
                )
              );
            })}
          </List>
        </Box>
        <Button
          variant="text"
          sx={{
            maxWidth: "fit-content",
            marginTop: "auto",
            display: { xs: "none", md: "flex" },
          }}
          aria-label="open drawer"
          onClick={toggleDrawer(!open)}
        >
          {open ? (
            <ChevronLeftDouble
              size="24"
              style={{ color: theme.colors.colorsForegroundFgBrandSecondary }}
              strokeWidth={2}
            />
          ) : (
            <ChevronRightDouble
              size="24"
              style={{ color: theme.colors.colorsForegroundFgBrandSecondary }}
              strokeWidth={2}
            />
          )}
        </Button>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
