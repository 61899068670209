export const termsEN = {
    terms_and_conditions: `<H1>TERMS AND CONDITIONS {{productName}}</H1>
 
            <p>These terms and conditions apply when using Energy Save's service {{productName}}.</p>
            
            <p></p>
            
            <H2>1. DEFINITIONS</H2>
            
            <p>In these Terms and Conditions, the following terms, when appearing with an initial capital letter, have the meaning set forth below.</p>
            
            <p>"Terms and Conditions" refers to these terms and conditions, which apply to your use of the Service.</p>
            
            <p>"User", "you" or "your" refers to the person who is the owner of the User Account.</p>
            
            <p>"User Account" refers to the User's account in the Service.</p>
            
            <p>"Energy Save", "us" or "we" refers to ES Energy Save Holding AB (publ), company registration number 559117-1292.</p>
            
            <p>"ES heat pump" refers to the heat pumps of the brand [Energy Save] which the User connects to the Service.</p>
            
            <p>"Party" refers to Energy Save or the User.</p>
            
            <p>"Parties" refers to Energy Save and the User collectively.</p>
            
            <p>"Service" refers to the web application {{productName}} provided by Energy Save for the monitoring and control of ES heat pumps.</p>
            
            <H2>2. TERMS AND CONDITIONS</H2>
            
            <p>By using the Service, you accept these Terms and Conditions. It is therefore important that you read the terms and conditions before you start using the Service.</p>
            
            <H2>3. MONITORING AND CONTROLLING YOUR ES HEAT PUMP</H2>
            
            <p>3.1 The User undertakes to:</p>
            
            <p>(i) follow the instructions specified in the Service;</p>
            
            <H2>4. YOUR USER ACCOUNT</H2>
            
            <p>4.1 To access the Service, you must have a User Account.</p>
            
            <p>4.2 It is important that you fill in correct information when you register your User Account and when you use the Service. The User is responsible for the data being correct and complete, as well as for any consequences of incorrect data.</p>
            
            <p>4.3 It is your responsibility to save and keep your login details secure, which means, among other things, that you may not share your login details with anyone else and that the details are stored in such a way that unauthorized persons cannot gain access to the information. If you still suspect that your login details have been disclosed or used by someone unauthorised, you must report this to Energy Save immediately. You are responsible for all purchases made with your login details up to the time you sent us your notification.</p>
            
            <p>4.4 We reserve the right to deny you access to the Service if we suspect that you are abusing your User Account or your login details or otherwise breaching these Terms and Conditions. If we deem it appropriate and/or necessary, we reserve the right to assign you new login details.</p>
            
            <p>5. PERSONAL DATA</p>
            
            <p>Energy Save processes your personal data within the scope of the Service. More information about how your personal data is processed and how you can exercise your rights can be found in our privacy policy at <a href="{{hostName}}/static/policy?productName={{productName}}">{{hostName}}/static/policy?productName={{productName}} </a></p>
            
            <H2>6. LIABILITY</H2>
            
            <p>6.1 Energy Save is not responsible for any damage due to interruptions in the Service.</p>
            
            <p>6.2 Energy Save is not responsible for damages that occur due to the User disregarding manuals and/or instructions in the Service or otherwise from Energy Save.</p>
            
            <p>6.3 Energy Save is not responsible for disruptions or irregularities in the electricity supply.</p>
            
            <p>6.4 The user is not entitled to compensation or reimbursement of fees due to errors or interruptions in the ES heat pump.</p>
            
            <p>6.5 Energy Save is not responsible for indirect damage or consequential damage to the User's property that occurs as a result of the use of the Service or the ES heat pump.</p>
            
            <p>6.6 Energy Save's liability for damages is limited to direct damages. Energy Save is not responsible for indirect damages and losses such as loss of profit or loss of expected savings.</p>
            
            <p>6.7 Energy Save's maximum liability towards the User for any loss or damage is limited to one thousand Euro.</p>
            
            <H2>7. FORCE MAJEURE</H2>
            
            <p>If Energy Save's fulfilment of its obligations under these Terms and Conditions is significantly hindered or prevented due to circumstances beyond Energy Save's control, this shall constitute grounds for exemption, which entails exemption from damages and other penalties.</p>
            
            <H2>8. CHANGE OF TERMS</H2>
            
            <p>We reserve the right to change these Terms and Conditions, fees, or any part of the Service and Premium Services at any time. We will announce material changes to these Terms and Conditions by making the revised Terms and Conditions available on the Service for you to read.</p>
            
            <H2>9. IP RIGHTS</H2>
            
            <p>All intellectual property rights, such as trademarks and copyright to the Service, belong to Energy Save or Energy Save's licensors. Any use of the Service or its content, including copying or storing of such content in whole or in part, other than for your own personal, non-commercial use, is prohibited without the permission of Energy Save.</p>
            
            <H2>10. YOUR USE OF THE SERVICE</H2>
            
            <p>10.1 In order to provide the best possible experience for you, we need to ensure that our services run smoothly. In connection with your acceptance of these Terms and Conditions, you understand and agree not to (i) publish, transmit, redistribute, upload or promote any communication or content that could harm or have a negative effect on our company, our business or on the Service; (ii) act in a way or use a device that restricts, hinders, interferes or completely stops any other user from using the Service, or that affects the security of the Service or (iii) use any device or attempt to use any search engine, software, tool, agent, script or other device or mechanism to navigate or search the Service or copy content from the Service. Energy Save reserves the right to immediately deny access to the Service and terminate your User Account in the event of a breach of this clause or any other clause of these terms and conditions.</p>
            
            <p>10.2 Energy Save reserves the right to any typographical errors in information provided in the Service. Energy Save reserves the right to update or change the information in the Service at any time.</p>
            
            <H2>11. SERVICE LEVELS</H2>
            
            <p>Energy Save is responsible for the functionality of the Service. Generally, the service is available via internet connection, 24 hours a day, seven days a week. However, Energy Save (and subcontractors engaged by Energy Save) have the right to take measures that affect the availability of the Service if it is deemed necessary for technical, operational or security reasons.</p>
            
            <p>Larger planned interruptions are notified to the User seven days before the planned downtime. Unplanned interruptions may occur. To the extent that Energy Save is responsible for and can affect such an interruption, Energy Save shall promptly remedy the error.</p>
            
            <p>Energy Save is responsible for providing adequate administrative, physical and technical security measures. Energy Save makes regular backup copies in order to keep customer data safe.</p>
            
            <H2>12. VALIDITY</H2>
            
            <p>If one or more provisions of these Terms and Conditions are deemed to be invalid or ruled ineffective by any court or supervisory authority, this shall not affect the validity of the remaining contractual provisions.</p>
            
            <H2>13. APPLICABLE LAW AND DISPUTE RESOLUTION</H2>
            
            <p>13.1 Swedish substantive law shall apply to these Terms and Conditions.</p>
            
            <p>13.2 Disputes concerning the drafting or application of these Terms and Conditions as well as other disputes concerning legal relationships due to the Terms and Conditions shall be finally settled in a general court, whereby Gothenburg District Court shall constitute the first instance.</p>
            
            <p> </p>
`
}