import React, { useEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import AuthenticationAPI from "../../api/AuthenticationAPI";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

interface PrivateRouteProps {
  element: React.ReactElement;
  rolesToCheck: Array<string>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  element,
  rolesToCheck,
}) => {
  let navigate = useNavigate();
  const authApi = new AuthenticationAPI(navigate);
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);

  const rolesRef = useRef(rolesToCheck);

  useEffect(() => {
    authApi
      .hasRoles(rolesRef.current)
      .then((response) => {
        setHasPermission(response.data.value);
      })
      .catch((error) => {
        setHasPermission(false);
      });
  }, []);

  if (hasPermission === null) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return hasPermission ? element : <Navigate to="/portal/no-access" />;
};

export default PrivateRoute;
