import { Box, Typography } from "@mui/material";
import React from "react";
import useTheme from "../../../../theme/hooks/useTheme";

export enum DataType {
  withUnit,
  onlyValue,
  withUnitNeedFormatting,
}

export interface IRealTimeDataRow {
  label: string;
  value: string | undefined;
  dataType?: DataType;
}

export default function RealTimeDataRow({
  label,
  value,
  dataType = DataType.onlyValue,
}: IRealTimeDataRow) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mb: theme.spacing.spacingMd,
      }}
    >
      <Typography
        sx={{
          ...theme.typography[".text-md-medium"],
          color: theme.colors.colorsTextTextSecondary,
        }}
      >
        {label}
      </Typography>
      <Typography
        sx={{
          ...theme.typography[".text-md-semibold"],
          color: theme.colors.colorsTextTextPrimary,
        }}
      >
        {value || "--"}
      </Typography>
    </Box>
  );
}
