import React, { useEffect, useState } from "react";
import useTheme from "../../../../../theme/hooks/useTheme";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { AlertTriangle } from "untitledui-js-base";

export interface IEmptyPageProps {
  noAlerts?: boolean;
  noResultsFound?: boolean;
  onClearFilterClick?: () => void;
}

export default function EmptyPage({
  noAlerts,
  noResultsFound,
  onClearFilterClick,
}: IEmptyPageProps) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");

  const [showEmptyPage, setShowEmptyPage] = useState(noAlerts);
  const [showNoResultsPage, setShowNoResultsPage] = useState(noResultsFound);

  useEffect(() => {
    setShowEmptyPage(noAlerts);
  }, [noAlerts]);

  useEffect(() => {
    setShowNoResultsPage(noResultsFound);
  }, [noResultsFound]);

  if (showEmptyPage) {
    return <></>;
  }

  if (showNoResultsPage) {
    return <></>;
  }

  return (
    <Box
      sx={{
        height: "100%",
        mb: theme.spacing.spacing2xl,
        border: `1px solid ${theme.colors.colorsBorderBorderSecondary}`,
        borderRadius: theme.radius.radiusXl,
        boxShadow: "0px 1px 2px 0px #1018280D",
        display: "flex",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          m: "0 auto",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            height: "48px",
            width: "48px",
            borderRadius: theme.radius.radiusFull,
            backgroundColor:
              theme.colors.componentColorsUtilityGrayUtilityGray50,
            color:
              theme.colors
                .componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgGray,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: theme.spacing.spacingXl,
          }}
        >
          <AlertTriangle
            size="20"
            style={{
              color: "inherit",
            }}
            strokeWidth={2}
          />
        </Box>
        <Typography
          sx={{
            ...theme.typography[".text-md-semibold"],
            color: theme.colors.colorsTextTextPrimary,
            mb: theme.spacing.spacingMd,
          }}
        >
          {t("pages.alertsPage.emptyPage.noAlertsTitle")}
        </Typography>
        <Typography
          sx={{
            ...theme.typography[".text-sm-regular"],
            color: theme.colors.colorsTextTextTertiary,
          }}
        >
          {t("pages.alertsPage.emptyPage.noAlertsDescription")}
        </Typography>
      </Box>
    </Box>
  );
}
