import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useTheme from "../../../../../../theme/hooks/useTheme";
import SecondaryTitle from "../../components/SecondaryTitle/SecondaryTitle";
import DisabledField from "../../components/DisabledField/DisabledField";
import NumberInput from "../../components/NumberInput/NumberInput";

export interface IMainSectionProps {
  // roomTemperature: string;
  // desiredRoomTemperature: number;
  // setDesiredRoomTemperature: (val: number | undefined) => void;
  mode: string | undefined;
  // zoneNumber: string;
}

export default function MainSection({
  // roomTemperature,
  // desiredRoomTemperature,
  // setDesiredRoomTemperature,
  mode,
}: // zoneNumber,
IMainSectionProps) {
  const { t } = useTranslation("cloud_ui");
  const theme = useTheme();

  // const [desiredRoomTemperatureValue, setDesiredRoomTemperatureValue] =
  //   useState<number>(desiredRoomTemperature);

  // useEffect(() => {
  //   setDesiredRoomTemperatureValue(desiredRoomTemperature);
  // }, [desiredRoomTemperature]);

  return (
    <>
      {/* <SecondaryTitle
        title={`${t(
          "pages.installationSettings.zones.zoneTitle"
        )} ${zoneNumber}`}
        variant="md"
      />
      <DisabledField
        label={`${t(
          "pages.installationSettings.zones.fieldsLabels.roomTemperature"
        )}`}
        value={roomTemperature}
      />
      <NumberInput
        initialValue={desiredRoomTemperatureValue}
        changeValue={(val) => {
          setDesiredRoomTemperature(val);
        }}
        min={12}
        max={30}
        label={`${t(
          "pages.installationSettings.zones.fieldsLabels.desiredRoomTemperature"
        )}`}
        hint={`${t(
          "pages.installationSettings.zones.fieldHints.desiredRoomTemperature",
          { from: "12", to: "30" }
        )}`}
      /> */}
      {mode && (
        <DisabledField
          label={`${t(
            "pages.installationSettings.zones.fieldsLabels.zoneHeatingCooling"
          )}`}
          value={mode}
        />
      )}
    </>
  );
}
