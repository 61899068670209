import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { TableContainerWithCustomScroll } from "../EnergySavingCalculatorPage";
import { useTranslation } from "react-i18next";
import { defineSourceAndUnit } from "../helpers/defineSourceAndUnit";
import { IAlternativeSource } from "../DownloadReportInterface";

export interface IInputEnergyComparisionTableProps {
  showEnergyPricing: boolean;
  currency: string | null | undefined;
  totalEnergyEfficiency: string | undefined;
  totalInputEnergy: string | undefined;
  totalCosts: string | undefined;
  currentEnergySource: string | undefined;
  currentEnergyPrice: string | undefined;
  electricityPrice: string | undefined;
  totalEnergyDemand: string;
}

export const InputEnergyComparisionTable = forwardRef(
  (
    {
      showEnergyPricing,
      currency,
      totalEnergyEfficiency,
      totalInputEnergy,
      totalCosts,
      currentEnergySource,
      currentEnergyPrice,
      electricityPrice,
      totalEnergyDemand,
    }: IInputEnergyComparisionTableProps,
    ref
  ) => {
    const SavingTableRow = styled(TableRow)(({ theme }) => ({
      [`th, td`]: {
        padding: `6px 16px`,
        fontSize: "1rem",
      },
      "&:last-child td, &:last-child th": { border: 0 },
    }));
    const { t } = useTranslation("cloud_ui");
    const [
      electricHeatingInputEnergyNeeded,
      setElectricHeatingInputEnergyNeeded,
    ] = useState<string>();
    const [
      districtHeatingInputEnergyNeeded,
      setDistrictHeatingInputEnergyNeeded,
    ] = useState<string>();
    const [oilInputEnergyNeeded, setOilInputEnergyNeeded] = useState<string>();
    const [naturalGasInputEnergyNeeded, setNaturalGasInputEnergyNeeded] =
      useState<string>();

    const [electricHeatingEnergyCosts, setElectricHeatingEnergyCosts] =
      useState<string>();

    const [
      currentEnergySourceInputEnergyCosts,
      setCurrentEnergySourceInputEnergyCosts,
    ] = useState<string>();

    useImperativeHandle(ref, () => ({
      getData: () => {
        const alternativeSourcesForReport: Array<IAlternativeSource> = [
          {
            currentSource: false,
            name: t(
              "pages.savingCalculator.inputEnergyComparison.ESInstallationRowTitle"
            ),
            efficiency: totalEnergyEfficiency?.replace(/[^\d]/g, "") || "",
            inputEnergy: totalInputEnergy?.replace(/[^\d]/g, "") || "",
            inputEnergyCost: totalCosts?.replace(/[^\d]/g, "") || "",
          },
          {
            currentSource: currentEnergySource === "2",
            amountInOriginalUOM:
              currentEnergySource === "2"
                ? calculateAmountInOriginalUOM(electricHeatingInputEnergyNeeded)
                : "",
            name: t(
              "pages.savingCalculator.inputEnergyComparison.electricHeatingRowTitle"
            ),
            efficiency: "95",
            inputEnergy:
              electricHeatingInputEnergyNeeded?.replace(/[^\d]/g, "") || "",
            inputEnergyCost:
              electricHeatingEnergyCosts?.replace(/[^\d]/g, "") || undefined,
          },
          {
            currentSource: currentEnergySource === "3",
            amountInOriginalUOM:
              currentEnergySource === "3"
                ? calculateAmountInOriginalUOM(districtHeatingInputEnergyNeeded)
                : "",
            name: t(
              "pages.savingCalculator.inputEnergyComparison.districtHeatingRowTitle"
            ),
            efficiency: "95",
            inputEnergy:
              districtHeatingInputEnergyNeeded?.replace(/[^\d]/g, "") || "",
            inputEnergyCost:
              currentEnergySource === "3"
                ? (currentEnergySourceInputEnergyCosts || "")?.replace(
                    /[^\d]/g,
                    ""
                  )
                : undefined,
          },
          {
            currentSource: currentEnergySource === "0",
            amountInOriginalUOM:
              currentEnergySource === "0"
                ? calculateAmountInOriginalUOM(oilInputEnergyNeeded)
                : "",
            name: t("pages.savingCalculator.inputEnergyComparison.oilRowTitle"),
            efficiency: "80",
            inputEnergy: oilInputEnergyNeeded?.replace(/[^\d]/g, "") || "",
            inputEnergyCost:
              currentEnergySource === "0"
                ? (currentEnergySourceInputEnergyCosts || "")?.replace(
                    /[^\d]/g,
                    ""
                  )
                : undefined,
          },
          {
            currentSource: currentEnergySource === "1",
            amountInOriginalUOM:
              currentEnergySource === "1"
                ? calculateAmountInOriginalUOM(naturalGasInputEnergyNeeded)
                : "",
            name: t(
              "pages.savingCalculator.inputEnergyComparison.naturalGasRowTitle"
            ),
            efficiency: "65",
            inputEnergy:
              naturalGasInputEnergyNeeded?.replace(/[^\d]/g, "") || "",
            inputEnergyCost:
              currentEnergySource === "1"
                ? (currentEnergySourceInputEnergyCosts || "")?.replace(
                    /[^\d]/g,
                    ""
                  )
                : undefined,
          },
        ];
        return alternativeSourcesForReport;
      },
    }));

    useEffect(() => {
      if (totalEnergyDemand) {
        const totalEnergyDemandNumber = Number(
          totalEnergyDemand.replace(/[^\d]/g, "")
        );
        const EH = Math.round(totalEnergyDemandNumber / 0.95);
        setElectricHeatingInputEnergyNeeded(
          `${EH.toLocaleString("sv-SE")} kWh`
        );
        const DH = Math.round(totalEnergyDemandNumber / 0.95);
        setDistrictHeatingInputEnergyNeeded(
          `${DH.toLocaleString("sv-SE")} kWh`
        );
        const oil = Math.round(totalEnergyDemandNumber / 0.8);
        setOilInputEnergyNeeded(`${oil.toLocaleString("sv-SE")} kWh`);
        const naturalGas = Math.round(totalEnergyDemandNumber / 0.65);
        setNaturalGasInputEnergyNeeded(
          `${naturalGas.toLocaleString("sv-SE")} kWh`
        );
        const currPriceNumber = Number(
          currentEnergyPrice?.replace(/[^\d.,]/g, "")
        );
        let inputEnergyNeeded: number;
        switch (currentEnergySource) {
          case "0":
            inputEnergyNeeded = oil;
            break;
          case "1":
            inputEnergyNeeded = naturalGas;
            break;
          case "3":
            inputEnergyNeeded = DH;
            break;
          default:
            break;
        }
        const { approximatelyEquals } =
          defineSourceAndUnit(currentEnergySource);
        const approximatelyEqualsNumber = Number(
          approximatelyEquals?.replace(/[^\d]/g, "")
        );
        const value = inputEnergyNeeded! / (approximatelyEqualsNumber || 1);
        const rounded = Math.ceil(value * 10) / 10;
        const source = rounded % 1 === 0 ? Math.floor(rounded) : rounded;
        const formattedValue = parseInt((source * currPriceNumber).toString());
        setCurrentEnergySourceInputEnergyCosts(
          `${formattedValue?.toLocaleString("sv-SE")} ${currency}`
        );
      }
    }, [totalEnergyDemand, currentEnergySource, currentEnergyPrice]);

    useEffect(() => {
      if (electricHeatingInputEnergyNeeded && electricityPrice) {
        const priceNumber = Number(electricityPrice?.replace(/[^\d.,]/g, ""));
        const EHNumber = Number(
          electricHeatingInputEnergyNeeded?.replace(/[^\d]/g, "")
        );
        const price = Math.round(priceNumber * EHNumber);
        setElectricHeatingEnergyCosts(
          `${price.toLocaleString("sv-SE")} ${currency}`
        );
      }
    }, [electricityPrice, electricHeatingInputEnergyNeeded]);

    const currEnergySourceLabel = (inputEnergyNeeded: string | undefined) => {
      const { unit, approximatelyEquals } =
        defineSourceAndUnit(currentEnergySource);
      const inputEnergyNeededNumber = Number(
        inputEnergyNeeded?.replace(/[^\d]/g, "")
      );
      const approximatelyEqualsNumber = Number(
        approximatelyEquals?.replace(/[^\d]/g, "")
      );
      const value = inputEnergyNeededNumber / (approximatelyEqualsNumber || 1);
      const rounded = Math.ceil(value * 10) / 10;
      const calculatedValue = rounded % 1 === 0 ? Math.floor(rounded) : rounded;
      const formatted = calculatedValue.toLocaleString("sv-SE");
      const prefix = `${formatted} ${unit}`;
      return (
        <Typography ml={"5px"} fontSize={14} fontStyle={"italic"}>
          ({prefix}){" "}
          {t(
            "pages.savingCalculator.inputEnergyComparison.currentEnergySourseLabel"
          )}
        </Typography>
      );
    };

    const calculateAmountInOriginalUOM = (
      inputEnergyNeeded: string | undefined
    ) => {
      const { approximatelyEquals } = defineSourceAndUnit(currentEnergySource);
      const inputEnergyNeededNumber = Number(
        inputEnergyNeeded?.replace(/[^\d]/g, "")
      );
      const approximatelyEqualsNumber = Number(
        approximatelyEquals?.replace(/[^\d]/g, "")
      );
      const value = inputEnergyNeededNumber / (approximatelyEqualsNumber || 1);
      const rounded = Math.ceil(value * 10) / 10;
      const prefix = `${rounded % 1 === 0 ? Math.floor(rounded) : rounded}`;
      return prefix;
    };
    const memoizedTable = useMemo(() => {
      return (
        <Box mb={"35px"}>
          <TableContainerWithCustomScroll>
            <Table sx={{ minWidth: 900 }} aria-label="simple table">
              <TableHead sx={{ borderBottom: "2px solid #95C11F" }}>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="right">
                    <Typography fontWeight={500} fontSize={14}>
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.efficiencyColTitle"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontWeight={500} fontSize={14}>
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.inputEnergyNeededColTitle"
                      )}{" "}
                      {` ⁽⁴`}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontWeight={500} fontSize={14}>
                        {t(
                          "pages.savingCalculator.inputEnergyComparison.inputEnergyCostsColTitle"
                        )}
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <SavingTableRow
                  sx={{
                    borderBottom: "1px solid #95C11F",
                    backgroundColor: "#f7fbf2",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography fontWeight={700} fontSize={14}>
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.ESInstallationRowTitle"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14} fontWeight={700}>
                      {totalEnergyEfficiency}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>{totalInputEnergy}</Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontSize={14}>{totalCosts}</Typography>
                    </TableCell>
                  )}
                </SavingTableRow>
                <SavingTableRow
                  sx={{
                    borderBottom: "1px solid #95C11F",
                    backgroundColor:
                      currentEnergySource === "2" ? "#f7fbf2" : "initial",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      fontWeight={700}
                      fontSize={14}
                      sx={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.electricHeatingRowTitle"
                      )}
                      {currentEnergySource === "2" &&
                        currEnergySourceLabel(electricHeatingInputEnergyNeeded)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>{"95 %"}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {electricHeatingInputEnergyNeeded}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontSize={14}>
                        {electricHeatingEnergyCosts}
                      </Typography>
                    </TableCell>
                  )}
                </SavingTableRow>
                <SavingTableRow
                  sx={{
                    borderBottom: "1px solid #95C11F",
                    backgroundColor:
                      currentEnergySource === "3" ? "#f7fbf2" : "initial",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      fontWeight={700}
                      fontSize={14}
                      sx={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.districtHeatingRowTitle"
                      )}
                      {currentEnergySource === "3" &&
                        currEnergySourceLabel(districtHeatingInputEnergyNeeded)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>{"95 %"}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {districtHeatingInputEnergyNeeded}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontSize={14}>
                        {currentEnergySource === "3"
                          ? `${currentEnergySourceInputEnergyCosts} ⁽⁵`
                          : "-"}
                      </Typography>
                    </TableCell>
                  )}
                </SavingTableRow>
                <SavingTableRow
                  sx={{
                    borderBottom: "1px solid #95C11F",
                    backgroundColor:
                      currentEnergySource === "0" ? "#f7fbf2" : "initial",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      fontWeight={700}
                      fontSize={14}
                      sx={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.oilRowTitle"
                      )}
                      {currentEnergySource === "0" &&
                        currEnergySourceLabel(oilInputEnergyNeeded)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>{"80 %"}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {oilInputEnergyNeeded}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontSize={14}>
                        {currentEnergySource === "0"
                          ? `${currentEnergySourceInputEnergyCosts} ⁽⁵`
                          : "-"}
                      </Typography>
                    </TableCell>
                  )}
                </SavingTableRow>
                <SavingTableRow
                  sx={{
                    borderBottom: "1px solid #95C11F",
                    backgroundColor:
                      currentEnergySource === "1" ? "#f7fbf2" : "initial",
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Typography
                      fontWeight={700}
                      fontSize={14}
                      sx={{ display: "flex", whiteSpace: "nowrap" }}
                    >
                      {t(
                        "pages.savingCalculator.inputEnergyComparison.naturalGasRowTitle"
                      )}
                      {currentEnergySource === "1" &&
                        currEnergySourceLabel(naturalGasInputEnergyNeeded)}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>{"65 %"}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography fontSize={14}>
                      {naturalGasInputEnergyNeeded}
                    </Typography>
                  </TableCell>
                  {showEnergyPricing && (
                    <TableCell align="right">
                      <Typography fontSize={14}>
                        {currentEnergySource === "1"
                          ? `${currentEnergySourceInputEnergyCosts} ⁽⁵`
                          : "-"}
                      </Typography>
                    </TableCell>
                  )}
                </SavingTableRow>
              </TableBody>
            </Table>
          </TableContainerWithCustomScroll>
          <Box
            mt={"16px"}
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              color: "rgba(0, 0, 0, 0.60)",
            }}
          >
            <Typography fontSize={12} fontStyle={"italic"} mr={"20px"}>
              {t("pages.savingCalculator.inputEnergyComparison.tableHint4", {
                totalEnergyDemand,
              })}
            </Typography>
            {showEnergyPricing && (
              <Typography fontSize={12} fontStyle={"italic"}>
                {t("pages.savingCalculator.inputEnergyComparison.tableHint5")}
              </Typography>
            )}
          </Box>
        </Box>
      );
    }, [
      showEnergyPricing,
      currency,
      totalEnergyEfficiency,
      totalInputEnergy,
      totalCosts,
      currentEnergySource,
      currentEnergyPrice,
      electricityPrice,
      totalEnergyDemand,
    ]);
    return memoizedTable;
  }
);
