export const themes = {
  eSLightMode: {
    colors: {
      colorsBackgroundBgActive: "#f9fafb",
      colorsBackgroundBgBrandPrimary: "#f4f9e9",
      colorsBackgroundBgBrandPrimaryAlt: "#f4f9e9",
      colorsBackgroundBgBrandSecondary: "#eaf3d2",
      colorsBackgroundBgBrandSection: "#3c4d0c",
      colorsBackgroundBgBrandSectionSubtle: "#597413",
      colorsBackgroundBgBrandSolid: "#779a19",
      colorsBackgroundBgBrandSolidHover: "#597413",
      colorsBackgroundBgDisabled: "#f2f4f7",
      colorsBackgroundBgDisabledSubtle: "#f9fafb",
      colorsBackgroundBgErrorPrimary: "#fef3f2",
      colorsBackgroundBgErrorSecondary: "#fee4e2",
      colorsBackgroundBgErrorSolid: "#d92d20",
      colorsBackgroundBgOverlay: "#0c111d",
      colorsBackgroundBgPrimary: "#ffffff",
      colorsBackgroundBgPrimarySolid: "#0c111d",
      colorsBackgroundBgPrimaryAlt: "#ffffff",
      colorsBackgroundBgPrimaryHover: "#f9fafb",
      colorsBackgroundBgQuaternary: "#eaecf0",
      colorsBackgroundBgSecondary: "#f9fafb",
      colorsBackgroundBgSecondarySolid: "#475467",
      colorsBackgroundBgSecondaryAlt: "#f9fafb",
      colorsBackgroundBgSecondaryHover: "#f2f4f7",
      colorsBackgroundBgSecondarySubtle: "#fcfcfd",
      colorsBackgroundBgSuccessPrimary: "#ecfdf3",
      colorsBackgroundBgSuccessSecondary: "#dcfae6",
      colorsBackgroundBgSuccessSolid: "#079455",
      colorsBackgroundBgTertiary: "#f2f4f7",
      colorsBackgroundBgWarningPrimary: "#fffaeb",
      colorsBackgroundBgWarningSecondary: "#fef0c7",
      colorsBackgroundBgWarningSolid: "#dc6803",
      colorsBorderBorderBrand: "#bfda79",
      colorsBorderBorderBrandSolid: "#779a19",
      colorsBorderBorderBrandSolidAlt: "#779a19",
      colorsBorderBorderDisabled: "#d0d5dd",
      colorsBorderBorderDisabledSubtle: "#eaecf0",
      colorsBorderBorderError: "#fda29b",
      colorsBorderBorderErrorSolid: "#d92d20",
      colorsBorderBorderPrimary: "#d0d5dd",
      colorsBorderBorderSecondary: "#eaecf0",
      colorsBorderBorderTertiary: "#f2f4f7",
      colorsFocusFocusBrand: "#95c11f3d",
      colorsFocusFocusError: "#f044383d",
      colorsFocusFocusGray: "#66708533",
      colorsForegroundFgBrandPrimary: "#779a19",
      colorsForegroundFgBrandPrimaryAlt: "#779a19",
      colorsForegroundFgBrandSecondary: "#95c11f",
      colorsForegroundFgDisabled: "#98a2b3",
      colorsForegroundFgDisabledSubtle: "#d0d5dd",
      colorsForegroundFgErrorPrimary: "#d92d20",
      colorsForegroundFgErrorSecondary: "#f04438",
      colorsForegroundFgPrimary: "#101828",
      colorsForegroundFgQuaternary: "#667085",
      colorsForegroundFgQuaternaryHover: "#475467",
      colorsForegroundFgQuinary: "#98a2b3",
      colorsForegroundFgQuinaryHover: "#667085",
      colorsForegroundFgSecondary: "#344054",
      colorsForegroundFgSecondaryHover: "#182230",
      colorsForegroundFgSenary: "#d0d5dd",
      colorsForegroundFgSuccessPrimary: "#079455",
      colorsForegroundFgSuccessSecondary: "#17b26a",
      colorsForegroundFgTertiary: "#475467",
      colorsForegroundFgTertiaryHover: "#344054",
      colorsForegroundFgWarningPrimary: "#dc6803",
      colorsForegroundFgWarningSecondary: "#f79009",
      colorsForegroundFgWhite: "#ffffff",
      colorsTextTextBrandPrimary: "#1e2706",
      colorsTextTextBrandSecondary: "#597413",
      colorsTextTextBrandTertiary: "#779a19",
      colorsTextTextBrandTertiaryAlt: "#779a19",
      colorsTextTextDisabled: "#667085",
      colorsTextTextErrorPrimary: "#d92d20",
      colorsTextTextPlaceholder: "#667085",
      colorsTextTextPlaceholderSubtle: "#d0d5dd",
      colorsTextTextPrimary: "#101828",
      colorsTextTextPrimaryOnBrand: "#ffffff",
      colorsTextTextQuaternary: "#667085",
      colorsTextTextQuaternaryOnBrand: "#bfda79",
      colorsTextTextSecondary: "#344054",
      colorsTextTextSecondaryHover: "#182230",
      colorsTextTextSecondaryOnBrand: "#d5e6a5",
      colorsTextTextSuccessPrimary: "#079455",
      colorsTextTextTertiary: "#475467",
      colorsTextTextTertiaryHover: "#344054",
      colorsTextTextTertiaryOnBrand: "#d5e6a5",
      colorsTextTextWarningPrimary: "#dc6803",
      colorsTextTextWhite: "#ffffff",
      componentColorsAlphaAlphaBlack10: "#0000001a",
      componentColorsAlphaAlphaBlack100: "#000000",
      componentColorsAlphaAlphaBlack20: "#00000033",
      componentColorsAlphaAlphaBlack30: "#0000004d",
      componentColorsAlphaAlphaBlack40: "#00000066",
      componentColorsAlphaAlphaBlack50: "#00000080",
      componentColorsAlphaAlphaBlack60: "#00000099",
      componentColorsAlphaAlphaBlack70: "#000000b2",
      componentColorsAlphaAlphaBlack80: "#000000cc",
      componentColorsAlphaAlphaBlack90: "#000000e5",
      componentColorsAlphaAlphaWhite10: "#ffffff1a",
      componentColorsAlphaAlphaWhite100: "#ffffff",
      componentColorsAlphaAlphaWhite20: "#ffffff33",
      componentColorsAlphaAlphaWhite30: "#ffffff4d",
      componentColorsAlphaAlphaWhite40: "#ffffff66",
      componentColorsAlphaAlphaWhite50: "#ffffff80",
      componentColorsAlphaAlphaWhite60: "#ffffff99",
      componentColorsAlphaAlphaWhite70: "#ffffffb2",
      componentColorsAlphaAlphaWhite80: "#ffffffcc",
      componentColorsAlphaAlphaWhite90: "#ffffffe5",
      componentColorsComponentsAppStoreBadgesAppStoreBadgeBorder: "#a6a6a6",
      componentColorsComponentsApplicationNavigationNavItemButtonIconFg:
        "#667085",
      componentColorsComponentsApplicationNavigationNavItemButtonIconFgActive:
        "#344054",
      componentColorsComponentsApplicationNavigationNavItemIconFg: "#667085",
      componentColorsComponentsApplicationNavigationNavItemIconFgActive:
        "#667085",
      componentColorsComponentsAvatarsAvatarBg: "#f2f4f7",
      componentColorsComponentsAvatarsAvatarContrastBorder: "#00000014",
      componentColorsComponentsAvatarsAvatarFocusBorder: "#98a2b324",
      componentColorsComponentsAvatarsAvatarProfilePhotoBorder: "#ffffff",
      componentColorsComponentsBreadcrumbsBreadcrumbBgHover: "#f9fafb",
      componentColorsComponentsBreadcrumbsBreadcrumbBrandBgHover: "#f4f9e9",
      componentColorsComponentsBreadcrumbsBreadcrumbBrandFgHover: "#597413",
      componentColorsComponentsBreadcrumbsBreadcrumbBrandIconFgHover: "#597413",
      componentColorsComponentsBreadcrumbsBreadcrumbFg: "#475467",
      componentColorsComponentsBreadcrumbsBreadcrumbFgHover: "#344054",
      componentColorsComponentsBreadcrumbsBreadcrumbIconFg: "#667085",
      componentColorsComponentsBreadcrumbsBreadcrumbIconFgHover: "#344054",
      componentColorsComponentsButtonsPrimaryButtonPrimaryBg: "#779a19",
      componentColorsComponentsButtonsPrimaryButtonPrimaryBgHover: "#597413",
      componentColorsComponentsButtonsPrimaryButtonPrimaryBorder: "#779a19",
      componentColorsComponentsButtonsPrimaryButtonPrimaryBorderHover:
        "#597413",
      componentColorsComponentsButtonsPrimaryButtonPrimaryFg: "#ffffff",
      componentColorsComponentsButtonsPrimaryButtonPrimaryFgHover: "#ffffff",
      componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorBg:
        "#d92d20",
      componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorBgHover:
        "#b42318",
      componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorBorder:
        "#d92d20",
      componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorBorderHover:
        "#b42318",
      componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorFg:
        "#ffffff",
      componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorFgHover:
        "#ffffff",
      componentColorsComponentsButtonsSecondaryButtonSecondaryBg: "#ffffff",
      componentColorsComponentsButtonsSecondaryButtonSecondaryBgHover:
        "#f9fafb",
      componentColorsComponentsButtonsSecondaryButtonSecondaryBorder: "#d0d5dd",
      componentColorsComponentsButtonsSecondaryButtonSecondaryBorderHover:
        "#d0d5dd",
      componentColorsComponentsButtonsSecondaryButtonSecondaryFg: "#344054",
      componentColorsComponentsButtonsSecondaryButtonSecondaryFgHover:
        "#182230",
      componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBg:
        "#ffffff",
      componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBgHover:
        "#f4f9e9",
      componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBorder:
        "#bfda79",
      componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBorderHover:
        "#d5e6a5",
      componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg:
        "#597413",
      componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFgHover:
        "#3c4d0c",
      componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorBg:
        "#ffffff",
      componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorBgHover:
        "#fef3f2",
      componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorBorder:
        "#fda29b",
      componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorBorderHover:
        "#fda29b",
      componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorFg:
        "#b42318",
      componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorFgHover:
        "#912018",
      componentColorsComponentsButtonsTertiaryButtonTertiaryBgHover: "#f9fafb",
      componentColorsComponentsButtonsTertiaryButtonTertiaryFg: "#475467",
      componentColorsComponentsButtonsTertiaryButtonTertiaryFgHover: "#344054",
      componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorBgHover:
        "#f4f9e9",
      componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFg:
        "#597413",
      componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFgHover:
        "#3c4d0c",
      componentColorsComponentsButtonsTertiaryErrorButtonTertiaryErrorBgHover:
        "#fef3f2",
      componentColorsComponentsButtonsTertiaryErrorButtonTertiaryErrorFg:
        "#b42318",
      componentColorsComponentsButtonsTertiaryErrorButtonTertiaryErrorFgHover:
        "#912018",
      componentColorsComponentsFootersFooterBadgeBg: "#ecfdf3",
      componentColorsComponentsFootersFooterBadgeBorder: "#abefc6",
      componentColorsComponentsFootersFooterBadgeFg: "#067647",
      componentColorsComponentsFootersFooterButtonFg: "#d5e6a5",
      componentColorsComponentsFootersFooterButtonFgHover: "#ffffff",
      componentColorsComponentsHeaderSectionsHeaderAbstract100Bg: "#eaf3d2",
      componentColorsComponentsHeaderSectionsHeaderAbstract200Bg: "#d5e6a5",
      componentColorsComponentsHeaderSectionsHeaderAbstract300Bg: "#bfda79",
      componentColorsComponentsHeaderSectionsHeaderAbstract50Bg: "#f4f9e9",
      componentColorsComponentsIconsFeaturedIconsDarkFeaturedIconDarkFgBrand:
        "#ffffff",
      componentColorsComponentsIconsFeaturedIconsDarkFeaturedIconDarkFgError:
        "#ffffff",
      componentColorsComponentsIconsFeaturedIconsDarkFeaturedIconDarkFgGray:
        "#ffffff",
      componentColorsComponentsIconsFeaturedIconsDarkFeaturedIconDarkFgSuccess:
        "#ffffff",
      componentColorsComponentsIconsFeaturedIconsDarkFeaturedIconDarkFgWarning:
        "#ffffff",
      componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgBrand:
        "#779a19",
      componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgError:
        "#d92d20",
      componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgGray:
        "#667085",
      componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgSuccess:
        "#079455",
      componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgWarning:
        "#dc6803",
      componentColorsComponentsIconsFeaturedIconsModernFeaturedIconModernBorder:
        "#eaecf0",
      componentColorsComponentsIconsIconsIconFgBrand: "#779a19",
      componentColorsComponentsIconsIconsIconFgBrandOnBrand: "#d5e6a5",
      componentColorsComponentsIconsSocialIconsSocialIconFgAngellist: "#000000",
      componentColorsComponentsIconsSocialIconsSocialIconFgApple: "#000000",
      componentColorsComponentsIconsSocialIconsSocialIconFgGithub: "#000000",
      componentColorsComponentsIconsSocialIconsSocialIconFgInstagram: "#000100",
      componentColorsComponentsIconsSocialIconsSocialIconFgTumblr: "#001935",
      componentColorsComponentsIconsSocialIconsSocialIconFgX: "#242e36",
      componentColorsComponentsMockupsScreenMockupBorder: "#101828",
      componentColorsComponentsSlidersSliderHandleBg: "#ffffff",
      componentColorsComponentsSlidersSliderHandleBorder: "#779a19",
      componentColorsComponentsThumbnailThumbnailBadgeBrandFg: "#597413",
      componentColorsComponentsThumbnailThumbnailBadgeSuccessFg: "#067647",
      componentColorsComponentsTogglesToggleButtonFgDisabled: "#f9fafb",
      componentColorsComponentsTooltipsTooltipSupportingText: "#d0d5dd",
      componentColorsComponentsWYSIWYGEditorWysiwygEditorIconFg: "#98a2b3",
      componentColorsComponentsWYSIWYGEditorWysiwygEditorIconFgActive:
        "#667085",
      componentColorsUtilityBlueUtilityBlue100: "#d1e9ff",
      componentColorsUtilityBlueUtilityBlue200: "#b2ddff",
      componentColorsUtilityBlueUtilityBlue300: "#84caff",
      componentColorsUtilityBlueUtilityBlue400: "#53b1fd",
      componentColorsUtilityBlueUtilityBlue50: "#eff8ff",
      componentColorsUtilityBlueUtilityBlue500: "#2e90fa",
      componentColorsUtilityBlueUtilityBlue600: "#1570ef",
      componentColorsUtilityBlueUtilityBlue700: "#175cd3",
      componentColorsUtilityBlueDarkUtilityBlueDark100: "#d1e0ff",
      componentColorsUtilityBlueDarkUtilityBlueDark200: "#b2ccff",
      componentColorsUtilityBlueDarkUtilityBlueDark300: "#84adff",
      componentColorsUtilityBlueDarkUtilityBlueDark400: "#528bff",
      componentColorsUtilityBlueDarkUtilityBlueDark50: "#eff4ff",
      componentColorsUtilityBlueDarkUtilityBlueDark500: "#2970ff",
      componentColorsUtilityBlueDarkUtilityBlueDark600: "#155eef",
      componentColorsUtilityBlueDarkUtilityBlueDark700: "#004eeb",
      componentColorsUtilityBlueLightUtilityBlueLight100: "#e0f2fe",
      componentColorsUtilityBlueLightUtilityBlueLight200: "#b9e6fe",
      componentColorsUtilityBlueLightUtilityBlueLight300: "#7cd4fd",
      componentColorsUtilityBlueLightUtilityBlueLight400: "#36bffa",
      componentColorsUtilityBlueLightUtilityBlueLight50: "#f0f9ff",
      componentColorsUtilityBlueLightUtilityBlueLight500: "#0ba5ec",
      componentColorsUtilityBlueLightUtilityBlueLight600: "#0086c9",
      componentColorsUtilityBlueLightUtilityBlueLight700: "#026aa2",
      componentColorsUtilityBrandUtilityBrand100: "#eaf3d2",
      componentColorsUtilityBrandUtilityBrand100Alt: "#eaf3d2",
      componentColorsUtilityBrandUtilityBrand200: "#d5e6a5",
      componentColorsUtilityBrandUtilityBrand200Alt: "#d5e6a5",
      componentColorsUtilityBrandUtilityBrand300: "#bfda79",
      componentColorsUtilityBrandUtilityBrand300Alt: "#bfda79",
      componentColorsUtilityBrandUtilityBrand400: "#aacd4c",
      componentColorsUtilityBrandUtilityBrand400Alt: "#aacd4c",
      componentColorsUtilityBrandUtilityBrand50: "#f4f9e9",
      componentColorsUtilityBrandUtilityBrand500: "#95c11f",
      componentColorsUtilityBrandUtilityBrand500Alt: "#95c11f",
      componentColorsUtilityBrandUtilityBrand50Alt: "#f4f9e9",
      componentColorsUtilityBrandUtilityBrand600: "#779a19",
      componentColorsUtilityBrandUtilityBrand600Alt: "#779a19",
      componentColorsUtilityBrandUtilityBrand700: "#597413",
      componentColorsUtilityBrandUtilityBrand700Alt: "#597413",
      componentColorsUtilityBrandUtilityBrand800: "#3c4d0c",
      componentColorsUtilityBrandUtilityBrand800Alt: "#3c4d0c",
      componentColorsUtilityBrandUtilityBrand900: "#1e2706",
      componentColorsUtilityBrandUtilityBrand900Alt: "#1e2706",
      componentColorsUtilityErrorUtilityError100: "#fee4e2",
      componentColorsUtilityErrorUtilityError200: "#fecdca",
      componentColorsUtilityErrorUtilityError300: "#fda29b",
      componentColorsUtilityErrorUtilityError400: "#f97066",
      componentColorsUtilityErrorUtilityError50: "#fef3f2",
      componentColorsUtilityErrorUtilityError500: "#f04438",
      componentColorsUtilityErrorUtilityError600: "#d92d20",
      componentColorsUtilityErrorUtilityError700: "#b42318",
      componentColorsUtilityFuchsiaUtilityFuchsia100: "#fbe8ff",
      componentColorsUtilityFuchsiaUtilityFuchsia200: "#f6d0fe",
      componentColorsUtilityFuchsiaUtilityFuchsia300: "#eeaafd",
      componentColorsUtilityFuchsiaUtilityFuchsia400: "#e478fa",
      componentColorsUtilityFuchsiaUtilityFuchsia50: "#fdf4ff",
      componentColorsUtilityFuchsiaUtilityFuchsia500: "#d444f1",
      componentColorsUtilityFuchsiaUtilityFuchsia600: "#ba24d5",
      componentColorsUtilityFuchsiaUtilityFuchsia700: "#9f1ab1",
      componentColorsUtilityGrayUtilityGray100: "#f2f4f7",
      componentColorsUtilityGrayUtilityGray200: "#eaecf0",
      componentColorsUtilityGrayUtilityGray300: "#d0d5dd",
      componentColorsUtilityGrayUtilityGray400: "#98a2b3",
      componentColorsUtilityGrayUtilityGray50: "#f9fafb",
      componentColorsUtilityGrayUtilityGray500: "#667085",
      componentColorsUtilityGrayUtilityGray600: "#475467",
      componentColorsUtilityGrayUtilityGray700: "#344054",
      componentColorsUtilityGrayUtilityGray800: "#182230",
      componentColorsUtilityGrayUtilityGray900: "#101828",
      componentColorsUtilityGrayBlueUtilityGrayBlue100: "#eaecf5",
      componentColorsUtilityGrayBlueUtilityGrayBlue200: "#d5d9eb",
      componentColorsUtilityGrayBlueUtilityGrayBlue300: "#b3b8db",
      componentColorsUtilityGrayBlueUtilityGrayBlue400: "#717bbc",
      componentColorsUtilityGrayBlueUtilityGrayBlue50: "#f8f9fc",
      componentColorsUtilityGrayBlueUtilityGrayBlue500: "#4e5ba6",
      componentColorsUtilityGrayBlueUtilityGrayBlue600: "#3e4784",
      componentColorsUtilityGrayBlueUtilityGrayBlue700: "#363f72",
      componentColorsUtilityIndigoUtilityIndigo100: "#e0eaff",
      componentColorsUtilityIndigoUtilityIndigo200: "#c7d7fe",
      componentColorsUtilityIndigoUtilityIndigo300: "#a4bcfd",
      componentColorsUtilityIndigoUtilityIndigo400: "#8098f9",
      componentColorsUtilityIndigoUtilityIndigo50: "#eef4ff",
      componentColorsUtilityIndigoUtilityIndigo500: "#6172f3",
      componentColorsUtilityIndigoUtilityIndigo600: "#444ce7",
      componentColorsUtilityIndigoUtilityIndigo700: "#3538cd",
      componentColorsUtilityOrangeUtilityOrange100: "#fdead7",
      componentColorsUtilityOrangeUtilityOrange200: "#f9dbaf",
      componentColorsUtilityOrangeUtilityOrange300: "#f7b27a",
      componentColorsUtilityOrangeUtilityOrange400: "#f38744",
      componentColorsUtilityOrangeUtilityOrange50: "#fef6ee",
      componentColorsUtilityOrangeUtilityOrange500: "#ef6820",
      componentColorsUtilityOrangeUtilityOrange600: "#e04f16",
      componentColorsUtilityOrangeUtilityOrange700: "#b93815",
      componentColorsUtilityOrangeDarkUtilityOrangeDark100: "#ffe6d5",
      componentColorsUtilityOrangeDarkUtilityOrangeDark200: "#ffd6ae",
      componentColorsUtilityOrangeDarkUtilityOrangeDark300: "#ff9c66",
      componentColorsUtilityOrangeDarkUtilityOrangeDark400: "#ff692e",
      componentColorsUtilityOrangeDarkUtilityOrangeDark50: "#fff4ed",
      componentColorsUtilityOrangeDarkUtilityOrangeDark500: "#ff4405",
      componentColorsUtilityOrangeDarkUtilityOrangeDark600: "#e62e05",
      componentColorsUtilityOrangeDarkUtilityOrangeDark700: "#bc1b06",
      componentColorsUtilityPinkUtilityPink100: "#fce7f6",
      componentColorsUtilityPinkUtilityPink200: "#fcceee",
      componentColorsUtilityPinkUtilityPink300: "#faa7e0",
      componentColorsUtilityPinkUtilityPink400: "#f670c7",
      componentColorsUtilityPinkUtilityPink50: "#fdf2fa",
      componentColorsUtilityPinkUtilityPink500: "#ee46bc",
      componentColorsUtilityPinkUtilityPink600: "#dd2590",
      componentColorsUtilityPinkUtilityPink700: "#c11574",
      componentColorsUtilityPurpleUtilityPurple100: "#ebe9fe",
      componentColorsUtilityPurpleUtilityPurple200: "#d9d6fe",
      componentColorsUtilityPurpleUtilityPurple300: "#bdb4fe",
      componentColorsUtilityPurpleUtilityPurple400: "#9b8afb",
      componentColorsUtilityPurpleUtilityPurple50: "#f4f3ff",
      componentColorsUtilityPurpleUtilityPurple500: "#7a5af8",
      componentColorsUtilityPurpleUtilityPurple600: "#6938ef",
      componentColorsUtilityPurpleUtilityPurple700: "#5925dc",
      componentColorsUtilitySuccessUtilitySuccess100: "#e6f4d7",
      componentColorsUtilitySuccessUtilitySuccess200: "#ceeab0",
      componentColorsUtilitySuccessUtilitySuccess300: "#acdc79",
      componentColorsUtilitySuccessUtilitySuccess400: "#86cb3c",
      componentColorsUtilitySuccessUtilitySuccess50: "#f5fbee",
      componentColorsUtilitySuccessUtilitySuccess500: "#669f2a",
      componentColorsUtilitySuccessUtilitySuccess600: "#4f7a21",
      componentColorsUtilitySuccessUtilitySuccess700: "#3f621a",
      componentColorsUtilityWarningUtilityWarning100: "#fef0c7",
      componentColorsUtilityWarningUtilityWarning200: "#fedf89",
      componentColorsUtilityWarningUtilityWarning300: "#fec84b",
      componentColorsUtilityWarningUtilityWarning400: "#fdb022",
      componentColorsUtilityWarningUtilityWarning50: "#fffaeb",
      componentColorsUtilityWarningUtilityWarning500: "#f79009",
      componentColorsUtilityWarningUtilityWarning600: "#dc6803",
      componentColorsUtilityWarningUtilityWarning700: "#b54708",
    },
  },
  eSDarkMode: {
    colors: {
      colorsBackgroundBgActive: "#1f242f",
      colorsBackgroundBgBrandPrimary: "#95c11f",
      colorsBackgroundBgBrandPrimaryAlt: "#f9fafb",
      colorsBackgroundBgBrandSecondary: "#779a19",
      colorsBackgroundBgBrandSection: "#f9fafb",
      colorsBackgroundBgBrandSectionSubtle: "#ffffff",
      colorsBackgroundBgBrandSolid: "#779a19",
      colorsBackgroundBgBrandSolidHover: "#95c11f",
      colorsBackgroundBgDisabled: "#161b26",
      colorsBackgroundBgDisabledSubtle: "#0c111d",
      colorsBackgroundBgErrorPrimary: "#f04438",
      colorsBackgroundBgErrorSecondary: "#d92d20",
      colorsBackgroundBgErrorSolid: "#d92d20",
      colorsBackgroundBgOverlay: "#1f242f",
      colorsBackgroundBgPrimary: "#0c111d",
      colorsBackgroundBgPrimarySolid: "#f9fafb",
      colorsBackgroundBgPrimaryAlt: "#f9fafb",
      colorsBackgroundBgPrimaryHover: "#333741",
      colorsBackgroundBgQuaternary: "#333741",
      colorsBackgroundBgSecondary: "#1f242f",
      colorsBackgroundBgSecondarySolid: "#61646c",
      colorsBackgroundBgSecondaryAlt: "#ffffff",
      colorsBackgroundBgSecondaryHover: "#333741",
      colorsBackgroundBgSecondarySubtle: "#1f242f",
      colorsBackgroundBgSuccessPrimary: "#17b26a",
      colorsBackgroundBgSuccessSecondary: "#079455",
      colorsBackgroundBgSuccessSolid: "#079455",
      colorsBackgroundBgTertiary: "#1f242f",
      colorsBackgroundBgWarningPrimary: "#f79009",
      colorsBackgroundBgWarningSecondary: "#dc6803",
      colorsBackgroundBgWarningSolid: "#dc6803",
      colorsBorderBorderBrand: "#aacd4c",
      colorsBorderBorderBrandSolid: "#95c11f",
      colorsBorderBorderBrandSolidAlt: "#333741",
      colorsBorderBorderDisabled: "#333741",
      colorsBorderBorderDisabledSubtle: "#1f242f",
      colorsBorderBorderError: "#f97066",
      colorsBorderBorderErrorSolid: "#f04438",
      colorsBorderBorderPrimary: "#333741",
      colorsBorderBorderSecondary: "#1f242f",
      colorsBorderBorderTertiary: "#1f242f",
      colorsFocusFocusBrand: "#95c11f3d",
      colorsFocusFocusError: "#f044383d",
      colorsFocusFocusGray: "#85888e3d",
      colorsForegroundFgBrandPrimary: "#95c11f",
      colorsForegroundFgBrandPrimaryAlt: "#cecfd2",
      colorsForegroundFgBrandSecondary: "#95c11f",
      colorsForegroundFgDisabled: "#85888e",
      colorsForegroundFgDisabledSubtle: "#61646c",
      colorsForegroundFgErrorPrimary: "#f04438",
      colorsForegroundFgErrorSecondary: "#f97066",
      colorsForegroundFgPrimary: "#ffffff",
      colorsForegroundFgQuaternary: "#94969c",
      colorsForegroundFgQuaternaryHover: "#cecfd2",
      colorsForegroundFgQuinary: "#85888e",
      colorsForegroundFgQuinaryHover: "#94969c",
      colorsForegroundFgSecondary: "#cecfd2",
      colorsForegroundFgSecondaryHover: "#ececed",
      colorsForegroundFgSenary: "#61646c",
      colorsForegroundFgSuccessPrimary: "#17b26a",
      colorsForegroundFgSuccessSecondary: "#47cd89",
      colorsForegroundFgTertiary: "#94969c",
      colorsForegroundFgTertiaryHover: "#cecfd2",
      colorsForegroundFgWarningPrimary: "#f79009",
      colorsForegroundFgWarningSecondary: "#fdb022",
      colorsForegroundFgWhite: "#ffffff",
      colorsTextTextBrandPrimary: "#f5f5f6",
      colorsTextTextBrandSecondary: "#bfda79",
      colorsTextTextBrandTertiary: "#aacd4c",
      colorsTextTextBrandTertiaryAlt: "#3c4d0c",
      colorsTextTextDisabled: "#85888e",
      colorsTextTextErrorPrimary: "#f97066",
      colorsTextTextPlaceholder: "#85888e",
      colorsTextTextPlaceholderSubtle: "#333741",
      colorsTextTextPrimary: "#f5f5f6",
      colorsTextTextPrimaryOnBrand: "#f5f5f6",
      colorsTextTextQuaternary: "#94969c",
      colorsTextTextQuaternaryOnBrand: "#94969c",
      colorsTextTextSecondary: "#cecfd2",
      colorsTextTextSecondaryHover: "#ececed",
      colorsTextTextSecondaryOnBrand: "#cecfd2",
      colorsTextTextSuccessPrimary: "#47cd89",
      colorsTextTextTertiary: "#94969c",
      colorsTextTextTertiaryHover: "#cecfd2",
      colorsTextTextTertiaryOnBrand: "#94969c",
      colorsTextTextWarningPrimary: "#fdb022",
      colorsTextTextWhite: "#ffffff",
      componentColorsAlphaAlphaBlack10: "#ffffff1a",
      componentColorsAlphaAlphaBlack100: "#ffffff",
      componentColorsAlphaAlphaBlack20: "#ffffff33",
      componentColorsAlphaAlphaBlack30: "#ffffff4d",
      componentColorsAlphaAlphaBlack40: "#ffffff66",
      componentColorsAlphaAlphaBlack50: "#ffffff80",
      componentColorsAlphaAlphaBlack60: "#ffffff99",
      componentColorsAlphaAlphaBlack70: "#ffffffb2",
      componentColorsAlphaAlphaBlack80: "#ffffffcc",
      componentColorsAlphaAlphaBlack90: "#ffffffe5",
      componentColorsAlphaAlphaWhite10: "#0c111d1a",
      componentColorsAlphaAlphaWhite100: "#0c111d",
      componentColorsAlphaAlphaWhite20: "#0c111d33",
      componentColorsAlphaAlphaWhite30: "#0c111d4d",
      componentColorsAlphaAlphaWhite40: "#0c111d66",
      componentColorsAlphaAlphaWhite50: "#0c111d80",
      componentColorsAlphaAlphaWhite60: "#0c111d99",
      componentColorsAlphaAlphaWhite70: "#0c111db2",
      componentColorsAlphaAlphaWhite80: "#0c111dcc",
      componentColorsAlphaAlphaWhite90: "#0c111de5",
      componentColorsComponentsAppStoreBadgesAppStoreBadgeBorder: "#ffffff",
      componentColorsComponentsApplicationNavigationNavItemButtonIconFg:
        "#94969c",
      componentColorsComponentsApplicationNavigationNavItemButtonIconFgActive:
        "#ececed",
      componentColorsComponentsApplicationNavigationNavItemIconFg: "#94969c",
      componentColorsComponentsApplicationNavigationNavItemIconFgActive:
        "#ececed",
      componentColorsComponentsAvatarsAvatarBg: "#1f242f",
      componentColorsComponentsAvatarsAvatarContrastBorder: "#ffffff1f",
      componentColorsComponentsAvatarsAvatarFocusBorder: "#98a2b324",
      componentColorsComponentsAvatarsAvatarProfilePhotoBorder: "#0c111d",
      componentColorsComponentsBreadcrumbsBreadcrumbBgHover: "#1f242f",
      componentColorsComponentsBreadcrumbsBreadcrumbBrandBgHover: "#1f242f",
      componentColorsComponentsBreadcrumbsBreadcrumbBrandFgHover: "#ffffff",
      componentColorsComponentsBreadcrumbsBreadcrumbBrandIconFgHover: "#ffffff",
      componentColorsComponentsBreadcrumbsBreadcrumbFg: "#cecfd2",
      componentColorsComponentsBreadcrumbsBreadcrumbFgHover: "#ffffff",
      componentColorsComponentsBreadcrumbsBreadcrumbIconFg: "#94969c",
      componentColorsComponentsBreadcrumbsBreadcrumbIconFgHover: "#ffffff",
      componentColorsComponentsButtonsPrimaryButtonPrimaryBg: "#779a19",
      componentColorsComponentsButtonsPrimaryButtonPrimaryBgHover: "#597413",
      componentColorsComponentsButtonsPrimaryButtonPrimaryBorder: "#779a19",
      componentColorsComponentsButtonsPrimaryButtonPrimaryBorderHover:
        "#597413",
      componentColorsComponentsButtonsPrimaryButtonPrimaryFg: "#ffffff",
      componentColorsComponentsButtonsPrimaryButtonPrimaryFgHover: "#ffffff",
      componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorBg:
        "#d92d20",
      componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorBgHover:
        "#b42318",
      componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorBorder:
        "#d92d20",
      componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorBorderHover:
        "#b42318",
      componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorFg:
        "#ffffff",
      componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorFgHover:
        "#ffffff",
      componentColorsComponentsButtonsSecondaryButtonSecondaryBg: "#161b26",
      componentColorsComponentsButtonsSecondaryButtonSecondaryBgHover:
        "#1f242f",
      componentColorsComponentsButtonsSecondaryButtonSecondaryBorder: "#333741",
      componentColorsComponentsButtonsSecondaryButtonSecondaryBorderHover:
        "#333741",
      componentColorsComponentsButtonsSecondaryButtonSecondaryFg: "#cecfd2",
      componentColorsComponentsButtonsSecondaryButtonSecondaryFgHover:
        "#f0f1f1",
      componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBg:
        "#1e2706",
      componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBgHover:
        "#3c4d0c",
      componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBorder:
        "#597413",
      componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBorderHover:
        "#779a19",
      componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg:
        "#bfda79",
      componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFgHover:
        "#eaf3d2",
      componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorBg:
        "#55160c",
      componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorBgHover:
        "#7a271a",
      componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorBorder:
        "#912018",
      componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorBorderHover:
        "#b42318",
      componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorFg:
        "#fecdca",
      componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorFgHover:
        "#fee4e2",
      componentColorsComponentsButtonsTertiaryButtonTertiaryBgHover: "#1f242f",
      componentColorsComponentsButtonsTertiaryButtonTertiaryFg: "#94969c",
      componentColorsComponentsButtonsTertiaryButtonTertiaryFgHover: "#ececed",
      componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorBgHover:
        "#3c4d0c",
      componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFg:
        "#bfda79",
      componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFgHover:
        "#eaf3d2",
      componentColorsComponentsButtonsTertiaryErrorButtonTertiaryErrorBgHover:
        "#7a271a",
      componentColorsComponentsButtonsTertiaryErrorButtonTertiaryErrorFg:
        "#fda29b",
      componentColorsComponentsButtonsTertiaryErrorButtonTertiaryErrorFgHover:
        "#fecdca",
      componentColorsComponentsFootersFooterBadgeBg: "#ffffff1a",
      componentColorsComponentsFootersFooterBadgeBorder: "#ffffff4d",
      componentColorsComponentsFootersFooterBadgeFg: "#ffffff",
      componentColorsComponentsFootersFooterButtonFg: "#cecfd2",
      componentColorsComponentsFootersFooterButtonFgHover: "#f0f1f1",
      componentColorsComponentsHeaderSectionsHeaderAbstract100Bg: "#1f242f",
      componentColorsComponentsHeaderSectionsHeaderAbstract200Bg: "#333741",
      componentColorsComponentsHeaderSectionsHeaderAbstract300Bg: "#61646c",
      componentColorsComponentsHeaderSectionsHeaderAbstract50Bg: "#161b26",
      componentColorsComponentsIconsFeaturedIconsDarkFeaturedIconDarkFgBrand:
        "#d5e6a5",
      componentColorsComponentsIconsFeaturedIconsDarkFeaturedIconDarkFgError:
        "#fecdca",
      componentColorsComponentsIconsFeaturedIconsDarkFeaturedIconDarkFgGray:
        "#ececed",
      componentColorsComponentsIconsFeaturedIconsDarkFeaturedIconDarkFgSuccess:
        "#abefc6",
      componentColorsComponentsIconsFeaturedIconsDarkFeaturedIconDarkFgWarning:
        "#fedf89",
      componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgBrand:
        "#d5e6a5",
      componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgError:
        "#fecdca",
      componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgGray:
        "#ececed",
      componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgSuccess:
        "#abefc6",
      componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgWarning:
        "#fedf89",
      componentColorsComponentsIconsFeaturedIconsModernFeaturedIconModernBorder:
        "#333741",
      componentColorsComponentsIconsIconsIconFgBrand: "#94969c",
      componentColorsComponentsIconsIconsIconFgBrandOnBrand: "#94969c",
      componentColorsComponentsIconsSocialIconsSocialIconFgAngellist: "#ffffff",
      componentColorsComponentsIconsSocialIconsSocialIconFgApple: "#ffffff",
      componentColorsComponentsIconsSocialIconsSocialIconFgGithub: "#ffffff",
      componentColorsComponentsIconsSocialIconsSocialIconFgInstagram: "#ffffff",
      componentColorsComponentsIconsSocialIconsSocialIconFgTumblr: "#ffffff",
      componentColorsComponentsIconsSocialIconsSocialIconFgX: "#ffffff",
      componentColorsComponentsMockupsScreenMockupBorder: "#333741",
      componentColorsComponentsSlidersSliderHandleBg: "#779a19",
      componentColorsComponentsSlidersSliderHandleBorder: "#ffffff",
      componentColorsComponentsThumbnailThumbnailBadgeBrandFg: "#cecfd2",
      componentColorsComponentsThumbnailThumbnailBadgeSuccessFg: "#cecfd2",
      componentColorsComponentsTogglesToggleButtonFgDisabled: "#61646c",
      componentColorsComponentsTooltipsTooltipSupportingText: "#cecfd2",
      componentColorsComponentsWYSIWYGEditorWysiwygEditorIconFg: "#94969c",
      componentColorsComponentsWYSIWYGEditorWysiwygEditorIconFgActive:
        "#ffffff",
      componentColorsUtilityBlueUtilityBlue100: "#194185",
      componentColorsUtilityBlueUtilityBlue200: "#1849a9",
      componentColorsUtilityBlueUtilityBlue300: "#175cd3",
      componentColorsUtilityBlueUtilityBlue400: "#1570ef",
      componentColorsUtilityBlueUtilityBlue50: "#102a56",
      componentColorsUtilityBlueUtilityBlue500: "#2e90fa",
      componentColorsUtilityBlueUtilityBlue600: "#53b1fd",
      componentColorsUtilityBlueUtilityBlue700: "#84caff",
      componentColorsUtilityBlueDarkUtilityBlueDark100: "#00359e",
      componentColorsUtilityBlueDarkUtilityBlueDark200: "#0040c1",
      componentColorsUtilityBlueDarkUtilityBlueDark300: "#004eeb",
      componentColorsUtilityBlueDarkUtilityBlueDark400: "#155eef",
      componentColorsUtilityBlueDarkUtilityBlueDark50: "#002266",
      componentColorsUtilityBlueDarkUtilityBlueDark500: "#2970ff",
      componentColorsUtilityBlueDarkUtilityBlueDark600: "#528bff",
      componentColorsUtilityBlueDarkUtilityBlueDark700: "#84adff",
      componentColorsUtilityBlueLightUtilityBlueLight100: "#0b4a6f",
      componentColorsUtilityBlueLightUtilityBlueLight200: "#065986",
      componentColorsUtilityBlueLightUtilityBlueLight300: "#026aa2",
      componentColorsUtilityBlueLightUtilityBlueLight400: "#0086c9",
      componentColorsUtilityBlueLightUtilityBlueLight50: "#062c41",
      componentColorsUtilityBlueLightUtilityBlueLight500: "#0ba5ec",
      componentColorsUtilityBlueLightUtilityBlueLight600: "#36bffa",
      componentColorsUtilityBlueLightUtilityBlueLight700: "#7cd4fd",
      componentColorsUtilityBrandUtilityBrand100: "#1e2706",
      componentColorsUtilityBrandUtilityBrand100Alt: "#f2f4f7",
      componentColorsUtilityBrandUtilityBrand200: "#3c4d0c",
      componentColorsUtilityBrandUtilityBrand200Alt: "#eaecf0",
      componentColorsUtilityBrandUtilityBrand300: "#597413",
      componentColorsUtilityBrandUtilityBrand300Alt: "#d0d5dd",
      componentColorsUtilityBrandUtilityBrand400: "#779a19",
      componentColorsUtilityBrandUtilityBrand400Alt: "#98a2b3",
      componentColorsUtilityBrandUtilityBrand50: "#0f1303",
      componentColorsUtilityBrandUtilityBrand500: "#95c11f",
      componentColorsUtilityBrandUtilityBrand500Alt: "#667085",
      componentColorsUtilityBrandUtilityBrand50Alt: "#f9fafb",
      componentColorsUtilityBrandUtilityBrand600: "#aacd4c",
      componentColorsUtilityBrandUtilityBrand600Alt: "#475467",
      componentColorsUtilityBrandUtilityBrand700: "#bfda79",
      componentColorsUtilityBrandUtilityBrand700Alt: "#344054",
      componentColorsUtilityBrandUtilityBrand800: "#d5e6a5",
      componentColorsUtilityBrandUtilityBrand800Alt: "#182230",
      componentColorsUtilityBrandUtilityBrand900: "#eaf3d2",
      componentColorsUtilityBrandUtilityBrand900Alt: "#101828",
      componentColorsUtilityErrorUtilityError100: "#7a271a",
      componentColorsUtilityErrorUtilityError200: "#912018",
      componentColorsUtilityErrorUtilityError300: "#b42318",
      componentColorsUtilityErrorUtilityError400: "#d92d20",
      componentColorsUtilityErrorUtilityError50: "#55160c",
      componentColorsUtilityErrorUtilityError500: "#f04438",
      componentColorsUtilityErrorUtilityError600: "#f97066",
      componentColorsUtilityErrorUtilityError700: "#fda29b",
      componentColorsUtilityFuchsiaUtilityFuchsia100: "#6f1877",
      componentColorsUtilityFuchsiaUtilityFuchsia200: "#821890",
      componentColorsUtilityFuchsiaUtilityFuchsia300: "#9f1ab1",
      componentColorsUtilityFuchsiaUtilityFuchsia400: "#ba24d5",
      componentColorsUtilityFuchsiaUtilityFuchsia50: "#47104c",
      componentColorsUtilityFuchsiaUtilityFuchsia500: "#d444f1",
      componentColorsUtilityFuchsiaUtilityFuchsia600: "#e478fa",
      componentColorsUtilityFuchsiaUtilityFuchsia700: "#eeaafd",
      componentColorsUtilityGrayUtilityGray100: "#1f242f",
      componentColorsUtilityGrayUtilityGray200: "#333741",
      componentColorsUtilityGrayUtilityGray300: "#333741",
      componentColorsUtilityGrayUtilityGray400: "#61646c",
      componentColorsUtilityGrayUtilityGray50: "#161b26",
      componentColorsUtilityGrayUtilityGray500: "#85888e",
      componentColorsUtilityGrayUtilityGray600: "#94969c",
      componentColorsUtilityGrayUtilityGray700: "#cecfd2",
      componentColorsUtilityGrayUtilityGray800: "#ececed",
      componentColorsUtilityGrayUtilityGray900: "#f0f1f1",
      componentColorsUtilityGrayBlueUtilityGrayBlue100: "#101323",
      componentColorsUtilityGrayBlueUtilityGrayBlue200: "#293056",
      componentColorsUtilityGrayBlueUtilityGrayBlue300: "#363f72",
      componentColorsUtilityGrayBlueUtilityGrayBlue400: "#3e4784",
      componentColorsUtilityGrayBlueUtilityGrayBlue50: "#101323",
      componentColorsUtilityGrayBlueUtilityGrayBlue500: "#4e5ba6",
      componentColorsUtilityGrayBlueUtilityGrayBlue600: "#717bbc",
      componentColorsUtilityGrayBlueUtilityGrayBlue700: "#b3b8db",
      componentColorsUtilityIndigoUtilityIndigo100: "#2d3282",
      componentColorsUtilityIndigoUtilityIndigo200: "#2d31a6",
      componentColorsUtilityIndigoUtilityIndigo300: "#3538cd",
      componentColorsUtilityIndigoUtilityIndigo400: "#444ce7",
      componentColorsUtilityIndigoUtilityIndigo50: "#1f235b",
      componentColorsUtilityIndigoUtilityIndigo500: "#6172f3",
      componentColorsUtilityIndigoUtilityIndigo600: "#8098f9",
      componentColorsUtilityIndigoUtilityIndigo700: "#a4bcfd",
      componentColorsUtilityOrangeUtilityOrange100: "#772917",
      componentColorsUtilityOrangeUtilityOrange200: "#932f19",
      componentColorsUtilityOrangeUtilityOrange300: "#b93815",
      componentColorsUtilityOrangeUtilityOrange400: "#e04f16",
      componentColorsUtilityOrangeUtilityOrange50: "#511c10",
      componentColorsUtilityOrangeUtilityOrange500: "#ef6820",
      componentColorsUtilityOrangeUtilityOrange600: "#f38744",
      componentColorsUtilityOrangeUtilityOrange700: "#f7b27a",
      componentColorsUtilityOrangeDarkUtilityOrangeDark100: "#771a0d",
      componentColorsUtilityOrangeDarkUtilityOrangeDark200: "#97180c",
      componentColorsUtilityOrangeDarkUtilityOrangeDark300: "#bc1b06",
      componentColorsUtilityOrangeDarkUtilityOrangeDark400: "#e62e05",
      componentColorsUtilityOrangeDarkUtilityOrangeDark50: "#57130a",
      componentColorsUtilityOrangeDarkUtilityOrangeDark500: "#ff4405",
      componentColorsUtilityOrangeDarkUtilityOrangeDark600: "#ff692e",
      componentColorsUtilityOrangeDarkUtilityOrangeDark700: "#ff9c66",
      componentColorsUtilityPinkUtilityPink100: "#851651",
      componentColorsUtilityPinkUtilityPink200: "#9e165f",
      componentColorsUtilityPinkUtilityPink300: "#c11574",
      componentColorsUtilityPinkUtilityPink400: "#dd2590",
      componentColorsUtilityPinkUtilityPink50: "#4e0d30",
      componentColorsUtilityPinkUtilityPink500: "#ee46bc",
      componentColorsUtilityPinkUtilityPink600: "#f670c7",
      componentColorsUtilityPinkUtilityPink700: "#faa7e0",
      componentColorsUtilityPurpleUtilityPurple100: "#3e1c96",
      componentColorsUtilityPurpleUtilityPurple200: "#4a1fb8",
      componentColorsUtilityPurpleUtilityPurple300: "#5925dc",
      componentColorsUtilityPurpleUtilityPurple400: "#6938ef",
      componentColorsUtilityPurpleUtilityPurple50: "#27115f",
      componentColorsUtilityPurpleUtilityPurple500: "#7a5af8",
      componentColorsUtilityPurpleUtilityPurple600: "#9b8afb",
      componentColorsUtilityPurpleUtilityPurple700: "#bdb4fe",
      componentColorsUtilitySuccessUtilitySuccess100: "#2b4212",
      componentColorsUtilitySuccessUtilitySuccess200: "#335015",
      componentColorsUtilitySuccessUtilitySuccess300: "#3f621a",
      componentColorsUtilitySuccessUtilitySuccess400: "#4f7a21",
      componentColorsUtilitySuccessUtilitySuccess50: "#1a280b",
      componentColorsUtilitySuccessUtilitySuccess500: "#669f2a",
      componentColorsUtilitySuccessUtilitySuccess600: "#86cb3c",
      componentColorsUtilitySuccessUtilitySuccess700: "#acdc79",
      componentColorsUtilityWarningUtilityWarning100: "#7a2e0e",
      componentColorsUtilityWarningUtilityWarning200: "#93370d",
      componentColorsUtilityWarningUtilityWarning300: "#b54708",
      componentColorsUtilityWarningUtilityWarning400: "#dc6803",
      componentColorsUtilityWarningUtilityWarning50: "#4e1d09",
      componentColorsUtilityWarningUtilityWarning500: "#f79009",
      componentColorsUtilityWarningUtilityWarning600: "#fdb022",
      componentColorsUtilityWarningUtilityWarning700: "#fec84b",
    },
  },
};
