import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  SxProps,
  Typography,
} from "@mui/material";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { Trash01 } from "untitledui-js-base";
import useTheme from "../../theme/hooks/useTheme";
import { Button, ButtonType } from "../Button/Button";

export interface IConfirmationModalProps {
  title?: string;
  text: string;
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
  confirmButtonText: string;
  confirmButtonStyles: SxProps;
  icon?: ReactElement;
}

export default function ConfirmationModal({
  title,
  text,
  isOpen,
  onOk,
  onCancel,
  confirmButtonText,
  icon,
}: IConfirmationModalProps) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");
  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      sx={{ "& .MuiPaper-root": { maxWidth: "400px" } }}
    >
      <DialogTitle
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          marginBottom: theme.spacing.spacing4xl,
          padding: `${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl} 0`,
        }}
      >
        <Box
          sx={{
            width: "48px",
            height: "48px",
            borderRadius: "50%",
            backgroundColor: theme.colors.colorsBackgroundBgErrorPrimary,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: theme.spacing.spacingXl,
          }}
        >
          {icon ? (
            icon
          ) : (
            <Trash01
              size="24"
              style={{
                color: theme.colors.colorsBackgroundBgErrorSolid,
              }}
              strokeWidth={2}
            />
          )}
        </Box>
        {title && (
          <Typography sx={{ ...theme.typography[".text-lg-semibold"] }}>
            {title}
          </Typography>
        )}
        <Typography fontSize={14} color={theme.colors.colorsTextTextTertiary}>
          {text}
        </Typography>
      </DialogTitle>
      <DialogActions
        sx={{
          padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
          justifyContent: "space-between",
        }}
      >
        <Button
          label={`${t("actions.cancel")}`}
          onClick={onCancel}
          buttonType={ButtonType.SecondaryGray}
          sx={{
            width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
          }}
        />
        <Button
          label={confirmButtonText}
          onClick={onOk}
          buttonType={ButtonType.ErrorPrimary}
          sx={{
            ml: "0 !important",
            width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
