import { Box, Typography } from "@mui/material";
import React from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";
import { XClose } from "untitledui-js-base";

export interface IFilter {
  label: string;
  value: any;
  icon?: any;
  selected: boolean;
  canNotBeCleared?: boolean;
}

export interface IFiltersProps {
  filters: Array<IFilter>;
  onFilterClick: (value: any) => void;
}

export default function Filters({ filters, onFilterClick }: IFiltersProps) {
  const theme = useTheme();
  const { colors, typography, spacing } = theme;
  return (
    <Box
      sx={{
        display: "flex",
        border: `1px solid ${colors.colorsBorderBorderPrimary}`,
        borderRadius: "8px",
        maxWidth: "fit-content",
      }}
    >
      {filters.map((filter, index) => (
        <Box
          key={filter.label}
          component={"button"}
          sx={{
            ...typography[".text-sm-semibold"],
            minHeight: "40px",
            display: "flex",
            alignItems: "center",
            border: "none", 
            padding: `${spacing.spacingMd} ${spacing.spacingXl}`,
            borderRadius:
              index === 0
                ? "8px 0 0 8px"
                : index === filters.length - 1
                ? "0 8px 8px 0"
                : "0",
            borderRight:
              index !== filters.length - 1
                ? `1px solid ${colors.colorsBorderBorderPrimary}`
                : "none",
            cursor: "pointer",
            backgroundColor: filter.selected
              ? colors.colorsBackgroundBgActive
              : "initial",
            "&:hover": {
              backgroundColor: colors.colorsBackgroundBgActive,
            },
          }}
          onClick={() => onFilterClick(filter.value)}
        >
          {filter.icon && (
            <Box
              sx={{
                color: filter.selected
                  ? colors.colorsTextTextBrandTertiary
                  : colors.colorsTextTextSecondary,
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                mr: spacing.spacingSm,
              }}
            >
              {filter.icon}
            </Box>
          )}
          <Typography
            sx={{
              ...typography[".text-sm-semibold"],
              color: filter.selected
                ? colors.colorsTextTextBrandTertiary
                : colors.colorsTextTextSecondary,
            }}
          >
            {filter.label}
          </Typography>
          {/* {filter.selected && !filter.canNotBeCleared && (
            <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
              <XClose
                size="20"
                style={{
                  color: colors.colorsBorderBorderPrimary,
                  marginLeft: spacing.spacingSm,
                }}
                strokeWidth={2}
              />
            </Box>
          )} */}
        </Box>
      ))}
    </Box>
  );
}
