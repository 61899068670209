export enum AvailableValuesForParametersDropdown {
  operationStatus = "operationStatus",
  currentRoomTemperatureZ1 = "currentRoomTemperatureZ1",
  currentRoomTemperatureZ2 = "currentRoomTemperatureZ2",
  outdoorTemperature = "outdoorTemperature",
  //Cooling/Heating water temperature THC //NO PARAMETER
  dhwTankTemperatureTWD = "dhwTankTemperatureTWD",
  tv1Temperature = "tv1Temperature",
  tv2Temperature = "tv2Temperature",
  condenserOutletWaterTemperatureTUO = "condenserOutletWaterTemperatureTUO",
  currentSeason = "currentSeason",
  condenserInletWaterTemperatureTUI = "condenserInletWaterTemperatureTUI",
  compressorSpeed = "compressorSpeed",
  requestedCompressorSpeed = "requestedCompressorSpeed",
  defrostMode = "defrostMode",
  //Water pump P0 status //NO PARAMETER
  //Water flow rate //NO PARAMETER
  waterPumpP1Status = "waterPumpP1Status",
  waterPumpP2Status = "waterPumpP2Status",
  //Anti-legionella (current state) //NO PARAMETER
  quiteMode = "quiteMode",
  highPressurePd = "highPressurePd",
  compressorDischargeTemperatureTD = "compressorDischargeTemperatureTD",
  lowPressurePs = "lowPressurePs",
  compressorSuctionTemperatureTS = "compressorSuctionTemperatureTS",
  condensingTemperatureTUP = "condensingTemperatureTUP",
  evaporatingTemperatureTP = "evaporatingTemperatureTP",
  eevOpeningP = "eevOpeningP",
  fanSpeed1 = "fanSpeed1",
  fanSpeed2 = "fanSpeed2",
  //Changelog //NO PARAMETER
  //Additional inline heating source status // NO PARAMETER
  //Additional DHW heating source status // NO PARAMETER
  //Additional buffer tank heating source status // NO PARAMETER

  //ON THE DESIGN ITS ONLY ONE VALUE FOR THIS PARAMS
  outdoorUnitElectricityCurrent1 = "outdoorUnitElectricityCurrent1",
  outdoorUnitElectricityCurrent2 = "outdoorUnitElectricityCurrent2",
  outdoorUnitElectricityCurrent3 = "outdoorUnitElectricityCurrent3",
  outdoorUnitVoltage1 = "outdoorUnitVoltage1",
  outdoorUnitVoltage2 = "outdoorUnitVoltage2",
  outdoorUnitVoltage3 = "outdoorUnitVoltage3",
}
