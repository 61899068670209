import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { installationApi } from "../services/installation";

export interface InstallationState {
  inProgressPairDevice: boolean;
}

const initialState: InstallationState = {
  inProgressPairDevice: false,
};

export const installationSlice = createSlice({
  name: "installation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

// Action creators are generated for each case reducer function
// export const { logout, clearLoginError, clearSignUpError, updateToken } = installationSlice.actions;
export const selectIsProgressPairing = (state: RootState) =>
  state.installationSlice.inProgressPairDevice;

export default installationSlice.reducer;
