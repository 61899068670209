import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { OrganizationTypes } from "../../api/OrganizationsAPI";

export interface IUserOrgInfo {
  uuid: string;
  parentUuid: string | null;
  name: string;
  country: string;
  address: string;
  phone: string | null;
  loggameraAccess: boolean;
  type: OrganizationTypes;
}

export interface OrganizationsState {
  userOrganizationInfo: IUserOrgInfo | undefined;
}

const initialState: OrganizationsState = {
  userOrganizationInfo: undefined,
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    setUserOrganizationInfo: (state, action) => {
      state.userOrganizationInfo = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

// Action creators are generated for each case reducer function
export const { setUserOrganizationInfo } = organizationSlice.actions;

export const selectUserOrganizationInfo = (state: RootState) =>
  state.organizationSlice.userOrganizationInfo;

export default organizationSlice.reducer;
