import { Box, Typography } from "@mui/material";
import React from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";

export default function MainTitle({ title }: { title: string }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        borderBottom: `1px solid ${theme.colors.colorsBorderBorderSecondary}`,
      }}
    >
      <Typography
        sx={{
          ...theme.typography[".text-lg-semibold"],
          color: theme.colors.colorsTextTextPrimary,
          mb: theme.spacing.spacingXl,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}
