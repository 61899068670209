import {
  Alert,
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  InputLabel,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import useTheme from "../../../theme/hooks/useTheme";
import { Button } from "../../../components/Button/Button";
import AddressAutocompleteComponent, {
  AddressFormats,
} from "../../../components/AddressAutocompleteComponent/AddressAutocompleteComponent";
import { Eye, EyeOff } from "untitledui-js-base";
import {
  useAcceptOrganizationInvitationMutation,
  useGetOrganizationInvitationInfoQuery,
} from "../../../store/services/organizations";
import UnlinkIcon from "../../../components/icons/UnlinkIcon";

type AcceptNewOrganizationProps = {
  token?: string;
  onSuccess: () => void;
};

export default function AcceptNewOrganization({
  token,
  onSuccess,
}: AcceptNewOrganizationProps) {
  const theme = useTheme();
  const TextInputStyle = {
    margin: `0 0 ${theme.spacing.spacing2xl}`,
    "& .MuiInputBase-root": {
      height: 44,
      padding: "0 14px",
      borderRadius: theme.radius.radiusMd,
      input: {
        padding: 0,
        ":placeholder-shown": {
          color: theme.colors.colorsTextTextPlaceholder,
        },
      },
    },
    "& .MuiFormHelperText-root": {
      marginLeft: 0,
    },
  };

  const { t } = useTranslation("cloud_ui", {
    keyPrefix: "pages.company_organizations",
  });
  const { t: tErrors } = useTranslation("cloud_ui", { keyPrefix: "errors" });

  const [orgIsPrepared, setOrgIsPrepared] = useState(false);
  const [orgName, setOrgName] = useState("");
  const [orgPhone, setOrgPhone] = useState("");
  const [orgAddress, setOrgAddress] = useState("");
  const [country, setCountry] = useState("");
  const [userName, setUserName] = useState("");
  const [userSurname, setUserSurname] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsAgreement, signTermsAgreement] = useState(false);
  const [securePass, setSecurePass] = useState(true);

  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
    general: "",
  });

  const onSelectAddress = (address: string, country?: string) => {
    setOrgAddress(address);
    if (country) setCountry(country);
  };

  const [acceptOrganizationInvitation, { isLoading: isLoadingAccept }] =
    useAcceptOrganizationInvitationMutation();
  const { data: invitationInfo, isError } =
    useGetOrganizationInvitationInfoQuery({
      token: token || "",
    });

  const renderShowPassBtn = () => {
    if (securePass) {
      return (
        <EyeOff
          size="13"
          onClick={() => setSecurePass(false)}
          color={theme.colors.colorsForegroundFgQuinary}
          style={{ cursor: "pointer" }}
        />
      );
    } else {
      return (
        <Eye
          size="13"
          onClick={() => setSecurePass(true)}
          color={theme.colors.colorsForegroundFgQuinary}
          style={{ cursor: "pointer" }}
        />
      );
    }
  };

  const validatePassword = (password: string) => {
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
    return passwordRegex.test(password);
  };

  const onChangePassword = (e: any) => {
    const value = e.target.value;
    setPassword(value);
    if (!validatePassword(value) && value.length) {
      setErrors({
        ...errors,
        password: t("accept_new_organization.fields.errors.password.pattern"),
      });
    } else {
      setErrors({ ...errors, password: "" });
    }
  };

  const onChangeConfirmPassword = (e: any) => {
    const value = e.target.value;
    setConfirmPassword(value);
    if (value.length && password !== value) {
      setErrors({
        ...errors,
        confirmPassword: t(
          "accept_new_organization.fields.errors.confirm_password"
        ),
      });
    } else {
      setErrors({ ...errors, confirmPassword: "" });
    }
  };

  const acceptInvitation = () => {
    setErrors({ ...errors, general: "" });
    if (token) {
      acceptOrganizationInvitation({
        token: token!,
        orgCountry: country,
        orgAddress: orgAddress,
        orgName: orgName,
        userFirstName: userName,
        userLastName: userSurname,
        userPassword: password,
        orgPhoneNumber: orgPhone,
        userPhoneNumber: userPhone,
      })
        .unwrap()
        .then(() => {
          onSuccess();
        })
        .catch(() =>
          setErrors({ ...errors, general: tErrors("somethingWentWrong") })
        );
    } else {
      setErrors({ ...errors, general: tErrors("noToken") });
    }
  };

  return (
    <>
      <Typography
        sx={{
          ...theme.typography[".display-sm-semibold"],
          textAlign: "center",
          paddingBottom: theme.spacing.spacing3xl,
        }}
      >
        {!orgIsPrepared
          ? t("accept_new_organization.title")
          : t("accept_new_organization.title_user")}
      </Typography>

      <Paper
        className="paper-form"
        sx={{
          padding: `${theme.spacing.spacing4xl} !important`,
          width: "100%",
        }}
      >
        {isError ? (
          <Container sx={{ marginTop: "1em;", textAlign: "center" }}>
            <UnlinkIcon style={{ fontSize: "5em", marginBottom: "20px" }} />
            <Typography variant={"h5"} color="primary.dark">
              <b>There was a problem with the link</b>
            </Typography>
            <Typography color={"primary"} margin={3}>
              Either the link has expired or wasn't copied correctly.
              <br />
              please try again or ask the administrator to send a new
              invitation.
            </Typography>
          </Container>
        ) : !orgIsPrepared ? (
          <>
            <Box>
              <InputLabel
                shrink
                sx={{
                  color: theme.colors.colorsTextTextSecondary,
                  fontWeight: 500,
                }}
              >
                {t("accept_new_organization.fields.labels.org_name")}
              </InputLabel>
              <TextField
                margin="normal"
                required
                fullWidth
                id="orgName"
                autoComplete="off"
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)}
                autoFocus
                placeholder={`${t(
                  "accept_new_organization.fields.placeholders.org_name"
                )}`}
                sx={TextInputStyle}
              />
            </Box>
            <Box>
              <InputLabel
                shrink
                sx={{
                  color: theme.colors.colorsTextTextSecondary,
                  fontWeight: 500,
                }}
              >
                {t("accept_new_organization.fields.labels.org_type")}
              </InputLabel>
              <TextField
                margin="normal"
                required
                fullWidth
                id="orgType"
                autoComplete="off"
                value={
                  invitationInfo?.invitedOrgType
                    ? t(
                        `invite_new_organization.${invitationInfo?.invitedOrgType}`
                      )
                    : ""
                }
                placeholder={`${t(
                  "accept_new_organization.fields.labels.org_type"
                )}`}
                sx={{
                  ...TextInputStyle,
                  backgroundColor:
                    theme.colors.colorsBackgroundBgDisabledSubtle,
                }}
                disabled
              />
            </Box>
            <Box>
              <InputLabel
                shrink
                sx={{
                  color: theme.colors.colorsTextTextSecondary,
                  fontWeight: 500,
                }}
              >
                {t("accept_new_organization.fields.labels.phone")}
              </InputLabel>
              <TextField
                margin="normal"
                required
                fullWidth
                id="orgPhone"
                autoComplete="off"
                type="tel"
                value={orgPhone}
                onChange={(e) => setOrgPhone(e.target.value)}
                autoFocus
                placeholder={`${t(
                  "accept_new_organization.fields.placeholders.phone"
                )}`}
                sx={TextInputStyle}
              />
            </Box>
            <Box sx={{ paddingBottom: theme.spacing.spacing3xl }}>
              <InputLabel
                shrink
                sx={{
                  color: theme.colors.colorsTextTextSecondary,
                  fontWeight: 500,
                }}
              >
                {t("accept_new_organization.fields.labels.address")}
              </InputLabel>
              <AddressAutocompleteComponent
                onAddressSelect={onSelectAddress}
                address={orgAddress}
                placeholder={`${t(
                  "accept_new_organization.fields.placeholders.address"
                )}`}
                addressFormat={AddressFormats.FullAddress}
              />
            </Box>
            <Box
              sx={{
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <Button
                label={`${t("accept_new_organization.submitButton")}`}
                disabled={!orgName || !orgPhone || !orgAddress}
                onClick={() => setOrgIsPrepared(true)}
                sx={{
                  ml: "0 !important",
                  width: "100%",
                }}
              />
            </Box>
          </>
        ) : (
          <>
            {errors.general && (
              <Alert
                severity="error"
                sx={{ marginBottom: theme.spacing.spacing3xl }}
              >
                {errors.general}
              </Alert>
            )}
            <Box>
              <InputLabel
                shrink
                sx={{
                  color: theme.colors.colorsTextTextSecondary,
                  fontWeight: 500,
                }}
              >
                {t("accept_new_organization.fields.labels.user_name")}
              </InputLabel>
              <TextField
                margin="normal"
                required
                fullWidth
                id="userName"
                autoComplete="off"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                autoFocus
                placeholder={`${t(
                  "accept_new_organization.fields.placeholders.user_name"
                )}`}
                sx={TextInputStyle}
              />
            </Box>
            <Box>
              <InputLabel
                shrink
                sx={{
                  color: theme.colors.colorsTextTextSecondary,
                  fontWeight: 500,
                }}
              >
                {t("accept_new_organization.fields.labels.user_surname")}
              </InputLabel>
              <TextField
                margin="normal"
                required
                fullWidth
                id="userSurname"
                autoComplete="off"
                value={userSurname}
                onChange={(e) => setUserSurname(e.target.value)}
                autoFocus
                placeholder={`${t(
                  "accept_new_organization.fields.placeholders.user_surname"
                )}`}
                sx={TextInputStyle}
              />
            </Box>
            <Box>
              <InputLabel
                shrink
                sx={{
                  color: theme.colors.colorsTextTextSecondary,
                  fontWeight: 500,
                }}
              >
                {t("accept_new_organization.fields.labels.user_phone")}
              </InputLabel>
              <TextField
                margin="normal"
                required
                fullWidth
                id="userPhone"
                autoComplete="off"
                type="tel"
                value={userPhone}
                onChange={(e) => setUserPhone(e.target.value)}
                placeholder={`${t(
                  "accept_new_organization.fields.placeholders.user_phone"
                )}`}
                sx={TextInputStyle}
              />
            </Box>
            <Box>
              <InputLabel
                shrink
                sx={{
                  color: theme.colors.colorsTextTextSecondary,
                  fontWeight: 500,
                }}
              >
                {t("accept_new_organization.fields.labels.password")}
              </InputLabel>
              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                autoComplete="off"
                type={securePass ? "password" : "text"}
                value={password}
                onChange={onChangePassword}
                placeholder={`${t(
                  "accept_new_organization.fields.placeholders.password"
                )}`}
                sx={TextInputStyle}
                InputProps={{
                  endAdornment: renderShowPassBtn(),
                }}
                helperText={
                  errors.password ||
                  t("accept_new_organization.fields.tips.password")
                }
                error={!!errors.password}
              />
            </Box>
            <Box>
              <InputLabel
                shrink
                sx={{
                  color: theme.colors.colorsTextTextSecondary,
                  fontWeight: 500,
                }}
              >
                {t("accept_new_organization.fields.labels.confirm_password")}
              </InputLabel>
              <TextField
                margin="normal"
                required
                fullWidth
                id="confirmPassword"
                autoComplete="off"
                type={securePass ? "password" : "text"}
                value={confirmPassword}
                onChange={onChangeConfirmPassword}
                placeholder={`${t(
                  "accept_new_organization.fields.placeholders.confirm_password"
                )}`}
                sx={TextInputStyle}
                InputProps={{
                  endAdornment: renderShowPassBtn(),
                }}
                helperText={errors.confirmPassword}
                error={!!errors.confirmPassword}
              />
            </Box>
            <Box
              sx={{
                paddingBottom: theme.spacing.spacing3xl,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      "&.Mui-checked": {
                        color: theme.colors.colorsBackgroundBgBrandSolid,
                      },
                    }}
                    checked={termsAgreement}
                    onChange={(_e, checked) => {
                      signTermsAgreement(checked);
                    }}
                  />
                }
                label={
                  <Typography
                    sx={{
                      ...theme.typography[".text-sm-medium"],
                      color: theme.colors.colorsTextTextSecondary,
                    }}
                  >
                    <Trans
                      ns="cloud_ui"
                      i18nKey="pages.company_organizations.accept_new_organization.terms"
                      components={{
                        action: (
                          <Link
                            target="_blank"
                            href="/static/ua?productName=ES Cloud"
                          />
                        ),
                      }}
                    />
                  </Typography>
                }
              />
            </Box>
            <Box
              sx={{
                justifyContent: "space-between",
                display: "flex",
              }}
            >
              <Button
                label={`${t("accept_new_organization.submitButton_user")}`}
                disabled={
                  !userName ||
                  !userSurname ||
                  !termsAgreement ||
                  !!errors.password ||
                  !!errors.confirmPassword
                }
                onClick={acceptInvitation}
                loadingInProgress={isLoadingAccept}
                sx={{
                  pointerEvents: isLoadingAccept ? "none" : "initial",
                  ml: "0 !important",
                  width: "100%",
                }}
              />
            </Box>
          </>
        )}
      </Paper>
    </>
  );
}
