import { useContext } from "react";
// import { darkTheme, lightTheme } from "../../styles/colors";
import { ThemeContext } from "../context/ThemeContext";
import typography from "../../styles/typography/textStyle";
import radius from "../../styles/spacing/radius";
import spacing from "../../styles/spacing/spacing";
import { themes } from "../themeVariables";

const useTheme = () => {
  const { theme } = useContext(ThemeContext);
  const themeStyles = {
    // colors: theme === "light" ? lightTheme : darkTheme,
    colors:
      theme === "light" ? themes.eSLightMode.colors : themes.eSDarkMode.colors,
    typography,
    radius,
    spacing,
  };
  return themeStyles;
};

export type ESThemeType = typeof useTheme;

export default useTheme;
