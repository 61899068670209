import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Timer from "../../../../components/Timer/Timer";
import { useNavigate, useParams } from "react-router";
import { FleetManagementAPI } from "../../../../api/FleetManagementAPI";
import RealTimeDataSection from "./RealTimeDataSection";
import { ReactComponent as SeasonSectionIcon } from "./SectionIcons/SeasonSectionIcon.svg";
import { ReactComponent as SpaceSectionIcon } from "./SectionIcons/SpaceSectionIcon.svg";
import { ReactComponent as SystemOperationIcon } from "./SectionIcons/SystemOperationIcon.svg";
import { ReactComponent as Zone1OperationsIcon } from "./SectionIcons/Zone1OperationsIcon.svg";
import { ReactComponent as Zone2OperationsIcon } from "./SectionIcons/Zone2OperationsIcon.svg";
import { ReactComponent as DHWIcon } from "./SectionIcons/DHWIcon.svg";
import { ReactComponent as QuietModeIcon } from "./SectionIcons/QuietModeIcon.svg";
import { ReactComponent as DefrostingIcon } from "./SectionIcons/DefrostingIcon.svg";
import { ReactComponent as OutdoorTemperatureIcon } from "./SectionIcons/OutdoorTemperatureIcon.svg";
import { ReactComponent as HydraulicParametersIcon } from "./SectionIcons/HydraulicParametersIcon.svg";
import { ReactComponent as RefrigerantParametersIcon } from "./SectionIcons/RefrigerantParametersIcon.svg";
import { ReactComponent as SGReadyIcon } from "./SectionIcons/SGReadyIcon.svg";
import { ReactComponent as AdditionalHeatingSourcesIcon } from "./SectionIcons/AdditionalHeatingSourcesIcon.svg";
import { ReactComponent as AccumulateOperationTimeIcon } from "./SectionIcons/AccumulateOperationTimeIcon.svg";
import { ReactComponent as OtherIcon } from "./SectionIcons/OtherIcon.svg";

import { useTranslation } from "react-i18next";
import RealTimeDataRow from "./RealTimeDataRow";
import useTheme from "../../../../theme/hooks/useTheme";
import {
  useGetInstallationSettingsForRealTimeDataQuery,
  useGetInstallationStateQuery,
} from "../../../../store/services/installation";
import {
  ActiveOrInactiveEnum,
  ActiveOrInactiveForDHWEnum,
  InstallationValueUnit,
  OperationStatus,
  Season,
  SgReadyStatus,
} from "../../../../store/services/models/installations/installationsCommon";
import { IStateZoneOperation } from "../../../../store/services/models/installations/installationState";
import {
  IInstallationZoneOperation,
  StageStateEnum,
} from "../../../../store/services/models/installations/installationSettings";

export default function RealTimeData() {
  const { t } = useTranslation("cloud_ui");
  const { id } = useParams();
  const theme = useTheme();

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const params = new URLSearchParams(url.search);

  const {
    data: realTimeDataState,
    isFetching,
    isLoading,
    refetch,
    fulfilledTimeStamp: stateFulfilledTimeStamp,
  } = useGetInstallationStateQuery(
    {
      uuid: id!,
      isRealData: params.get("dummyData") === "false",
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 60000,
    }
  );

  const {
    data: realTimeDataSettings,
    isFetching: isFetchingSettings,
    isLoading: isLoadingSettings,
    refetch: refetchSettings,
    fulfilledTimeStamp: settingsFulfilledTimeStamp,
  } = useGetInstallationSettingsForRealTimeDataQuery(
    {
      uuid: id!,
      isRealData: params.get("dummyData") === "false",
    },
    {
      refetchOnMountOrArgChange: true,
      pollingInterval: 60000,
    }
  );

  function formatValue(
    value: number | undefined,
    decimalPlaces: number,
    unit: InstallationValueUnit | undefined
  ) {
    let unitString: string;

    switch (unit) {
      case InstallationValueUnit.CELSIUS:
        unitString = "°C";
        break;
      case InstallationValueUnit.LITRE_PER_MINUTE:
        unitString = "l/min";
        break;
      case InstallationValueUnit.PERCENT:
        unitString = "%";
        break;
      case InstallationValueUnit.HERTZ:
        unitString = "Hz";
        break;
      case InstallationValueUnit.BAR:
        unitString = "bar";
        break;
      case InstallationValueUnit.REVOLUTION_PER_MINUTE:
        unitString = "rpm";
        break;
      case InstallationValueUnit.STEP:
        unitString = "p";
        break;
      case InstallationValueUnit.AMPERE:
        unitString = "A";
        break;
      case InstallationValueUnit.VOLT:
        unitString = "V";
        break;
      case InstallationValueUnit.KILOWATT:
        unitString = "kw";
        break;
      default:
        unitString = "";
        break;
    }
    if (value) {
      switch (decimalPlaces) {
        case 0:
          return `${value?.toFixed(0)} ${unitString}`;
        case 1:
          return `${value?.toFixed(1)} ${unitString}`;
        case 2:
          return `${value?.toFixed(2)} ${unitString}`;
        default:
          throw new Error("decimalPlaces should be 0, 1, or 2");
      }
    } else {
      return "--";
    }
  }

  const calculateActiveStages = () => {
    let count = 0;
    const additionalInlineHeatingSource =
      realTimeDataSettings?.installerSettings?.commissioning
        ?.additionalInlineHeatingSource;
    if (additionalInlineHeatingSource) {
      const { stage1, stage2, stage3 } = additionalInlineHeatingSource;
      // if (stage1 === StageStateEnum.OFF && stage2 === StageStateEnum.OFF && stage3 === StageStateEnum.OFF) {
      //   count = 0
      // } else if () {

      // }
      const stages = [stage1, stage2, stage3];
      stages.forEach((stage) => {
        if (stage !== StageStateEnum.OFF) {
          count += 1;
        }
      });
    }

    return count === 0 ? "" : `${count}/3`;
  };

  const seasonFormatting = (season: Season | undefined) => {
    switch (season) {
      case Season.HEATING:
        return t("pages.realTimeData.sections.season.heating");
      case Season.COOLING:
        return t("pages.realTimeData.sections.season.cooling");
      case Season.NEUTRAL:
        return t("pages.realTimeData.sections.season.neutral");
      default:
        return "";
    }
  };

  const systemOperationFormatting = (
    operationStatus: OperationStatus | undefined
  ) => {
    switch (operationStatus) {
      case OperationStatus.COOLING:
        return t("pages.realTimeData.sections.systemOperation.cooling");
      case OperationStatus.DHW:
        return t("pages.realTimeData.sections.systemOperation.DHW");
      case OperationStatus.HEATING:
        return t("pages.realTimeData.sections.systemOperation.heating");
      case OperationStatus.IDLE:
        return t("pages.realTimeData.sections.systemOperation.IDLE");
      default:
        return "";
    }
  };

  const activeOrInactiveStateFormatting = (
    state: ActiveOrInactiveEnum | undefined | ActiveOrInactiveForDHWEnum
  ) => {
    switch (state) {
      case ActiveOrInactiveEnum.ACTIVE:
        return t("pages.realTimeData.sections.active");
      case ActiveOrInactiveForDHWEnum.ACTIVE:
        return t("pages.realTimeData.sections.active");
      case ActiveOrInactiveEnum.INACTIVE:
        return t("pages.realTimeData.sections.inactive");
      case ActiveOrInactiveForDHWEnum.INACTIVE:
        return t("pages.realTimeData.sections.inactive");
      default:
        return "";
    }
  };

  const SGReadyStatusFormatting = (status: SgReadyStatus | undefined) => {
    switch (status) {
      case SgReadyStatus.BLOCKED:
        return t("pages.realTimeData.sections.SGReady.blocked");
      case SgReadyStatus.ENCOURAGED:
        return t("pages.realTimeData.sections.SGReady.encouraged");
      case SgReadyStatus.NORMAL:
        return t("pages.realTimeData.sections.SGReady.normal");
      case SgReadyStatus.ORDERED:
        return t("pages.realTimeData.sections.SGReady.ordered");
      default:
        return "";
    }
  };

  const extractHoursAndMinutes = (duration: string | undefined) => {
    if (duration) {
      const regex = /PT(\d+H)?(\d+M)?/;
      const matches = duration?.match(regex);

      if (!matches) {
        throw new Error("Invalid duration format");
      }

      const hours = matches[1] ? matches[1].slice(0, -1) : "0";
      const minutes = matches[2] ? matches[2].slice(0, -1) : "0";

      return `${hours}h ${minutes}m`;
    } else {
      return "";
    }
  };

  const renderZonesOperationsSections = (
    zone: 1 | 2,
    data: IStateZoneOperation | undefined,
    settingsData: IInstallationZoneOperation | undefined
  ) => {
    return (
      <RealTimeDataSection
        icon={zone === 1 ? <Zone1OperationsIcon /> : <Zone2OperationsIcon />}
        name={
          zone === 1
            ? t("pages.realTimeData.sections.zoneOperations.title1")
            : t("pages.realTimeData.sections.zoneOperations.title2")
        }
      >
        <RealTimeDataRow
          label={t(
            "pages.realTimeData.sections.zoneOperations.desiredRoomTemperature"
          )}
          value={formatValue(
            settingsData?.preferredRoomTemp?.value,
            1,
            settingsData?.preferredRoomTemp?.unit
          )}
        />
        <RealTimeDataRow
          label={t(
            "pages.realTimeData.sections.zoneOperations.currentRoomTemperature",
            { zoneNumber: zone }
          )}
          value={formatValue(
            data?.currentRoomTemp?.value,
            1,
            data?.currentRoomTemp?.unit
          )}
        />
        {/* <RealTimeDataRow
          label={t(
            "pages.realTimeData.sections.zoneOperations.currentTargetFlowTemperature"
          )}
          value={formatValue(
            data?.currentFlowTemp.value,
            0,
            data?.currentFlowTemp.unit
          )}
        /> */}

        <RealTimeDataRow
          label={
            zone === 1
              ? t("pages.realTimeData.sections.zoneOperations.TV1Temperature")
              : t("pages.realTimeData.sections.zoneOperations.TV2Temperature")
          }
          value={formatValue(
            data?.currentFlowTemp?.value,
            0,
            data?.currentFlowTemp?.unit
          )}
        />
        <RealTimeDataRow
          label={
            zone === 1
              ? t(
                  "pages.realTimeData.sections.zoneOperations.waterPumpP1Status"
                )
              : t(
                  "pages.realTimeData.sections.zoneOperations.waterPumpP2Status"
                )
          }
          value={data?.waterPumpStatus as string} //TODO!!! Question? No data in response, but property in model
        />
      </RealTimeDataSection>
    );
  };

  return (
    <Box>
      <Timer
        timestamp={stateFulfilledTimeStamp}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "52px",
          p: `0 ${theme.spacing.spacingLg}`,
          border: `1px solid ${theme.colors.colorsBorderBorderBrandSolid}`,
          borderRadius: theme.radius.radiusMd,
          m: "10px 0",
          backgroundColor:
            theme.colors.componentColorsUtilityBrandUtilityBrand100,
        }}
      >
        <Typography
          sx={{
            ...theme.typography[".text-sm-medium"],
            color: theme.colors.colorsTextTextPrimary,
          }}
        >
          {t("pages.realTimeData.infoMessageText")}
        </Typography>
      </Box>
      {/* data container */}
      <Box
        sx={{
          display: "flex",
          pt: theme.spacing.spacingLg,
          height: `calc((var(--vh, 1vh) * 100) - 313px)`,
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "4px",
            height: "4px",
            backgroundColor: theme.colors.colorsBackgroundBgSecondaryAlt,
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            backgroundColor: "rgba(0,0,0,0.2)",
            minHeight: "24px",
          },
          "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: theme.colors.colorsTextTextQuaternary,
          },
        }}
      >
        {/* column 1 container */}
        <Box sx={{ width: "50%" }}>
          {/* season section */}
          <RealTimeDataSection
            icon={<SeasonSectionIcon />}
            name={t("pages.realTimeData.sections.season.title")}
          >
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.season.currentSeasonLabel")}
              value={seasonFormatting(realTimeDataState?.season.currentSeason)}
            />
          </RealTimeDataSection>
          {/* season section end*/}

          {/* space section */}
          <RealTimeDataSection
            icon={<SpaceSectionIcon />}
            name={t("pages.realTimeData.sections.space.title")}
          >
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.space.outdoorTemperature")}
              value={formatValue(
                realTimeDataState?.space.outdoorTemp.value,
                1,
                realTimeDataState?.space.outdoorTemp.unit
              )}
            />
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.space.heatpumpOnOff")}
              value={realTimeDataSettings?.operation?.hpState}
            />
          </RealTimeDataSection>
          {/* space section end*/}

          {/* system operations section */}
          <RealTimeDataSection
            icon={<SystemOperationIcon />}
            name={t("pages.realTimeData.sections.systemOperation.title")}
          >
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.systemOperation.operationStatus"
              )}
              value={systemOperationFormatting(
                realTimeDataState?.systemOperation?.operationStatus
              )}
            />
          </RealTimeDataSection>
          {/* system operations section section end*/}

          {/* zone 1 operations*/}
          {renderZonesOperationsSections(
            1,
            realTimeDataState?.zoneOperations.zone1Operations,
            realTimeDataSettings?.zoneOperations.zone1Operations
          )}
          {/* zone 1 operations end*/}

          {/* zone 2 operations*/}
          {realTimeDataSettings &&
            realTimeDataSettings?.installerSettings?.commissioning?.zonesCount
              ?.value > 1 &&
            renderZonesOperationsSections(
              2,
              realTimeDataState?.zoneOperations.zone2Operations,
              realTimeDataSettings?.zoneOperations.zone2Operations
            )}
          {/* zone 2 operations end*/}

          {/* DHW section*/}
          <RealTimeDataSection
            icon={<DHWIcon />}
            name={t("pages.realTimeData.sections.DHW.title")}
          >
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.DHW.setDHWTemperature")} //TODO!!! Question? is it current dhw or preffered
              // value={formatValue(
              //   realTimeDataState?.dhw.currentTemp.value,
              //   0,
              //   realTimeDataState?.dhw.currentTemp.unit
              // )}
              value={formatValue(
                realTimeDataSettings?.dhw?.preferredTemp?.value,
                0,
                realTimeDataSettings?.dhw?.preferredTemp?.unit
              )}
            />
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.DHW.DHWTankTemperatureTWD")} //TODO!!! Question? Data for this field
              value={formatValue(
                realTimeDataState?.dhw?.currentTemp?.value,
                0,
                realTimeDataState?.dhw?.currentTemp?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.DHW.AntiLegionellaCurrentState"
              )}
              value={activeOrInactiveStateFormatting(
                realTimeDataSettings?.dhw.antiLegionella.state
              )}
            />
          </RealTimeDataSection>
          {/* DHW section end*/}

          {/* quiet mode section */}
          <RealTimeDataSection
            icon={<QuietModeIcon />}
            name={t("pages.realTimeData.sections.quietMode.title")}
          >
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.quietMode.currentState")}
              value={activeOrInactiveStateFormatting(
                realTimeDataState?.quietMode?.state
              )}
            />
          </RealTimeDataSection>
          {/* quiet mode section end */}

          {/* defrosting mode section */}
          <RealTimeDataSection
            icon={<DefrostingIcon />}
            name={t("pages.realTimeData.sections.defrostingMode.title")}
          >
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.defrostingMode.currentState"
              )}
              value={`${t(
                `pages.realTimeData.sections.${
                  realTimeDataState?.refrigerantParameters?.defrostingActive
                    ? "active"
                    : "inactive"
                }`
              )}`}
            />
          </RealTimeDataSection>
          {/* defrosting mode section end */}

          {/* outdoor temperature section */}
          <RealTimeDataSection
            icon={<OutdoorTemperatureIcon />}
            name={t("pages.realTimeData.sections.outdoorTemperature.title")}
          >
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.outdoorTemperature.outdoorTemperature" //TODO!!! Question? Is it correct data for outdoor temperature in outdooor section
              )}
              value={formatValue(
                realTimeDataState?.space?.outdoorTemp?.value,
                1,
                realTimeDataState?.space?.outdoorTemp?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.outdoorTemperature.averageOutdoorTemperature1h"
              )}
              value={formatValue(
                realTimeDataState?.outdoorTemp.average1h.value,
                1,
                realTimeDataState?.outdoorTemp.average1h.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.outdoorTemperature.averageOutdoorTemperature3h"
              )}
              value={formatValue(
                realTimeDataState?.outdoorTemp.average3h.value,
                1,
                realTimeDataState?.outdoorTemp.average3h.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.outdoorTemperature.averageOutdoorTemperature24h"
              )}
              value={formatValue(
                realTimeDataState?.outdoorTemp.average24h.value,
                1,
                realTimeDataState?.outdoorTemp.average24h.unit
              )}
            />
          </RealTimeDataSection>
          {/* outdoor temperature section end */}
          <Box sx={{ height: theme.spacing.spacing2xl }} />
        </Box>
        {/* column 1 container end*/}

        {/* column 2 container*/}
        <Box sx={{ width: "50%" }}>
          {/* hydraulic parameters section */}
          <RealTimeDataSection
            icon={<HydraulicParametersIcon />}
            name={t("pages.realTimeData.sections.hydraulicParameters.title")}
          >
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.DHW.coolingHeatingWaterTemperatureTHC"
              )} //TODO!!! Question? Is it current dhw temp or something else
              value={formatValue(
                realTimeDataState?.dhw?.currentTemp?.value,
                1,
                realTimeDataState?.dhw?.currentTemp?.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.hydraulicParameters.condenserOutletWaterTemperatureTUO"
              )}
              value={formatValue(
                realTimeDataState?.hydraulicParameters
                  .condenserOutletWaterTempTUO.value,
                0,
                realTimeDataState?.hydraulicParameters
                  .condenserOutletWaterTempTUO.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.hydraulicParameters.condenserInletWaterTemperatureTUI"
              )}
              value={formatValue(
                realTimeDataState?.hydraulicParameters
                  .condenserInletWaterTempTUI.value,
                0,
                realTimeDataState?.hydraulicParameters
                  .condenserInletWaterTempTUI.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.hydraulicParameters.waterPumpP0Status"
              )}
              value={formatValue(
                realTimeDataState?.hydraulicParameters.waterPumpP0Status.value,
                0,
                realTimeDataState?.hydraulicParameters.waterPumpP0Status.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.hydraulicParameters.waterFlowRate"
              )}
              value={formatValue(
                realTimeDataState?.hydraulicParameters.waterFlowRate.value,
                0,
                realTimeDataState?.hydraulicParameters.waterFlowRate.unit
              )}
            />
          </RealTimeDataSection>
          {/* hydraulic parameters section end*/}
          {/* refrigerant parameters section */}
          <RealTimeDataSection
            icon={<RefrigerantParametersIcon />}
            name={t("pages.realTimeData.sections.refrigerantParameters.title")}
          >
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.compressorSpeed"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters.compressorSpeed.value,
                0,
                realTimeDataState?.refrigerantParameters.compressorSpeed.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.highPressurePd"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters.highPressurePd.value,
                1,
                realTimeDataState?.refrigerantParameters.highPressurePd.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.compressorDischargeTemperatureTD"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters
                  .compressorDischargeTempTD.value,
                1,
                realTimeDataState?.refrigerantParameters
                  .compressorDischargeTempTD.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.lowPressurePs"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters.lowPressurePs.value,
                1,
                realTimeDataState?.refrigerantParameters.lowPressurePs.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.compressorSuctionTemperatureTS"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters.compressorSuctionTempTS
                  .value,
                1,
                realTimeDataState?.refrigerantParameters.compressorSuctionTempTS
                  .unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.condensingTemperatureTUP"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters.condensingTempTUP
                  .value,
                1,
                realTimeDataState?.refrigerantParameters.condensingTempTUP.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.evaporatingTemperatureTP"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters.evaporatingTempTP
                  .value,
                1,
                realTimeDataState?.refrigerantParameters.evaporatingTempTP.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.EEVOpening"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters.eevOpening.value,
                0,
                realTimeDataState?.refrigerantParameters.eevOpening.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.fanSpeed1"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters.fanSpeed1.value,
                0,
                realTimeDataState?.refrigerantParameters.fanSpeed1.unit
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.refrigerantParameters.fanSpeed2"
              )}
              value={formatValue(
                realTimeDataState?.refrigerantParameters.fanSpeed2.value,
                0,
                realTimeDataState?.refrigerantParameters.fanSpeed2.unit
              )}
            />
          </RealTimeDataSection>
          {/* refrigerant parameters section end*/}

          {/* SG ready section */}
          <RealTimeDataSection
            icon={<SGReadyIcon />}
            name={t("pages.realTimeData.sections.SGReady.title")}
          >
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.SGReady.SGReadyStage")}
              value={SGReadyStatusFormatting(realTimeDataState?.sgReady.status)}
            />
          </RealTimeDataSection>
          {/* SG ready section end */}

          {/* Additional heating sources section */}
          <RealTimeDataSection
            icon={<AdditionalHeatingSourcesIcon />}
            name={t(
              "pages.realTimeData.sections.additionalHeatingSources.title"
            )}
          >
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.additionalHeatingSources.additionalInlineHeatingSource"
              )}
              value={`${activeOrInactiveStateFormatting(
                realTimeDataSettings?.installerSettings?.commissioning
                  ?.additionalInlineHeatingSource?.state
              )} ${
                realTimeDataSettings?.installerSettings?.commissioning
                  ?.additionalInlineHeatingSource?.state ===
                ActiveOrInactiveEnum.ACTIVE
                  ? calculateActiveStages()
                  : ""
              }`}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.additionalHeatingSources.additionalDHWTankHeatingSource"
              )}
              value={activeOrInactiveStateFormatting(
                realTimeDataSettings?.installerSettings?.commissioning
                  ?.additionalDhwHeatingSource?.state
                // .dhwTankHeatingSourceState
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.additionalHeatingSources.additionalBufferTankHeatingSource"
              )}
              value={activeOrInactiveStateFormatting(
                realTimeDataSettings?.installerSettings?.commissioning
                  ?.bufferTank?.inlineHeaterState
              )}
            />
          </RealTimeDataSection>
          {/* Additional heating sources section end*/}

          {/* Accumulate Operation time section */}
          <RealTimeDataSection
            icon={<AccumulateOperationTimeIcon />}
            name={t(
              "pages.realTimeData.sections.accumulatedOperationTime.title"
            )}
          >
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.accumulatedOperationTime.heatPump"
              )}
              value={extractHoursAndMinutes(
                realTimeDataState?.accumulatedOperationTime.heatPump
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.accumulatedOperationTime.additionalInlineHeatingSourceStage1"
              )}
              value={extractHoursAndMinutes(
                realTimeDataState?.accumulatedOperationTime
                  .additionalInlineHeatingSource1
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.accumulatedOperationTime.additionalInlineHeatingSourceStage2"
              )}
              value={extractHoursAndMinutes(
                realTimeDataState?.accumulatedOperationTime
                  .additionalInlineHeatingSource2
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.accumulatedOperationTime.additionalInlineHeatingSourceStage3"
              )}
              value={extractHoursAndMinutes(
                realTimeDataState?.accumulatedOperationTime
                  .additionalInlineHeatingSource3
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.accumulatedOperationTime.additionalDHWTankHeatingSource"
              )}
              value={extractHoursAndMinutes(
                realTimeDataState?.accumulatedOperationTime
                  .additionalDHWHeatingSource
              )}
            />
            <RealTimeDataRow
              label={t(
                "pages.realTimeData.sections.accumulatedOperationTime.additionalDHWTankHeatingSource"
              )}
              value={extractHoursAndMinutes(
                realTimeDataState?.accumulatedOperationTime
                  .additionalBufferTankHeatingSource
              )}
            />
          </RealTimeDataSection>
          {/* Accumulate Operation time section end*/}

          {/* Other section */}
          <RealTimeDataSection
            icon={<OtherIcon />}
            name={t("pages.realTimeData.sections.other.title")}
          >
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.other.outdoorUnitCurrent")}
              value={formatValue(
                realTimeDataState?.others.outdoorUnitCurrent.value,
                0,
                realTimeDataState?.others.outdoorUnitCurrent.unit
              )}
            />
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.other.outdoorUnitVoltage")}
              value={formatValue(
                realTimeDataState?.others.outdoorUnitVoltage.value,
                0,
                realTimeDataState?.others.outdoorUnitVoltage.unit
              )}
            />
            <RealTimeDataRow
              label={t("pages.realTimeData.sections.other.softwareVersion")}
              value={realTimeDataState?.others.softwareVersion}
            />
          </RealTimeDataSection>
          {/* Other section */}
        </Box>
        {/* column 2 container end*/}
      </Box>
      {/* data container end*/}
    </Box>
  );
}
