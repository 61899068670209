import { Box, Typography } from "@mui/material";
import { ApexOptions } from "apexcharts";
import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import EnergySavingCalculationResponse from "../../../../api/responses/tools/EnergySavingCalculationResponse";
import { useTranslation } from "react-i18next";

export interface IChartBlockProps {
  energySavings: EnergySavingCalculationResponse | null;
  isDHWHeatingEnabledForLatestCalculation: boolean;
}

export const ChartBlock = ({
  energySavings,
  isDHWHeatingEnabledForLatestCalculation,
}: IChartBlockProps) => {
  const { t } = useTranslation("cloud_ui");
  const yAxis = (
    name: string,
    title: string,
    opposite: boolean,
    show: boolean,
    min: number,
    max: number
  ) => {
    return {
      seriesName: name,
      opposite: opposite,
      min: min,
      max: max,
      show: show,
      tickAmount: 7,
      decimalsInFloat: 0,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: "#000000",
      },
      labels: {
        style: {
          colors: "#000000",
        },
      },
      title: {
        text: title,
        style: {
          color: "#000000",
        },
      },
    };
  };

  const temperatures = energySavings?.chartData.map(
    (d) => d.outsideTemperature
  ) as number[];
  const minTemperature = Math.min.apply(Math, temperatures);
  const maxTemperature = Math.max.apply(Math, temperatures);

  const power: Array<number> = energySavings?.chartData.reduce(
    (
      acc: Array<number>,
      item: {
        additive: number;
        electricEffectVp: number;
        heatEffectVP: number;
        heatEffectVV: number;
        powerRequirement: number;
      }
    ) =>
      acc.concat(
        item.additive,
        item.electricEffectVp,
        item.heatEffectVP,
        item.heatEffectVV,
        item.powerRequirement
      ),
    []
  ) as Array<number>;
  const minPower = Math.min.apply(Math, power);
  const maxPower = Math.max.apply(Math, power);

  const series: ApexAxisChartSeries = [];

  series.push({
    name: t(
      "pages.savingCalculator.systemSimulationBlock.graphLabels.powerDemandHeat"
    ) as string,
    data: energySavings?.chartData.map((d) => d.powerRequirement) as number[],
  });
  series.push({
    name: t(
      "pages.savingCalculator.systemSimulationBlock.graphLabels.powerSupplyForHeatPump"
    ) as string,
    data: energySavings?.chartData.map((d) => d.heatEffectVP) as number[],
  });
  if (isDHWHeatingEnabledForLatestCalculation) {
    series.push({
      name: t(
        "pages.savingCalculator.systemSimulationBlock.graphLabels.powerRequiredForDHW"
      ) as string,
      data: energySavings?.chartData.map((d) => d.heatEffectVV) as number[],
    });
  }
  series.push({
    name: t(
      "pages.savingCalculator.systemSimulationBlock.graphLabels.additivePower"
    ) as string,
    data: energySavings?.chartData.map((d) => d.additive) as number[],
  });
  series.push({
    name: t(
      "pages.savingCalculator.systemSimulationBlock.graphLabels.electricalPowerForHeatPump"
    ) as string,
    data: energySavings?.chartData.map((d) => d.electricEffectVp) as number[],
  });
  series.push({
    name: t(
      "pages.savingCalculator.systemSimulationBlock.graphLabels.outdoorTemperature"
    ) as string,
    data: energySavings?.chartData.map((d) => d.outsideTemperature) as number[],
  });

  const colors = [];
  colors.push("#8A57F7");
  colors.push("#58ADFA");
  if (isDHWHeatingEnabledForLatestCalculation) {
    colors.push("#8B8D93");
  }
  colors.push("#77C72F");
  colors.push("#F2B935");
  colors.push("#E96057");

  const yaxis = [];
  yaxis.push(
    yAxis(
      "effect",
      `${t("pages.savingCalculator.effect")} kW`,
      false,
      true,
      minPower,
      maxPower
    )
  );
  yaxis.push(
    yAxis(
      "effect",
      `${t("pages.savingCalculator.effect")} kW`,
      false,
      false,
      minPower,
      maxPower
    )
  );
  if (isDHWHeatingEnabledForLatestCalculation) {
    yaxis.push(
      yAxis(
        "effect",
        `${t("pages.savingCalculator.effect")} kW`,
        false,
        false,
        minPower,
        maxPower
      )
    );
  }
  yaxis.push(
    yAxis(
      "effect",
      `${t("pages.savingCalculator.effect")} kW`,
      false,
      false,
      minPower,
      maxPower
    )
  );
  yaxis.push(
    yAxis(
      "effect",
      `${t("pages.savingCalculator.effect")} kW`,
      false,
      false,
      minPower,
      maxPower
    )
  );
  yaxis.push(
    yAxis(
      "outdoor_temperature",
      `${t("pages.savingCalculator.outdoorTemp")} °C`,
      true,
      true,
      minTemperature,
      maxTemperature
    )
  );

  const options: ApexOptions = {
    chart: {
      type: "line",
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: t("pages.savingCalculator.numberOfHours") as string,
      align: "center",
    },
    colors: colors,
    plotOptions: {
      bar: {
        columnWidth: "20%",
      },
    },
    xaxis: {
      categories: energySavings?.chartData.map((d) => d.time),
      tickAmount: 10,
      // title: { text: t("pages.savingCalculator.numberOfHours") as string },
    },
    yaxis: yaxis,
    legend: {
      horizontalAlign: "center",
      offsetX: 40,
    },
  };

  const exportableOptions = {
    ...options,
    chart: {
      id: "chart-exportable",
    },
    stroke: {
      width: 2,
    },
  };

  const memoizedChart = useMemo(() => {
    return (
      <div id="chart" style={{ minWidth: "900px" }}>
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={350}
        />
        <div
          style={{
            visibility: "hidden",
            maxHeight: "1px",
            overflowY: "hidden",
          }}
        >
          <ReactApexChart
            options={exportableOptions}
            series={series}
            type="line"
            height={430}
            width={1100}
          />
        </div>
        <Box
          mt={"16px"}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            color: "rgba(0, 0, 0, 0.60)",
          }}
        >
          <Typography fontSize={12} fontStyle={"italic"} mr={"20px"}>
            {t("pages.savingCalculator.systemSimulationBlock.graphHint")}
          </Typography>
        </Box>
      </div>
    );
  }, [energySavings, isDHWHeatingEnabledForLatestCalculation]);

  return memoizedChart;
};
