import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { createAction } from "@reduxjs/toolkit";

export const redirect = createAction<string>("auth/redirect");

export interface IAuthState {
  redirectPath: string | null;
}

const initialState: IAuthState = {
  redirectPath: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(redirect, (state, action: PayloadAction<string | null>) => {
      state.redirectPath = action.payload;
    });
  },
});

export const { actions, reducer } = authSlice;
export default reducer;
