import { Box, InputLabel, Typography } from "@mui/material";
import React from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";

export interface IDisabledFieldProps {
  label: string;
  value: any;
}

export default function DisabledField({ label, value }: IDisabledFieldProps) {
  const theme = useTheme();

  return (
    <Box sx={{ width: "100%", mb: theme.spacing.spacingLg }}>
      <InputLabel
        shrink
        sx={{
          ...theme.typography[".text-sm-medium"],
          color: theme.colors.colorsTextTextSecondary,
        }}
      >
        {label}
      </InputLabel>
      <Box
        sx={{
          p: "10px 14px",
          height: "44px",
          width: "100%",
          border: `1px solid ${theme.colors.colorsBorderBorderDisabled}`,
          borderRadius: theme.radius.radiusMd,
          backgroundColor: theme.colors.colorsBackgroundBgDisabledSubtle,
          boxShadow: "0px 1px 2px 0px #1018280D",
        }}
      >
        <Typography
          sx={{
            ...theme.typography[".text-md-regular"],
            color: theme.colors.colorsTextTextDisabled,
          }}
        >
          {value || "-"}
        </Typography>
      </Box>
    </Box>
  );
}
