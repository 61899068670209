import React, { useEffect, useState } from "react";
import useTheme from "../../theme/hooks/useTheme";
import {
  Box,
  CircularProgress,
  Button as MUIButton,
  SxProps,
} from "@mui/material";

export enum ButtonType {
  Primary = "Primary",
  ErrorPrimary = "Error Primary",
  SecondaryGray = "Secondary Gray",
  SecondaryColor = "Secondary Color",
  ErrorSecondary = "Error Secondary",
  TertiaryGray = "Tertiary Gray",
  TertiaryColor = "Tertiary Color",
  ErrorTertiary = "Error Tertiary",
  LinkGray = "Link Gray",
  LinkColor = "Link Color",
  ErrorLink = "Error Link",
}

export enum ButtonSize {
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  xxl = "xxl",
  xxxl = "xxxl",
}

export enum IconPosition {
  Left = "Left",
  Right = "Right",
}

interface ButtonProps {
  buttonType?: ButtonType;
  buttonSize?: ButtonSize;
  Icon?: any;
  iconPosition?: IconPosition;
  isDotLeadingIcon?: boolean;
  isOnlyIcon?: boolean;
  label?: string;
  disabled?: boolean;
  url?: string;
  onClick?: () => void;
  sx?: SxProps;
  loadingInProgress?: boolean;
}

interface ButtonColorsVariables {
  textColor: string;
  textColorHover: string;
  textColorDisabled: string;
  backgroundColor: string;
  backgroundColorHover: string;
  backgroundColorFocused: string;
  backgroundColorDisabled: string;
  border: string;
  borderHover: string;
  borderFocused: string;
  outlineFocused: string;
  borderDisabled: string;
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
  buttonType = ButtonType.Primary,
  buttonSize = ButtonSize.md,
  Icon,
  iconPosition = IconPosition.Left,
  isDotLeadingIcon,
  isOnlyIcon,
  label,
  disabled,
  url,
  onClick,
  sx,
  loadingInProgress,
}: ButtonProps) => {
  const theme = useTheme();
  const [colorVariables, setColorVariables] = useState<ButtonColorsVariables>();
  const [focusShadow, setFocusShadow] = useState<string>();
  const [typography, setTypography] = useState<any>();
  const [spacing, setSpacing] = useState<string>();
  const [iconSizes, setIconSizes] = useState<{ width: number; heigt: number }>({
    width: 20,
    heigt: 20,
  });
  const [hideLabel, setHideLabel] = useState<boolean>(isOnlyIcon && Icon);

  useEffect(() => {
    setHideLabel(isOnlyIcon && Icon);
  }, [isOnlyIcon, Icon]);

  useEffect(() => {
    const {
      componentColorsComponentsButtonsPrimaryButtonPrimaryFg,
      componentColorsComponentsButtonsPrimaryButtonPrimaryFgHover,
      colorsForegroundFgDisabled,
      componentColorsComponentsButtonsPrimaryButtonPrimaryBg,
      componentColorsComponentsButtonsPrimaryButtonPrimaryBgHover,
      colorsBackgroundBgDisabled,
      componentColorsComponentsButtonsPrimaryButtonPrimaryBorder,
      componentColorsComponentsButtonsPrimaryButtonPrimaryBorderHover,
      colorsBorderBorderDisabledSubtle,
      componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
      componentColorsComponentsButtonsSecondaryButtonSecondaryFgHover,
      componentColorsComponentsButtonsSecondaryButtonSecondaryBg,
      componentColorsComponentsButtonsSecondaryButtonSecondaryBgHover,
      componentColorsComponentsButtonsSecondaryButtonSecondaryBorder,
      componentColorsComponentsButtonsSecondaryButtonSecondaryBorderHover,
      componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg,
      componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFgHover,
      componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBg,
      componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBgHover,
      componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBorder,
      componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBorderHover,
      componentColorsComponentsButtonsTertiaryButtonTertiaryFg,
      componentColorsComponentsButtonsTertiaryButtonTertiaryFgHover,
      componentColorsComponentsButtonsTertiaryButtonTertiaryBgHover,
      componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFg,
      componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFgHover,
      componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorBgHover,
      colorsForegroundFgWhite,
      componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorBg,
      componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorBgHover,
      componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorBorder,
      componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorBorderHover,
      componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorFg,
      componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorFgHover,
      componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorBg,
      componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorBgHover,
      colorsBackgroundBgPrimary,
      componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorBorder,
      componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorBorderHover,
      componentColorsComponentsButtonsTertiaryErrorButtonTertiaryErrorFg,
      componentColorsComponentsButtonsTertiaryErrorButtonTertiaryErrorFgHover,
      componentColorsComponentsButtonsTertiaryErrorButtonTertiaryErrorBgHover,
    } = theme.colors;
    // textColor: string,
    // textColorHover: string,
    // textColorDisabled: string,
    // backgroundColor: string,
    // backgroundColorHover: string,
    // backgroundColorFocused: string,
    // backgroundColorDisabled: string,
    // bodrder: string,
    // borderHover: string,
    // borderFocused: string,
    // borderDisabled: string,
    // outlineFocused: string
    switch (buttonType) {
      case ButtonType.Primary:
        updateColors(
          componentColorsComponentsButtonsPrimaryButtonPrimaryFg,
          componentColorsComponentsButtonsPrimaryButtonPrimaryFgHover,
          colorsForegroundFgDisabled,
          componentColorsComponentsButtonsPrimaryButtonPrimaryBg,
          componentColorsComponentsButtonsPrimaryButtonPrimaryBgHover,
          componentColorsComponentsButtonsPrimaryButtonPrimaryBg,
          colorsBackgroundBgDisabled,
          componentColorsComponentsButtonsPrimaryButtonPrimaryBorder,
          componentColorsComponentsButtonsPrimaryButtonPrimaryBorderHover,
          componentColorsComponentsButtonsPrimaryButtonPrimaryBorder,
          colorsBorderBorderDisabledSubtle,
          ""
        );
        setFocusShadow(
          "0px 0px 0px 4px rgba(149, 193, 31, 0.24), 0px 1px 2px 0px rgba(0, 0, 0, 0.05)"
        );
        break;
      case ButtonType.SecondaryGray:
        updateColors(
          componentColorsComponentsButtonsSecondaryButtonSecondaryFg,
          componentColorsComponentsButtonsSecondaryButtonSecondaryFgHover,
          colorsForegroundFgDisabled,
          componentColorsComponentsButtonsSecondaryButtonSecondaryBg,
          componentColorsComponentsButtonsSecondaryButtonSecondaryBgHover,
          componentColorsComponentsButtonsSecondaryButtonSecondaryBg,
          componentColorsComponentsButtonsSecondaryButtonSecondaryBg,
          componentColorsComponentsButtonsSecondaryButtonSecondaryBorder,
          componentColorsComponentsButtonsSecondaryButtonSecondaryBorderHover,
          componentColorsComponentsButtonsSecondaryButtonSecondaryBorder,
          colorsBorderBorderDisabledSubtle,
          ""
        );
        setFocusShadow(
          "0px 0px 0px 4px rgba(152, 162, 179, 0.14), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
        );
        break;
      case ButtonType.SecondaryColor:
        updateColors(
          componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFg,
          componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorFgHover,
          colorsForegroundFgDisabled,
          componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBg,
          componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBgHover,
          componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBg,
          componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBg,
          componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBorder,
          componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBorderHover,
          componentColorsComponentsButtonsSecondaryColorButtonSecondaryColorBorder,
          colorsBorderBorderDisabledSubtle,
          ""
        );
        setFocusShadow(
          "0px 0px 0px 4px rgba(149, 193, 31, 0.24), 0px 1px 2px 0px rgba(0, 0, 0, 0.05)"
        );
        break;
      case ButtonType.TertiaryGray:
        updateColors(
          componentColorsComponentsButtonsTertiaryButtonTertiaryFg,
          componentColorsComponentsButtonsTertiaryButtonTertiaryFgHover,
          colorsForegroundFgDisabled,
          "transparent",
          componentColorsComponentsButtonsTertiaryButtonTertiaryBgHover,
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          ""
        );
        setFocusShadow("none");
        break;
      case ButtonType.TertiaryColor:
        updateColors(
          componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFg,
          componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFgHover,
          colorsForegroundFgDisabled,
          "transparent",
          componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorBgHover,
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          ""
        );
        setFocusShadow("none");
        break;
      case ButtonType.LinkGray:
        updateColors(
          componentColorsComponentsButtonsTertiaryButtonTertiaryFg,
          componentColorsComponentsButtonsTertiaryButtonTertiaryFgHover,
          colorsForegroundFgDisabled,
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          ""
        );
        setFocusShadow("none");
        break;
      case ButtonType.LinkColor:
        updateColors(
          componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFg,
          componentColorsComponentsButtonsTertiaryColorButtonTertiaryColorFgHover,
          colorsForegroundFgDisabled,
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          ""
        );
        setFocusShadow("none");
        break;
      case ButtonType.ErrorPrimary:
        updateColors(
          colorsForegroundFgWhite,
          colorsForegroundFgWhite,
          colorsForegroundFgDisabled,
          componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorBg,
          componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorBgHover,
          componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorBg,
          colorsBackgroundBgDisabled,
          componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorBorder,
          componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorBorderHover,
          componentColorsComponentsButtonsPrimaryErrorButtonPrimaryErrorBorder,
          colorsBorderBorderDisabledSubtle,
          ""
        );
        setFocusShadow(
          "0px 0px 0px 4px rgba(240, 68, 56, 0.24), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
        );
        break;
      case ButtonType.ErrorSecondary:
        updateColors(
          componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorFg,
          componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorFgHover,
          colorsForegroundFgDisabled,
          componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorBg,
          componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorBgHover,
          componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorBg,
          colorsBackgroundBgPrimary,
          componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorBorder,
          componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorBorderHover,
          componentColorsComponentsButtonsSecondaryErrorButtonSecondaryErrorBorder,
          colorsBorderBorderDisabledSubtle,
          ""
        );
        setFocusShadow(
          "0px 0px 0px 4px rgba(240, 68, 56, 0.24), 0px 1px 2px 0px rgba(16, 24, 40, 0.05)"
        );
        break;
      case ButtonType.ErrorTertiary:
        updateColors(
          componentColorsComponentsButtonsTertiaryErrorButtonTertiaryErrorFg,
          componentColorsComponentsButtonsTertiaryErrorButtonTertiaryErrorFgHover,
          colorsForegroundFgDisabled,
          "transparent",
          componentColorsComponentsButtonsTertiaryErrorButtonTertiaryErrorBgHover,
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          ""
        );
        setFocusShadow("none");
        break;
      case ButtonType.ErrorLink:
        updateColors(
          componentColorsComponentsButtonsTertiaryErrorButtonTertiaryErrorFg,
          componentColorsComponentsButtonsTertiaryErrorButtonTertiaryErrorFgHover,
          colorsForegroundFgDisabled,
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          "transparent",
          ""
        );
        setFocusShadow("none");
        break;
      default:
        updateColors(
          componentColorsComponentsButtonsPrimaryButtonPrimaryFg,
          componentColorsComponentsButtonsPrimaryButtonPrimaryFgHover,
          colorsForegroundFgDisabled,
          componentColorsComponentsButtonsPrimaryButtonPrimaryBg,
          componentColorsComponentsButtonsPrimaryButtonPrimaryBgHover,
          componentColorsComponentsButtonsPrimaryButtonPrimaryBg,
          colorsBackgroundBgDisabled,
          componentColorsComponentsButtonsPrimaryButtonPrimaryBorder,
          componentColorsComponentsButtonsPrimaryButtonPrimaryBorderHover,
          componentColorsComponentsButtonsPrimaryButtonPrimaryBorder,
          colorsBorderBorderDisabledSubtle,
          ""
        );
        setFocusShadow(
          "0px 0px 0px 4px rgba(149, 193, 31, 0.24), 0px 1px 2px 0px rgba(0, 0, 0, 0.05)"
        );
        break;
    }
  }, [buttonType]);

  useEffect(() => {
    const { spacingMd, spacingLg, spacingXl, spacing2xl } = theme.spacing;
    switch (buttonSize) {
      case ButtonSize.sm:
        setTypography(theme.typography[".text-sm-semibold"]);
        setSpacing(`${spacingMd} ${spacingLg}`);
        setIconSizes({ width: 20, heigt: 20 });
        break;
      case ButtonSize.md:
        setTypography(theme.typography[".text-sm-semibold"]);
        setSpacing(`10px 14px`);
        setIconSizes({ width: 20, heigt: 20 });
        break;
      case ButtonSize.lg:
        setTypography(theme.typography[".text-md-semibold"]);
        setSpacing(`10px ${spacingXl}`);
        setIconSizes({ width: 20, heigt: 20 });
        break;
      case ButtonSize.xl:
        setTypography(theme.typography[".text-md-semibold"]);
        setSpacing(`${spacingLg} 18px`);
        setIconSizes({ width: 20, heigt: 20 });
        break;
      case ButtonSize.xxl:
        setTypography(theme.typography[".text-lg-semibold"]);
        setSpacing(`${spacingXl} 22px`);
        setIconSizes({ width: 24, heigt: 24 });
        break;
      case ButtonSize.xxxl:
        setTypography(theme.typography[".display-xs-semibold"]);
        setSpacing(`${spacing2xl} 22px`);
        setIconSizes({ width: 32, heigt: 32 });
        break;
      default:
        setTypography(theme.typography[".text-sm-semibold"]);
        setSpacing(`10px 14px`);
        setIconSizes({ width: 20, heigt: 20 });
        break;
    }
  }, [buttonSize]);

  const updateColors = (
    textColor: string,
    textColorHover: string,
    textColorDisabled: string,
    backgroundColor: string,
    backgroundColorHover: string,
    backgroundColorFocused: string,
    backgroundColorDisabled: string,
    border: string,
    borderHover: string,
    borderFocused: string,
    borderDisabled: string,
    outlineFocused: string
  ) => {
    setColorVariables({
      textColor,
      textColorHover,
      textColorDisabled,
      backgroundColor,
      backgroundColorHover,
      backgroundColorFocused,
      backgroundColorDisabled,
      border,
      borderHover,
      borderFocused,
      borderDisabled,
      outlineFocused,
    });
  };

  return (
    // <button type="button" {...props}>
    //   {label}
    // </button>
    <MUIButton
      component={
        buttonType === ButtonType.LinkColor ||
        buttonType === ButtonType.LinkGray ||
        buttonType === ButtonType.ErrorLink
          ? "a"
          : "button"
      }
      href={
        buttonType === ButtonType.LinkColor ||
        buttonType === ButtonType.LinkGray ||
        buttonType === ButtonType.ErrorLink
          ? url
          : ""
      }
      disabled={disabled}
      focusRipple={false}
      onClick={onClick}
      sx={{
        minWidth: "max-content",
        backgroundColor: colorVariables?.backgroundColor,
        color: colorVariables?.textColor,
        border: `1px solid ${colorVariables?.border}`,
        padding:
          buttonType !== ButtonType.LinkColor &&
          buttonType !== ButtonType.LinkGray &&
          buttonType !== ButtonType.ErrorLink
            ? spacing
            : 0,
        borderRadius: theme.radius.radiusMd,
        textTransform: "initial",
        "&:hover": {
          backgroundColor: colorVariables?.backgroundColorHover,
          color: colorVariables?.textColorHover,
          border: `1px solid ${colorVariables?.borderHover}`,
        },
        "&:focus": {
          backgroundColor: colorVariables?.backgroundColorFocused,
          border: `1px solid ${colorVariables?.borderFocused}`,
          outline: "none",
          boxShadow: focusShadow,
        },
        "&.Mui-disabled": {
          backgroundColor: colorVariables?.backgroundColorDisabled,
          color: colorVariables?.textColorDisabled,
          border: `1px solid ${colorVariables?.borderDisabled}`,
          outline: "none",
          ".dot-leading-icon": {
            backgroundColor: theme.colors.colorsForegroundFgDisabledSubtle,
          },
        },
        ...typography,
        ...sx,
      }}
    >
      <Box sx={{ visibility: loadingInProgress ? "hidden" : "visible" }}>
        {Icon && (
          <Box
            sx={{
              display: "flex",
              mr:
                iconPosition === IconPosition.Left && !isOnlyIcon ? "10px" : 0,
              ml:
                iconPosition === IconPosition.Right && !isOnlyIcon ? "10px" : 0,
              order: iconPosition === IconPosition.Left ? 0 : 1,
            }}
          >
            <Icon width={iconSizes.width} height={iconSizes.heigt} />
          </Box>
        )}
        {isDotLeadingIcon && !Icon && (
          <Box
            component={"span"}
            className="dot-leading-icon"
            sx={{
              display: "flex",
              height: "10px",
              width: "10px",
              mr: "10px",
              borderRadius: "50%",
              backgroundColor:
                buttonType === ButtonType.Primary ||
                buttonType === ButtonType.ErrorPrimary
                  ? theme.colors
                      .componentColorsComponentsButtonsPrimaryButtonPrimaryFg
                  : theme.colors.colorsForegroundFgSuccessSecondary,
            }}
          ></Box>
        )}
        {!hideLabel && label}
      </Box>
      {loadingInProgress && (
        <Box
          sx={{
            position: "absolute",
            top: "55%",
            transform: "translateY(-50%)",
          }}
        >
          <CircularProgress
            size={typography.lineHeight}
            sx={{
              color: colorVariables?.textColor,
            }}
          />
        </Box>
      )}
    </MUIButton>
  );
};
