import { Box, InputLabel, TextField } from "@mui/material";
import React from "react";
import useTheme from "../../../../../../theme/hooks/useTheme";

export interface ITextInputWithLabelProps {
  label: string;
  value: any;
  setValue: (newValue: any) => void;
  placeholder: string;
}

export default function TextFieldWithLabel({
  label,
  value,
  setValue,
  placeholder,
}: ITextInputWithLabelProps) {
  const theme = useTheme();
  return (
    <Box sx={{ width: "100%", mb: theme.spacing.spacingLg }}>
      <InputLabel
        shrink
        sx={{
          ...theme.typography[".text-sm-medium"],
          color: theme.colors.colorsTextTextSecondary,
        }}
      >
        {label}
      </InputLabel>
      <TextField
        sx={{
          margin: 0,
          border: "none",
          "& .MuiInputBase-root": {
            height: 44,
            padding: "0 15px",
            border: `1px solid ${theme.colors.colorsBorderBorderPrimary}`,
            borderRadius: theme.radius.radiusMd,
            input: {
              ...theme.typography[".text-md-regular"],
              color: theme.colors.colorsTextTextPrimary,
              padding: 0,
              ":placeholder-shown": {
                color: theme.colors.colorsTextTextPlaceholder,
              },
            },
            fieldset: {
              border: "none",
            },
          },
          "& .MuiFormHelperText-root": {
            ml: 0,
          },
        }}
        value={value}
        margin="dense"
        placeholder={placeholder}
        type="text"
        fullWidth
        onChange={(e) => setValue(e.target.value)}
      />
    </Box>
  );
}
