export const termsSV = {
    terms_and_conditions: `<H1>VILLKOR FÖR ANVÄNDNING AV {{productName}}</H1>
    
    <p>Dessa villkor gäller vid användning av Energy Saves tjänst {{productName}}.</p>
    
    <p></p>
    
    <H2>1. DEFINITIONER</H2>
    
    <p>I dessa Villkor har följande termer, när de används med inledande versal, den betydelse som anges nedan.</p>
    
    <p>"Villkor" avser dessa villkor som gäller för din användning av Tjänsten.</p>
    
    <p>"Användare", "du" eller "din" avser den person som är innehavare av Användarkontot.</p>
    
    <p>"Användarkonto" avser Användarens konto i Tjänsten.</p>
    
    <p>"Energy Save", "vi" eller "oss" avser ES Energy Save Holding AB (publ), organisationsnummer 559117-1292.</p>
    
    <p>"ES värmepump" avser värmepumpar av märket [Energy Save] som Användaren ansluter till Tjänsten.</p>
    
    <p>"Part" avser Energy Save eller Användaren.</p>
    
    <p>"Parter" avser Energy Save och Användaren tillsammans.</p>
    
    <p>"Tjänsten" avser webbapplikationen {{productName}} som tillhandahålls av Energy Save för övervakning och kontroll av ES värmepumpar.</p>
    
    <H2>2. VILLKOR</H2>
    
    <p>Genom att använda Tjänsten accepterar du dessa Villkor. Det är därför viktigt att du läser villkoren innan du börjar använda Tjänsten.</p>
    
    <H2>3. ÖVERVAKNING OCH KONTROLL AV DIN ES VÄRMEPUMP</H2>
    
    <p>3.1 Användaren åtar sig att:</p>
    
    <p>(i) följa de instruktioner som anges i Tjänsten;</p>
    
    <H2>4. DITT ANVÄNDARKONTO</H2>
    
    <p>4.1 För att få tillgång till Tjänsten måste du ha ett Användarkonto.</p>
    
    <p>4.2 Det är viktigt att du anger korrekt information när du registrerar ditt Användarkonto och när du använder Tjänsten. Användaren ansvarar för att uppgifterna är korrekta och fullständiga, samt för eventuella konsekvenser av felaktiga uppgifter.</p>
    
    <p>4.3 Det är ditt ansvar att spara och hålla dina inloggningsuppgifter säkra, vilket bland annat innebär att du inte får dela dina inloggningsuppgifter med någon annan och att uppgifterna lagras på ett sätt som förhindrar att obehöriga får tillgång till informationen. Om du ändå misstänker att dina inloggningsuppgifter har avslöjats eller används av någon obehörig måste du omedelbart rapportera detta till Energy Save. Du ansvarar för alla köp som görs med dina inloggningsuppgifter fram till dess att vi mottagit din anmälan.</p>
    
    <p>4.4 Vi förbehåller oss rätten att neka dig tillgång till Tjänsten om vi misstänker att du missbrukar ditt Användarkonto eller dina inloggningsuppgifter eller på annat sätt bryter mot dessa Villkor. Om vi anser det lämpligt och/eller nödvändigt förbehåller vi oss rätten att tilldela dig nya inloggningsuppgifter.</p>
    
    <H2>5. PERSONUPPGIFTER</H2>
    
    <p>Energy Save behandlar dina personuppgifter inom ramen för Tjänsten. Mer information om hur dina personuppgifter behandlas och hur du kan utöva dina rättigheter finns i vår integritetspolicy på <a href="{{hostName}}/static/policy?productName={{productName}}&lang=sv">{{hostName}}/static/policy?productName={{productName}}&lang=sv </a></p>
    
    <H2>6. ANSVAR</H2>
    
    <p>6.1 Energy Save ansvarar inte för skador som uppstår till följd av avbrott i Tjänsten.</p>
    
    <p>6.2 Energy Save ansvarar inte för skador som uppstår på grund av att Användaren inte följer manualer och/eller instruktioner i Tjänsten eller på annat sätt från Energy Save.</p>
    
    <p>6.3 Energy Save ansvarar inte för störningar eller avbrott i elförsörjningen.</p>
    
    <p>6.4 Användaren har inte rätt till ersättning eller återbetalning av avgifter på grund av fel eller avbrott i ES värmepumpen.</p>
    
    <p>6.5 Energy Save ansvarar inte för indirekt skada eller följdskada på Användarens egendom som uppstår till följd av användningen av Tjänsten eller ES värmepumpen.</p>
    
    <p>6.6 Energy Saves ansvar för skador är begränsat till direkta skador. Energy Save ansvarar inte för indirekta skador och förluster såsom utebliven vinst eller utebliven förväntad besparing.</p>
    
    <p>6.7 Energy Saves maximala ansvar gentemot Användaren för eventuella förluster eller skador är begränsat till ett tusen euro.</p>
    
    <H2>7. FORCE MAJEURE</H2>
    
    <p>Om Energy Saves fullgörande av sina skyldigheter enligt dessa Villkor avsevärt hindras eller förhindras på grund av omständigheter utanför Energy Saves kontroll ska detta utgöra grund för befrielse, vilket innebär befrielse från skadestånd och andra påföljder.</p>
    
    <H2>8. ÄNDRINGAR AV VILLKOREN</H2>
    
    <p>Vi förbehåller oss rätten att ändra dessa Villkor, avgifter eller någon del av Tjänsten och Premiumtjänsterna när som helst. Vi kommer att meddela väsentliga ändringar av dessa Villkor genom att göra de reviderade Villkoren tillgängliga i Tjänsten för dig att läsa.</p>
    
    <H2>9. IMMATERIELLA RÄTTIGHETER</H2>
    
    <p>Alla immateriella rättigheter, såsom varumärken och upphovsrätt till Tjänsten, tillhör Energy Save eller Energy Saves licensgivare. All användning av Tjänsten eller dess innehåll, inklusive kopiering eller lagring av sådant innehåll i sin helhet eller delvis, annat än för din egen personliga, icke-kommersiella användning, är förbjuden utan tillstånd från Energy Save.</p>
    
    <H2>10. DIN ANVÄNDNING AV TJÄNSTEN</H2>
    
    <p>10.1 För att tillhandahålla den bästa möjliga upplevelsen för dig behöver vi säkerställa att våra tjänster fungerar smidigt. I samband med ditt godkännande av dessa Villkor förstår och samtycker du till att inte (i) publicera, överföra, omfördela, ladda upp eller marknadsföra någon kommunikation eller innehåll som kan skada eller ha en negativ inverkan på vårt företag, vår verksamhet eller på Tjänsten; (ii) agera på ett sätt eller använda en enhet som begränsar, hindrar, stör eller helt stoppar någon annan användare från att använda Tjänsten, eller som påverkar Tjänstens säkerhet eller (iii) använda någon enhet eller försöka använda någon sökmotor, programvara, verktyg, agent, skript eller annan enhet eller mekanism för att navigera eller söka i Tjänsten eller kopiera innehåll från Tjänsten. Energy Save förbehåller sig rätten att omedelbart neka tillgång till Tjänsten och avsluta ditt Användarkonto vid överträdelse av denna punkt eller någon annan punkt i dessa villkor.</p>
    
    <p>10.2 Energy Save förbehåller sig rätten till eventuella skrivfel i informationen som tillhandahålls i Tjänsten. Energy Save förbehåller sig rätten att när som helst uppdatera eller ändra informationen i Tjänsten.</p>
    
    <H2>11. SERVICENIVÅER</H2>
    
    <p>Energy Save ansvarar för Tjänstens funktionalitet. Generellt sett är tjänsten tillgänglig via internetuppkoppling, 24 timmar om dygnet, sju dagar i veckan. Dock har Energy Save (och underleverantörer engagerade av Energy Save) rätt att vidta åtgärder som påverkar tillgängligheten till Tjänsten om det anses nödvändigt av tekniska, operativa eller säkerhetsskäl.</p>
    
    <p>Större planerade avbrott meddelas Användaren sju dagar innan det planerade driftstoppet. Oplanerade avbrott kan förekomma. I
    
    <p>den mån Energy Save ansvarar för och kan påverka sådana avbrott ska Energy Save omgående åtgärda felet.</p>

    <p>Energy Save ansvarar för att tillhandahålla adekvata administrativa, fysiska och tekniska säkerhetsåtgärder. Energy Save gör regelbundna säkerhetskopior för att hålla kunddata säker.</p>

    <H2>12. GILTIGHET</H2>

    <p>Om en eller flera bestämmelser i dessa Villkor anses vara ogiltiga eller förklaras ogiltiga av domstol eller tillsynsmyndighet, ska detta inte påverka giltigheten av de återstående avtalsvillkoren.</p>

    <H2>13. TILLÄMPLIG LAG OCH TVISTELÖSNING</H2>

    <p>13.1 Svensk materiell rätt ska tillämpas på dessa Villkor.</p>

    <p>13.2 Tvister angående tolkning eller tillämpning av dessa Villkor samt andra tvister avseende rättsförhållanden på grund av Villkoren ska slutligt avgöras i allmän domstol, varvid Göteborgs tingsrätt ska utgöra första instans.</p>

    <p> </p>
`
}
