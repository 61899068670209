import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import filesImage from "../../../../images/image-savings-calculator.png";

const EmptyResultBlock = () => {
  const { t } = useTranslation("cloud_ui");
  const theme = useTheme();

  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "30px",
      }}
    >
      <img
        src={filesImage}
        alt={"Empty energy savings"}
        style={{
          maxWidth: "390px",
          minHeight: "300px",
          marginBottom: isMobileOrTablet ? "20px" : "0",
        }}
      />
      <Typography
        style={{
          maxWidth: "420px",
          fontSize: isMobileOrTablet ? "24px" : "34px",
          textAlign: "center",
          color: "#abacaf",
        }}
        variant={"h4"}
        component="h4"
      >
        {isMobileOrTablet
          ? t("pages.savingCalculator.emptyPageMobileText")
          : t("pages.savingCalculator.emptyPageText")}
      </Typography>
    </Box>
  );
};

export default EmptyResultBlock;
