import {
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Modal,
  Paper,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import React from "react";
import { useTranslation } from "react-i18next";
import useTheme from "../../theme/hooks/useTheme";
import { Check } from "untitledui-js-base";
import { Button, ButtonType } from "../../components/Button/Button";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  pt: 2,
  px: 4,
  pb: 3,
};

export interface ISuccessfullyCreatedInstallationModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
}

export default function SuccessfullyCreatedInstallationModal({
  isOpen,
  onClose,
  onConfirm,
}: ISuccessfullyCreatedInstallationModalProps) {
  const theme = useTheme();
  const { t } = useTranslation("cloud_ui");

  const handleConfirmClick = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth={true}
      sx={{ "& .MuiPaper-root": { maxWidth: "400px" } }}
    >
      <DialogTitle
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          marginBottom: theme.spacing.spacing4xl,
          padding: `${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl} 0`,
        }}
      >
        <Box
          sx={{
            width: "48px",
            height: "48px",
            borderRadius: "50%",
            backgroundColor:
              theme.colors.componentColorsUtilityBrandUtilityBrand50,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mb: theme.spacing.spacingXl,
          }}
        >
          <Check
            size="24"
            style={{
              color:
                theme.colors
                  .componentColorsComponentsIconsFeaturedIconsLightFeaturedIconLightFgBrand,
            }}
            strokeWidth={2}
          />
        </Box>

        <Typography sx={{ ...theme.typography[".text-lg-semibold"] }}>
          {t("pages.installation_new.installationCreatedSuccessfullyMessage")}
        </Typography>
        <Box>
          <Typography sx={{ ...theme.typography[".text-sm-regular"] }}>
            {t("pages.installation_new.installationCreatedSuccessfullyTitle")}
            <br />{" "}
            {t(
              "pages.installation_new.installationCreatedSuccessfullyDescription"
            )}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogActions
        sx={{
          padding: `0 ${theme.spacing.spacing3xl} ${theme.spacing.spacing3xl}`,
          justifyContent: "space-between",
        }}
      >
        <Button
          label={`${t(
            "pages.installation_new.addConnectivityIDNotNowButtonText"
          )}`}
          onClick={onClose}
          buttonType={ButtonType.SecondaryGray}
          sx={{
            width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
          }}
        />
        <Button
          label={`${t("pages.installation_new.addConnectivityIDButtonText")}`}
          onClick={handleConfirmClick}
          buttonType={ButtonType.Primary}
          sx={{
            ml: "0 !important",
            width: `calc(50% - ${theme.spacing.spacingLg} / 2)`,
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

export interface ISuccessfullyCreatedInstallationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}
