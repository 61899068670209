const spacing = {
  spacingNone: "0rem",
  spacingXxs: "0.125rem",
  spacingXs: "0.25rem",
  spacingSm: "0.375rem",
  spacingMd: "0.5rem",
  spacingLg: "0.75rem",
  spacingXl: "1rem",
  spacing2xl: "1.25rem",
  spacing3xl: "1.5rem",
  spacing4xl: "2rem",
  spacing5xl: "2.5rem",
  spacing6xl: "3rem",
  spacing7xl: "4rem",
  spacing8xl: "5rem",
  spacing9xl: "6rem",
  spacing10xl: "8rem",
  spacing11xl: "10rem",
};

export default spacing;
